import type { AxiosInstance } from 'axios'

import type { MapPayload, MapboxResponse } from '@/imports/@types/maps'

export const useMapApi = (http: AxiosInstance) => ({
  fetchMapImage: async (payload: MapPayload, accessToken: string): Promise<string | null> => {
    const encodedLocation = encodeURIComponent(payload.location)
    const apiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedLocation}.json`

    try {
      const response = await http.get<MapboxResponse>(apiUrl, {
        params: {
          access_token: accessToken,
        },
      })

      const { center } = response.data.features[0]
      const [longitude, latitude] = center

      const styleId = payload.styleId
      const locationMarker = `pin-l+005B5E(${longitude},${latitude})`

      return `https://api.mapbox.com/styles/v1/${styleId}/static/${locationMarker}/${longitude},${latitude},${payload.zoom}/${payload.width}x${payload.height}?access_token=${accessToken}`
    } catch (error) {
      console.error('Error fetching map image', error)
      return null
    }
  },
})
