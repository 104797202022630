import type { AxiosInstance } from 'axios'

import type {
  CustomersTableDataResponse,
  GetCustomersPayload,
} from '@/imports/@types/customersEngagement/CustomersEngagement'

export const useCustomersEngagementApi = (http: AxiosInstance) => ({
  getCustomers(params: GetCustomersPayload) {
    return http.get<CustomersTableDataResponse>('/supplier-customers', { params })
  },
})
