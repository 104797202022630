import type { AxiosInstance } from 'axios'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'
import type { Invite, CreateUserInviteParams, CreateUserInviteResponse } from '@/imports/@types/Invite'

export const useInviteApi = (http: AxiosInstance) => ({
  list({ orgId }: { orgId: string }) {
    return http.get<ApiResponseInner<Invite[]>>('invite/list', {
      params: {
        orgId,
      },
    })
  },

  create(params: CreateUserInviteParams) {
    return http.post<CreateUserInviteResponse>('invite/create', params)
  },
})
