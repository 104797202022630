import type { AxiosInstance } from 'axios'

import type { LegalEntityV2, LegalEntityV2Initial } from '@/imports/@types/LegalEntity'

export const useLegalEntitiesApi = (http: AxiosInstance) => ({
  getLegalEntities({ orgId }: { orgId: string }) {
    return http.get<{
      legalEntities: LegalEntityV2[]
    }>('/legalEntityV2/getForOrganization', { params: { orgId } })
  },

  removeLegalEntity({ orgId, id }: { orgId: string; id: string }) {
    return http.delete<{ success: boolean }>('/legalEntityV2/delete', {
      params: {
        orgId,
        id,
      },
    })
  },

  createLegalEntity(payload: LegalEntityV2Initial & { orgId: string }) {
    return http.post<{ legalEntity: LegalEntityV2 }>('/legalEntityV2/create', payload)
  },

  updateLegalEntity(payload: LegalEntityV2 & { orgId: string }) {
    return http.patch<{ legalEntity: LegalEntityV2 }>('/legalEntityV2/update', payload)
  },
})
