export enum EMISSIONS_TARGETS_STATUSES {
  DRAFT = 'DRAFT',
  LIVE = 'LIVE',
}

export enum EMISSIONS_TARGETS_TERM_TYPES {
  LONG = 'LONG',
  SHORT = 'SHORT',
}

export enum EMISSIONS_TARGETS_REDUCTION_TYPES {
  LINEAR = 'LINEAR',
  COMPOUND = 'COMPOUND',
}

export enum EMISSIONS_TARGETS_SBTI_STATUS {
  IN_VALIDATION = 'IN_VALIDATION',
  VALIDATED = 'VALIDATED',
  COMMITTED = 'COMMITTED',
}

export enum EMISSIONS_TARGETS_CONTRACTION_TYPES {
  ABSOLUTE = 'ABSOLUTE',
  INTENSITY = 'INTENSITY',
}

export enum EMISSIONS_TARGETS_PROGRESS_STATUSES {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  NOT_ACHIEVED = 'NOT_ACHIEVED',
}

export enum ACTIVE_EMISSION_TARGET_ROUTE_STATE {
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

export enum CHART_TYPES {
  MIRRORED = 'MIRRORED',
  BAR = 'BAR', // for situations where things collide badly and can't work on the same stacked chart
  STACKED = 'STACKED',
}
