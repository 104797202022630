<template>
  <div>
    <template v-if="hasPermissions">
      <slot></slot>
    </template>

    <template v-else>
      <slot name="fallback"></slot>
    </template>
  </div>
</template>

<script lang="ts" setup>
  /**
   * Higher order component used for wrapping features that are
   * conditionally enabled by permissions
   * Displays the fallback slot if user doesn't meet the permission criteria
   */

  import { computed } from 'vue'

  import PermissionService from '@/imports/lib/services/permissionService'
  import type { ACTION } from '@/imports/lib/constants/permission/permissionConstants'

  const props = defineProps<{
    permissions: ACTION | ACTION[]
  }>()

  const hasPermissions = computed(() => PermissionService.hasPermissions(props.permissions))
</script>
