import { ROLES_KEYS_MAP, ROLE_KEYS } from '@/imports/lib/constants/permission/permissionConstants'

export const getHumanReadeableRole = (role: ROLE_KEYS) => {
  return ROLES_KEYS_MAP[role]
}

export const getRoleToTagColor = (role: ROLE_KEYS) => {
  const rolesToColorsMap: { [key: string]: string } = {
    SUPER_USER: 'error',
    NO_ACCESS: 'grey',
    ADMIN: 'waste',
  }

  return rolesToColorsMap[role] || 'transporation'
}
