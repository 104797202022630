export class ValidateDTO {}
function getSmallestPositive(a: number, b: number) {
  if (a >= 0 && b >= 0) return Math.min(a, b)
  return Math.max(a, b)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function replaceUriParams(uri: string, params: { [key: string]: any }): string {
  return uri
    .split('/')
    .map((uriComponent: string): string => {
      if (!uriComponent.startsWith(':')) return uriComponent
      const keyName = uriComponent.substring(1, getSmallestPositive(uriComponent.indexOf('('), uriComponent.length))
      if (!(keyName in params)) throw new Error(`cannot find parameter "${keyName}" in parameters object`)
      return String(params[keyName])
    })
    .join('/')
}

// Why remove this?
export const RUP = replaceUriParams
