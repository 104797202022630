<template>
  <component
    :is="element"
    :class="[
      'aq-card',
      {
        'big-padding': bigPadding,
        'no-padding': !includePadding,
        'small-padding': smallPadding,
        'smallest-padding': smallestPadding,
        'no-border': removeBorder,
        'include-margin-bottom': includeMarginBottom,
      },
      theme,
    ]"
  >
    <slot />
  </component>
</template>

<script lang="ts">
  import { defineComponent, type PropType } from 'vue'

  type Theme = 'blue' | 'ghost' | 'grey' | ''

  export default defineComponent({
    name: 'Card',

    props: {
      element: {
        type: String,
        default: 'div',
      },

      bigPadding: {
        type: Boolean,
      },

      smallPadding: {
        type: Boolean,
      },

      smallestPadding: {
        type: Boolean,
      },

      includePadding: {
        type: Boolean,
        default: true,
      },

      includeMarginBottom: {
        type: Boolean,
      },

      theme: {
        type: String as PropType<Theme>,
        default: '',
      },

      removeBorder: {
        type: Boolean,
      },
    },
  })
</script>

<style lang="scss" scoped>
  $module: 'aq-card';

  .#{$module} {
    border: 1px solid $grey-5;
    background-color: white;
    border-radius: 8px;
    padding: $grid-size-padding * 6;
    position: relative;

    &.big-padding {
      padding: 42px 36px 60px;
    }

    &.small-padding {
      padding: $grid-size-padding * 4;
    }

    &.smallest-padding {
      padding: $grid-size-padding * 3;
    }

    &.no-padding {
      padding: 0;
    }

    &.include-margin-bottom {
      margin-bottom: $grid-size-margin * 3;
    }

    &.blue {
      background: $blue-light;
      border: 1px solid rgba(128, 173, 175, 0.25);
    }

    &.ghost {
      background: transparent;
      border: 1px solid $grey-4;
    }

    &.grey {
      background: $grey-7;
    }

    &.no-border {
      border: 0;
    }
  }
</style>
