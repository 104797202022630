/**
 * @description Permissions defined here so both frontend and backend can use.
 * Tests also are generated based on these definitions
 */

export enum ACTION {
  UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE',
  UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS',
  VIEW_CONTRACT_SCOPE_SETTINGS = 'VIEW_CONTRACT_SCOPE_SETTINGS',
  UPDATE_CONTRACT_SCOPE_SETTINGS = 'UPDATE_CONTRACT_SCOPE_SETTINGS',
  UPDATE_ORGANISATION_DETAILS = 'UPDATE_ORGANISATION_DETAILS',
  UPDATE_AUTHENTICATION_SETTINGS = 'UPDATE_AUTHENTICATION_SETTINGS',
  CREATE_INTEGRATIONS = 'CREATE_INTEGRATIONS',
  UPDATE_INTEGRATIONS = 'UPDATE_INTEGRATIONS',
  DELETE_INTEGRATIONS = 'DELETE_INTEGRATIONS',
  VIEW_TEAM_MEMBERS = 'VIEW_TEAM_MEMBERS',
  CREATE_NEW_USERS = 'CREATE_NEW_USERS',
  UPDATE_USER_ROLES = 'UPDATE_USER_ROLES',
  VIEW_A_LIST_OF_DATA_SOURCES = 'VIEW_A_LIST_OF_DATA_SOURCES',
  VIEW_A_LIST_OF_DATA_SOURCES_TAGS = 'VIEW_A_LIST_OF_DATA_SOURCES_TAGS',
  CREATE_A_NEW_DATA_SOURCE = 'CREATE_A_NEW_DATA_SOURCE',
  UPDATE_DATA_SOURCE_METADATA = 'UPDATE_DATA_SOURCE_METADATA',
  UPDATE_DATA_SOURCE_STATUS = 'UPDATE_DATA_SOURCE_STATUS',
  UPLOAD_DATA_TO_THE_DATA_SOURCE = 'UPLOAD_DATA_TO_THE_DATA_SOURCE',
  CREATE_DATA_SOURCE_ASSUMPTIONS = 'CREATE_DATA_SOURCE_ASSUMPTIONS',
  UPDATE_DATA_SOURCE_ASSUMPTIONS = 'UPDATE_DATA_SOURCE_ASSUMPTIONS',
  DELETE_DATA_SOURCE_ASSUMPTIONS = 'DELETE_DATA_SOURCE_ASSUMPTIONS',
  CREATE_COMMENT_ON_A_DATA_SOURCE = 'CREATE_COMMENT_ON_A_DATA_SOURCE',
  DOWNLOAD_DATA_FROM_A_DATA_SOURCE = 'DOWNLOAD_DATA_FROM_A_DATA_SOURCE',
  UPDATE_THE_STATUS_OF_DATA_SETS = 'UPDATE_THE_STATUS_OF_DATA_SETS',
  CREATE_ISSUES_TO_A_DATA_SET = 'CREATE_ISSUES_TO_A_DATA_SET',
  DELETE_A_DATA_SET_FROM_A_DATA_SOURCE = 'DELETE_A_DATA_SET_FROM_A_DATA_SOURCE',
  CREATE_INTENSITY_METRICS = 'CREATE_INTENSITY_METRICS',
  UPDATE_INTENSITY_METRICS = 'UPDATE_INTENSITY_METRICS',
  VIEW_THE_LIST_OF_BUSINESS_UNITS_IN_THE_ORGANISATION = 'VIEW_THE_LIST_OF_BUSINESS_UNITS_IN_THE_ORGANISATION',
  CREATE_A_NEW_BUSINESS_UNIT = 'CREATE_A_NEW_BUSINESS_UNIT',
  UPDATE_THE_DETAILS_OF_A_BUSINESS_UNIT = 'UPDATE_THE_DETAILS_OF_A_BUSINESS_UNIT',
  UPDATE_USER_ROLES_WITHIN_A_BUSINESS_UNIT = 'UPDATE_USER_ROLES_WITHIN_A_BUSINESS_UNIT',
  VIEW_FACILITIES = 'VIEW_FACILITIES',
  CREATE_FACILITIES = 'CREATE_FACILITIES',
  UPDATE_FACILITIES = 'UPDATE_FACILITIES',
  VIEW_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS = 'VIEW_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS',
  DOWNLOAD_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS = 'DOWNLOAD_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS',
  VIEW_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS = 'VIEW_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS',
  DOWNLOAD_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS = 'DOWNLOAD_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS',
  VIEW_LIVE_SUSTAINABILITY_TARGETS = 'VIEW_LIVE_SUSTAINABILITY_TARGETS',
  VIEW_DRAFT_SUSTAINABILITY_TARGETS = 'VIEW_DRAFT_SUSTAINABILITY_TARGETS',
  UPDATE_SUSTAINABILITY_TARGETS = 'UPDATE_SUSTAINABILITY_TARGETS',
  VIEW_RECOMMENDATIONS = 'VIEW_RECOMMENDATIONS',
  CREATE_AND_UPDATE_RECOMMENDATIONS = 'CREATE_AND_UPDATE_RECOMMENDATIONS',
  VIEW_REPORTS = 'VIEW_REPORTS',
  DOWNLOAD_REPORTS = 'DOWNLOAD_REPORTS',
  UPLOAD_REPORTS = 'UPLOAD_REPORTS',
  VIEW_LIST_OF_ALL_SUPPLIERS = 'VIEW_LIST_OF_ALL_SUPPLIERS',
  CREATE_REQUEST_DATA_FROM_DATA_SUPPLIERS = 'CREATE_REQUEST_DATA_FROM_DATA_SUPPLIERS', // @TODO Make sure this is correct - do we want specific suppliers?
  ACCESS_ALTRUISTIQ_ADVISOR = 'ACCESS_ALTRUISTIQ_ADVISOR',
  SETUP_PRODUCT_DATA_SOURCE = 'SETUP_PRODUCT_DATA_SOURCE',
  CREATE_INITIATIVES = 'CREATE_INITIATIVES',
  UPDATE_INITIATIVES = 'UPDATE_INITIATIVES',
  DELETE_INITIATIVES = 'DELETE_INITIATIVES',
  VIEW_INITIATIVES = 'VIEW_INITIATIVES',
  VIEW_CORPORATE_INITIATIVES = 'VIEW_CORPORATE_INITIATIVES',
  EDIT_CORPORATE_INITIATIVES = 'EDIT_CORPORATE_INITIATIVES',
  EDIT_CORPORATE_INITIATIVES_SCENARIOS = 'EDIT_CORPORATE_INITIATIVES_SCENARIOS',
  CREATE_GENERIC_CORPORATE_INITIATIVES = 'CREATE_GENERIC_CORPORATE_INITIATIVES',
  CREATE_CORPORATE_INITIATIVES = 'CREATE_CORPORATE_INITIATIVES',
  VIEW_CUSTOM_DASHBOARDS = 'VIEW_CUSTOM_DASHBOARDS',
  CREATE_EMISSIONS_TARGETS = 'CREATE_EMISSIONS_TARGETS',
  EDIT_EMISSIONS_TARGETS = 'EDIT_EMISSIONS_TARGETS',
  VIEW_LIVE_EMISSIONS_TARGETS = 'VIEW_LIVE_EMISSIONS_TARGETS',
  VIEW_DRAFT_EMISSIONS_TARGETS = 'VIEW_DRAFT_EMISSIONS_TARGETS',
  DELETE_EMISSIONS_TARGETS = 'DELETE_EMISSIONS_TARGETS',
  CREATE_SUPPLIER_PRODUCT_FOOTPRINT_REQUESTS = 'CREATE_SUPPLIER_PRODUCT_FOOTPRINT_REQUESTS',
  CREATE_SUPPLIER_INVITE = 'CREATE_SUPPLIER_INVITE',
  CREATE_SUPPLIER_PRODUCT_FOOTPRINT = 'CREATE_SUPPLIER_PRODUCT_FOOTPRINT',
  UPDATE_SUPPLIER_PRODUCT_FOOTPRINT_REQUEST = 'UPDATE_SUPPLIER_PRODUCT_FOOTPRINT_REQUEST',
  VIEW_SUPPLIER_PRODUCT_FOOTPRINT = 'VIEW_SUPPLIER_PRODUCT_FOOTPRINT',
  CAN_DELETE_DATA_SOURCE = 'CAN_DELETE_DATA_SOURCE',
  ADD_BASIC_CALCULATION = 'ADD_BASIC_CALCULATION',
  CAN_DELETE_DATA_SET = 'CAN_DELETE_DATA_SET',
  UPDATE_LOCKED_YEAR_STATUS = 'UPDATE_LOCKED_YEAR_STATUS',
  PDF_VIEW_PAGE_GROUPING_MASTER_FILES = 'PDF_VIEW_PAGE_GROUPING_MASTER_FILES',
  PDF_VIEW_PAGE_GROUPING = 'PDF_VIEW_PAGE_GROUPING',
  DELETE_PAGE_GROUPING = 'DELETE_PAGE_GROUPING',
  PDF_MOVE_PAGE_TO_GROUP = 'PDF_MOVE_PAGE_TO_GROUP',
  PDF_MARK_AS_MASTER = 'PDF_MARK_AS_MASTER',
  PDF_CREATE_PAGE_GROUPING = 'PDF_CREATE_PAGE_GROUPING',
  PDF_UPDATE_PAGE_GROUPING_GROUP_NAME = 'PDF_UPDATE_PAGE_GROUPING_GROUP_NAME',
  GET_MASTER_FILES = 'GET_MASTER_FILES',
  VIEW_ALL_MASTER_FILES = 'VIEW_ALL_MASTER_FILES',
  PDF_VIEW_FILE_PAGES = 'PDF_VIEW_FILE_PAGES',
  PDF_VIEW_GROUP_PDF_IMG = 'PDF_VIEW_GROUP_PDF_IMG',
  PDF_VIEW_PAGE_ANNOTATIONS = 'PDF_VIEW_PAGE_ANNOTATIONS',
  PDF_VIEW_LS_TASK = 'PDF_VIEW_LS_TASK',
  CREATE_BASIC_CALCULATION = 'CREATE_BASIC_CALCULATION',
  LOAD_MORE_PAGE_GROUPING_RESULTS = 'LOAD_MORE_PAGE_GROUPING_RESULTS',
  PDF_VIEW_LS_PROJECT_CONFIG = 'PDF_VIEW_LS_PROJECT_CONFIG',
  PDF_CREATE_ANNOTATION_LAYER = 'PDF_CREATE_ANNOTATION_LAYER',
  PDF_UPDATE_ANNOTATION_LAYER = 'PDF_UPDATE_ANNOTATION_LAYER',
  PDF_DELETE_ANNOTATION_LAYER = 'PDF_DELETE_ANNOTATION_LAYER',
  CAN_PUBLISH_CALCULATIONS = 'CAN_PUBLISH_CALCULATIONS',
  VIEW_DATA_REQUESTS = 'VIEW_DATA_REQUESTS',
  VIEW_FRANCHISE_REQUEST = 'VIEW_FRANCHISE_REQUEST',
  VIEW_FRANCHISES = 'VIEW_FRANCHISES',
  CREATE_FRANCHISE = 'CREATE_FRANCHISE',
  SEND_FRANCHISE_INVITE = 'SEND_FRANCHISE_INVITE',
  DELETE_FRANCHISE = 'DELETE_FRANCHISE',
  EDIT_FRANCHISE = 'EDIT_FRANCHISE',
  PDF_SUBMIT_ANNOTATIONS = 'PDF_SUBMIT_ANNOTATIONS',
  ADD_FOOTPRINT_TO_FRANCHISE_REQUEST = 'ADD_FOOTPRINT_TO_FRANCHISE_REQUEST',
  PDF_CAN_TRIGGER_PAGE_GROUPING = 'PDF_CAN_TRIGGER_PAGE_GROUPING',
  PDF_VIEW_REVIEW_LABELS = 'PDF_VIEW_REVIEW_LABELS',
  GET_FRANCHISORS = 'GET_FRANCHISORS',
  PDF_MARK_ANNOTATION_AS_REVIEWED = 'PDF_MARK_ANNOTATION_AS_REVIEWED',
  PDF_REVIEW_DATA_UPDATE_CELL = 'PDF_REVIEW_DATA_UPDATE_CELL',
  PDF_TRIGGER_INGESTION = 'PDF_TRIGGER_INGESTION',
  PDF_UPDATE_ANNOTATION_LABEL = 'PDF_UPDATE_ANNOTATION_LABEL',
  PDF_GET_REVIEWED_DATA_STATUS = 'PDF_GET_REVIEWED_DATA_STATUS',
  UPDATE_SUPPLIER_INVITE_EMAIL = 'UPDATE_SUPPLIER_INVITE_EMAIL',
  PDF_GET_DATA_SOURCE_LABELS = 'PDF_GET_DATA_SOURCE_LABELS',
  PDF_CREATE_ANNOTATION_LABEL = 'PDF_CREATE_ANNOTATION_LABEL',
  PDF_AUTO_ANNOTATION_STATUS = 'PDF_AUTO_ANNOTATION_STATUS',
}

const SOLUTION_ADVISOR_PERMISSIONS = [
  ACTION.UPDATE_USER_PROFILE,
  ACTION.UPDATE_USER_SETTINGS,
  ACTION.VIEW_CONTRACT_SCOPE_SETTINGS,
  ACTION.UPDATE_CONTRACT_SCOPE_SETTINGS,
  ACTION.UPDATE_ORGANISATION_DETAILS,
  ACTION.UPDATE_AUTHENTICATION_SETTINGS,
  ACTION.CREATE_INTEGRATIONS,
  ACTION.UPDATE_INTEGRATIONS,
  ACTION.DELETE_INTEGRATIONS,
  ACTION.VIEW_TEAM_MEMBERS,
  ACTION.CREATE_NEW_USERS,
  ACTION.UPDATE_USER_ROLES,
  ACTION.VIEW_A_LIST_OF_DATA_SOURCES,
  ACTION.VIEW_A_LIST_OF_DATA_SOURCES_TAGS,
  ACTION.CREATE_A_NEW_DATA_SOURCE,
  ACTION.UPDATE_DATA_SOURCE_METADATA,
  ACTION.UPDATE_DATA_SOURCE_STATUS,
  ACTION.UPLOAD_DATA_TO_THE_DATA_SOURCE,
  ACTION.CREATE_DATA_SOURCE_ASSUMPTIONS,
  ACTION.UPDATE_DATA_SOURCE_ASSUMPTIONS,
  ACTION.DELETE_DATA_SOURCE_ASSUMPTIONS,
  ACTION.CREATE_COMMENT_ON_A_DATA_SOURCE,
  ACTION.DOWNLOAD_DATA_FROM_A_DATA_SOURCE,
  ACTION.UPDATE_THE_STATUS_OF_DATA_SETS,
  ACTION.CREATE_ISSUES_TO_A_DATA_SET,
  ACTION.DELETE_A_DATA_SET_FROM_A_DATA_SOURCE,
  ACTION.CREATE_INTENSITY_METRICS,
  ACTION.UPDATE_INTENSITY_METRICS,
  ACTION.VIEW_THE_LIST_OF_BUSINESS_UNITS_IN_THE_ORGANISATION,
  ACTION.CREATE_A_NEW_BUSINESS_UNIT,
  ACTION.UPDATE_THE_DETAILS_OF_A_BUSINESS_UNIT,
  ACTION.UPDATE_USER_ROLES_WITHIN_A_BUSINESS_UNIT,
  ACTION.VIEW_FACILITIES,
  ACTION.CREATE_FACILITIES,
  ACTION.UPDATE_FACILITIES,
  ACTION.VIEW_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
  ACTION.DOWNLOAD_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
  ACTION.VIEW_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
  ACTION.DOWNLOAD_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
  ACTION.VIEW_LIVE_SUSTAINABILITY_TARGETS,
  ACTION.VIEW_DRAFT_SUSTAINABILITY_TARGETS,
  ACTION.UPDATE_SUSTAINABILITY_TARGETS,
  ACTION.VIEW_RECOMMENDATIONS,
  ACTION.CREATE_AND_UPDATE_RECOMMENDATIONS,
  ACTION.VIEW_REPORTS,
  ACTION.DOWNLOAD_REPORTS,
  ACTION.UPLOAD_REPORTS,
  ACTION.VIEW_LIST_OF_ALL_SUPPLIERS,
  ACTION.CREATE_REQUEST_DATA_FROM_DATA_SUPPLIERS,
  ACTION.ACCESS_ALTRUISTIQ_ADVISOR,
  ACTION.CREATE_INITIATIVES,
  ACTION.UPDATE_INITIATIVES,
  ACTION.DELETE_INITIATIVES,
  ACTION.VIEW_INITIATIVES,
  ACTION.VIEW_CORPORATE_INITIATIVES,
  ACTION.EDIT_CORPORATE_INITIATIVES,
  ACTION.EDIT_CORPORATE_INITIATIVES_SCENARIOS,
  ACTION.CREATE_CORPORATE_INITIATIVES,
  ACTION.CREATE_GENERIC_CORPORATE_INITIATIVES,
  ACTION.VIEW_CUSTOM_DASHBOARDS,
  ACTION.CREATE_EMISSIONS_TARGETS,
  ACTION.DELETE_EMISSIONS_TARGETS,
  ACTION.VIEW_DRAFT_EMISSIONS_TARGETS,
  ACTION.VIEW_LIVE_EMISSIONS_TARGETS,
  ACTION.EDIT_EMISSIONS_TARGETS,
  ACTION.CREATE_SUPPLIER_INVITE,
  ACTION.CREATE_SUPPLIER_PRODUCT_FOOTPRINT_REQUESTS,
  ACTION.CREATE_SUPPLIER_PRODUCT_FOOTPRINT,
  ACTION.UPDATE_SUPPLIER_PRODUCT_FOOTPRINT_REQUEST,
  ACTION.VIEW_SUPPLIER_PRODUCT_FOOTPRINT,
  ACTION.CAN_DELETE_DATA_SOURCE,
  ACTION.CAN_DELETE_DATA_SET,
  ACTION.UPDATE_LOCKED_YEAR_STATUS,
  ACTION.VIEW_ALL_MASTER_FILES,
  ACTION.PDF_VIEW_PAGE_GROUPING,
  ACTION.DELETE_PAGE_GROUPING,
  ACTION.PDF_MOVE_PAGE_TO_GROUP,
  ACTION.PDF_MARK_AS_MASTER,
  ACTION.PDF_CREATE_PAGE_GROUPING,
  ACTION.PDF_UPDATE_PAGE_GROUPING_GROUP_NAME,
  ACTION.VIEW_ALL_MASTER_FILES,
  ACTION.PDF_VIEW_FILE_PAGES,
  ACTION.PDF_VIEW_GROUP_PDF_IMG,
  ACTION.PDF_VIEW_PAGE_GROUPING_MASTER_FILES,
  ACTION.PDF_VIEW_PAGE_ANNOTATIONS,
  ACTION.PDF_VIEW_LS_TASK,
  ACTION.CREATE_BASIC_CALCULATION,
  ACTION.LOAD_MORE_PAGE_GROUPING_RESULTS,
  ACTION.PDF_VIEW_LS_PROJECT_CONFIG,
  ACTION.PDF_CREATE_ANNOTATION_LAYER,
  ACTION.PDF_UPDATE_ANNOTATION_LAYER,
  ACTION.PDF_DELETE_ANNOTATION_LAYER,
  ACTION.CAN_PUBLISH_CALCULATIONS,
  ACTION.VIEW_DATA_REQUESTS,
  ACTION.VIEW_FRANCHISES,
  ACTION.CREATE_FRANCHISE,
  ACTION.VIEW_FRANCHISE_REQUEST,
  ACTION.SEND_FRANCHISE_INVITE,
  ACTION.DELETE_FRANCHISE,
  ACTION.EDIT_FRANCHISE,
  ACTION.PDF_SUBMIT_ANNOTATIONS,
  ACTION.ADD_FOOTPRINT_TO_FRANCHISE_REQUEST,
  ACTION.PDF_CAN_TRIGGER_PAGE_GROUPING,
  ACTION.PDF_VIEW_REVIEW_LABELS,
  ACTION.GET_FRANCHISORS,
  ACTION.PDF_CAN_TRIGGER_PAGE_GROUPING,
  ACTION.PDF_MARK_ANNOTATION_AS_REVIEWED,
  ACTION.PDF_CAN_TRIGGER_PAGE_GROUPING,
  ACTION.PDF_REVIEW_DATA_UPDATE_CELL,
  ACTION.PDF_TRIGGER_INGESTION,
  ACTION.PDF_UPDATE_ANNOTATION_LABEL,
  ACTION.PDF_GET_REVIEWED_DATA_STATUS,
  ACTION.UPDATE_SUPPLIER_INVITE_EMAIL,
  ACTION.PDF_GET_DATA_SOURCE_LABELS,
  ACTION.PDF_CREATE_ANNOTATION_LABEL,
  ACTION.PDF_AUTO_ANNOTATION_STATUS,
]

const ADMIN_PERMISSIONS = [
  ACTION.UPDATE_USER_PROFILE,
  ACTION.UPDATE_USER_SETTINGS,
  ACTION.UPDATE_ORGANISATION_DETAILS,
  ACTION.CREATE_INTEGRATIONS,
  ACTION.UPDATE_INTEGRATIONS,
  ACTION.DELETE_INTEGRATIONS,
  ACTION.UPDATE_AUTHENTICATION_SETTINGS,
  ACTION.VIEW_TEAM_MEMBERS,
  ACTION.CREATE_NEW_USERS,
  ACTION.UPDATE_USER_ROLES,
  ACTION.VIEW_A_LIST_OF_DATA_SOURCES,
  ACTION.VIEW_A_LIST_OF_DATA_SOURCES_TAGS,
  ACTION.CREATE_A_NEW_DATA_SOURCE,
  ACTION.UPDATE_DATA_SOURCE_METADATA,
  ACTION.UPLOAD_DATA_TO_THE_DATA_SOURCE,
  ACTION.CREATE_COMMENT_ON_A_DATA_SOURCE,
  ACTION.DOWNLOAD_DATA_FROM_A_DATA_SOURCE,
  ACTION.CREATE_INTENSITY_METRICS,
  ACTION.UPDATE_INTENSITY_METRICS,
  ACTION.VIEW_THE_LIST_OF_BUSINESS_UNITS_IN_THE_ORGANISATION,
  ACTION.CREATE_A_NEW_BUSINESS_UNIT,
  ACTION.UPDATE_THE_DETAILS_OF_A_BUSINESS_UNIT,
  ACTION.UPDATE_USER_ROLES_WITHIN_A_BUSINESS_UNIT,
  ACTION.VIEW_FACILITIES,
  ACTION.CREATE_FACILITIES,
  ACTION.UPDATE_FACILITIES,
  ACTION.VIEW_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
  ACTION.DOWNLOAD_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
  ACTION.VIEW_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
  ACTION.DOWNLOAD_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
  ACTION.VIEW_LIVE_SUSTAINABILITY_TARGETS,
  ACTION.VIEW_DRAFT_SUSTAINABILITY_TARGETS,
  ACTION.UPDATE_SUSTAINABILITY_TARGETS,
  ACTION.VIEW_RECOMMENDATIONS,
  ACTION.VIEW_REPORTS,
  ACTION.DOWNLOAD_REPORTS,
  ACTION.VIEW_LIST_OF_ALL_SUPPLIERS,
  ACTION.SETUP_PRODUCT_DATA_SOURCE,
  ACTION.CREATE_INITIATIVES,
  ACTION.UPDATE_INITIATIVES,
  ACTION.DELETE_INITIATIVES,
  ACTION.VIEW_INITIATIVES,
  ACTION.VIEW_CORPORATE_INITIATIVES,
  ACTION.EDIT_CORPORATE_INITIATIVES,
  ACTION.CREATE_CORPORATE_INITIATIVES,
  ACTION.EDIT_CORPORATE_INITIATIVES_SCENARIOS,
  ACTION.VIEW_CUSTOM_DASHBOARDS,
  ACTION.CREATE_EMISSIONS_TARGETS,
  ACTION.VIEW_DRAFT_EMISSIONS_TARGETS,
  ACTION.VIEW_LIVE_EMISSIONS_TARGETS,
  ACTION.DELETE_EMISSIONS_TARGETS,
  ACTION.EDIT_EMISSIONS_TARGETS,
  ACTION.CREATE_SUPPLIER_INVITE,
  ACTION.CREATE_SUPPLIER_PRODUCT_FOOTPRINT_REQUESTS,
  ACTION.CREATE_SUPPLIER_PRODUCT_FOOTPRINT,
  ACTION.UPDATE_SUPPLIER_PRODUCT_FOOTPRINT_REQUEST,
  ACTION.VIEW_SUPPLIER_PRODUCT_FOOTPRINT,
  ACTION.ADD_BASIC_CALCULATION,
  ACTION.UPDATE_LOCKED_YEAR_STATUS,
  ACTION.CREATE_BASIC_CALCULATION,
  ACTION.CAN_DELETE_DATA_SET,
  ACTION.CAN_DELETE_DATA_SOURCE,
  ACTION.VIEW_DATA_REQUESTS,
  ACTION.VIEW_FRANCHISES,
  ACTION.CREATE_FRANCHISE,
  ACTION.VIEW_FRANCHISE_REQUEST,
  ACTION.SEND_FRANCHISE_INVITE,
  ACTION.DELETE_FRANCHISE,
  ACTION.EDIT_FRANCHISE,
  ACTION.ADD_FOOTPRINT_TO_FRANCHISE_REQUEST,
  ACTION.GET_FRANCHISORS,
  ACTION.UPDATE_SUPPLIER_INVITE_EMAIL,
]

const DATA_UPLOADER_EXTERNAL_PERMISSIONS = [ACTION.UPDATE_USER_PROFILE, ACTION.UPDATE_USER_SETTINGS]

const VIEWER_PERMISSIONS = [
  ACTION.UPDATE_USER_PROFILE,
  ACTION.UPDATE_USER_SETTINGS,
  ACTION.VIEW_TEAM_MEMBERS,
  ACTION.VIEW_A_LIST_OF_DATA_SOURCES,
  ACTION.VIEW_A_LIST_OF_DATA_SOURCES_TAGS,
  ACTION.CREATE_COMMENT_ON_A_DATA_SOURCE,
  ACTION.VIEW_THE_LIST_OF_BUSINESS_UNITS_IN_THE_ORGANISATION,
  ACTION.VIEW_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
  ACTION.DOWNLOAD_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
  ACTION.VIEW_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
  ACTION.DOWNLOAD_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
  ACTION.VIEW_LIVE_SUSTAINABILITY_TARGETS,
  ACTION.VIEW_DRAFT_SUSTAINABILITY_TARGETS,
  ACTION.VIEW_RECOMMENDATIONS,
  ACTION.VIEW_REPORTS,
  ACTION.DOWNLOAD_REPORTS,
  ACTION.VIEW_INITIATIVES,
  ACTION.VIEW_FACILITIES,
]

const CONTRIBUTOR_PERMISSIONS = [
  ACTION.UPDATE_USER_PROFILE,
  ACTION.UPDATE_USER_SETTINGS,
  ACTION.VIEW_TEAM_MEMBERS,
  ACTION.VIEW_A_LIST_OF_DATA_SOURCES,
  ACTION.VIEW_A_LIST_OF_DATA_SOURCES_TAGS,
  ACTION.CREATE_A_NEW_DATA_SOURCE,
  ACTION.UPDATE_DATA_SOURCE_METADATA,
  ACTION.UPLOAD_DATA_TO_THE_DATA_SOURCE,
  ACTION.CREATE_COMMENT_ON_A_DATA_SOURCE,
  ACTION.DOWNLOAD_DATA_FROM_A_DATA_SOURCE,
  ACTION.CREATE_INTENSITY_METRICS,
  ACTION.UPDATE_INTENSITY_METRICS,
  ACTION.VIEW_THE_LIST_OF_BUSINESS_UNITS_IN_THE_ORGANISATION,
  ACTION.VIEW_FACILITIES,
  ACTION.CREATE_FACILITIES,
  ACTION.UPDATE_FACILITIES,
  ACTION.VIEW_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
  ACTION.DOWNLOAD_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
  ACTION.VIEW_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
  ACTION.DOWNLOAD_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
  ACTION.VIEW_LIVE_SUSTAINABILITY_TARGETS,
  ACTION.VIEW_DRAFT_SUSTAINABILITY_TARGETS,
  ACTION.UPDATE_SUSTAINABILITY_TARGETS,
  ACTION.VIEW_RECOMMENDATIONS,
  ACTION.VIEW_REPORTS,
  ACTION.DOWNLOAD_REPORTS,
  ACTION.VIEW_LIST_OF_ALL_SUPPLIERS,
  ACTION.UPDATE_THE_DETAILS_OF_A_BUSINESS_UNIT,
  ACTION.VIEW_CORPORATE_INITIATIVES,
  ACTION.CREATE_INITIATIVES,
  ACTION.UPDATE_INITIATIVES,
  ACTION.DELETE_INITIATIVES,
  ACTION.VIEW_INITIATIVES,
  ACTION.VIEW_CUSTOM_DASHBOARDS,
  ACTION.VIEW_LIVE_EMISSIONS_TARGETS,
  ACTION.CREATE_SUPPLIER_PRODUCT_FOOTPRINT,
  ACTION.UPDATE_SUPPLIER_PRODUCT_FOOTPRINT_REQUEST,
  ACTION.VIEW_SUPPLIER_PRODUCT_FOOTPRINT,
  ACTION.CREATE_BASIC_CALCULATION,
  ACTION.ADD_BASIC_CALCULATION,
  ACTION.CAN_DELETE_DATA_SET,
  ACTION.CAN_DELETE_DATA_SOURCE,
  ACTION.VIEW_DATA_REQUESTS,
  ACTION.VIEW_FRANCHISES,
  ACTION.VIEW_FRANCHISE_REQUEST,
  ACTION.GET_FRANCHISORS,
  ACTION.CREATE_SUPPLIER_INVITE,
  ACTION.CREATE_SUPPLIER_PRODUCT_FOOTPRINT_REQUESTS,
  ACTION.UPDATE_SUPPLIER_PRODUCT_FOOTPRINT_REQUEST,
  ACTION.UPDATE_SUPPLIER_INVITE_EMAIL,
]

const NO_ACCESS_PERMISSIONS = [ACTION.UPDATE_USER_PROFILE, ACTION.UPDATE_USER_SETTINGS]

// eslint-disable-next-line no-shadow
export enum ROLE_KEYS {
  NO_ACCESS = 'NO_ACCESS',
  VIEWER = 'VIEWER',
  CONTRIBUTOR = 'CONTRIBUTOR',
  ADMIN = 'ADMIN',
  SOLUTION_ADVISOR = 'SOLUTION_ADVISOR',
  SUPER_USER = 'SUPER_USER',
  DATA_UPLOADER = 'DATA_UPLOADER',
  SUPPLIER_ADMIN = 'SUPPLIER_ADMIN',
  SUPPLIER_CONTRIBUTOR = 'SUPPLIER_CONTRIBUTOR',
}

export const ROLE_PERMISSIONS: { [key in ROLE_KEYS]?: ACTION[] } = {
  [ROLE_KEYS.NO_ACCESS]: NO_ACCESS_PERMISSIONS,
  [ROLE_KEYS.VIEWER]: VIEWER_PERMISSIONS,
  [ROLE_KEYS.CONTRIBUTOR]: CONTRIBUTOR_PERMISSIONS,
  [ROLE_KEYS.ADMIN]: ADMIN_PERMISSIONS,
  [ROLE_KEYS.SOLUTION_ADVISOR]: SOLUTION_ADVISOR_PERMISSIONS,
  [ROLE_KEYS.DATA_UPLOADER]: DATA_UPLOADER_EXTERNAL_PERMISSIONS,
  [ROLE_KEYS.SUPPLIER_ADMIN]: ADMIN_PERMISSIONS,
  [ROLE_KEYS.SUPPLIER_CONTRIBUTOR]: CONTRIBUTOR_PERMISSIONS,
}

export const ROLES_KEYS_MAP: { [K in ROLE_KEYS]?: string } = {
  NO_ACCESS: 'Restricted',
  VIEWER: 'Viewer',
  CONTRIBUTOR: 'Contributor',
  DATA_UPLOADER: 'Data uploader',
  ADMIN: 'Admin',
  SOLUTION_ADVISOR: 'Solution advisor',
  SUPER_USER: 'Super user',
}

/**
 * A hardcoded hierarchy of roles. This is used to determine if a user can be assigned a role.
 * For full details see https://www.figma.com/file/BtEiI5uV765hUfiyVqwKve/BU-Level-RBAC-Supporting-Logic?type=whiteboard&node-id=0-1&t=CG4dSFpxXaBc7ieI-0Z3jw
 */
export const ROLE_HIERARCHY = [
  ROLE_KEYS.NO_ACCESS,
  ROLE_KEYS.VIEWER,
  ROLE_KEYS.CONTRIBUTOR,
  ROLE_KEYS.DATA_UPLOADER,
  ROLE_KEYS.ADMIN,
  ROLE_KEYS.SOLUTION_ADVISOR,
  ROLE_KEYS.SUPER_USER,
]
