import { IsNumber } from 'class-validator'

import { FRANCHISE_REQUESTS_CONTROLLER } from './franchise-requests.common-vars'

export class GetFranchiseRequestsRespondedPercentageDTO {
  @IsNumber()
  year!: number
}

export const GET_FRANCHISE_REQUESTS_RESPONDED_PERCENTAGE_SUB_PATH = 'responded/percentage'

export const GET_FRANCHISE_REQUESTS_RESPONDED_PERCENTAGE_PATH = `${FRANCHISE_REQUESTS_CONTROLLER}/${GET_FRANCHISE_REQUESTS_RESPONDED_PERCENTAGE_SUB_PATH}`

export type GetFranchiseRequestsRespondedPercentageResponse = {
  result: number
}
