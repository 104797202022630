<template>
  <span
    :class="['ellipsis-text', { 'ellipsis-text--no-wrap': noWrap }]"
    :style="styles"
    ><slot></slot
  ></span>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'

  const props = defineProps<{
    noWrap?: boolean
    maxWidth?: number
  }>()

  const styles = computed(() => {
    if (!props.maxWidth) return {}

    return {
      display: 'inline-block',
      maxWidth: `${props.maxWidth}px`,
    }
  })
</script>

<style lang="scss" scoped>
  $module: 'ellipsis-text';

  .#{$module} {
    overflow: hidden;
    text-overflow: ellipsis;

    &--no-wrap {
      white-space: nowrap;
    }

    :global(.aq-datatable .#{$module}--no-wrap) {
      white-space: nowrap !important;
    }
  }
</style>
