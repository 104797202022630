import type { AxiosInstance } from 'axios'

import type {
  ProductEmissionsPerFacilityDTOReq,
  ProductEmissionsPerFacilityDTORes,
} from '@lib/DTO/products/details/product-per-facility.dto'

import type {
  TotalProductCarbonImpactDTORes,
  TotalProductCarbonImpactDTOReq,
} from '@lib/DTO/products/overview/metric-impact.dto'

import type {
  HighestEmittingProductCategoryDTORes,
  HighestEmittingProductCategoryDTOReq,
} from '@lib/DTO/products/overview/metric-highest-category.dto'

import type {
  HighestEmittingProductDTORes,
  HighestEmittingProductDTOReq,
} from '@lib/DTO/products/overview/metric-highest-product.dto'

import type { ProductListDTORes, ProductListDTOReq } from '@lib/DTO/products/overview/product-list.dto'

import type { CategoryListDTORes, CategoryListDTOReq } from '@lib/DTO/products/overview/category-list.dto'

import type { CategoryNamesDTOReq, CategoryNamesDTORes } from '@/imports/lib/DTO/products/overview/category-names.dto'

import type {
  ProductEmissionsByActivityDTOReq,
  ProductEmissionsByActivityDTORes,
} from '@/imports/lib/DTO/products/details/metric-activity-emissions.dto'

import type {
  HighestProductFacilityEmissionsDTOReq,
  HighestProductFacilityEmissionsDTORes,
} from '@/imports/lib/DTO/products/details/metric-highest-facility.dto'

import type {
  ProductTotalImpactDTOReq,
  ProductTotalImpactDTORes,
} from '@/imports/lib/DTO/products/details/metric-carbon-impact.dto'

import type { ProductInfoDTORes, ProductInfoDTOReq } from '@/imports/lib/DTO/products/details/product-info.dto'

import type { ActivityNamesDTOReq, ActivityNamesDTORes } from '@/imports/lib/DTO/products/details/activity-names.dto'
import type { ApiResponseInner } from '@/imports/@types/ApiResponse'
import type { Product, ProductWithFootprint } from '@/imports/@types/Product'
import type { ProductCategory } from '@/imports/@types/ProductCategory'
import type {
  Material,
  MaterialDetail,
  MaterialDetailRequestPayload,
  MaterialsRequestPayload,
} from '@/imports/@types/ProductMaterial'
import type { ProductPartsDTOReq, ProductPartsRes } from '@/imports/lib/DTO/products/details/product-parts.dto'

import type {
  SnapshotDetailsDTORes,
  SnapshotDetailsDTOReq,
} from '@/imports/lib/DTO/products/snapshot/snapshot-details.dto'

import type {
  ProductFacilitiesDTORes,
  ProductFacilitiesDTOReq,
} from '@/imports/lib/DTO/products/details/facility-overview.dto'

import type {
  ProductSnapshotsDTORes,
  ProductSnapshotsDTOReq,
} from '@/imports/lib/DTO/products/snapshot/snapshot-list.dto'

export class ProductsApi {
  private http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  getProducts(orgId: string) {
    return this.http.get<ApiResponseInner<{ products: Product[] }>>('/products/list', {
      params: {
        orgId,
      },
    })
  }

  getMetricImpact(orgId: string, metricImpactPayload: TotalProductCarbonImpactDTOReq) {
    return this.http.get<TotalProductCarbonImpactDTORes>(`/products/overview/metric-impact`, {
      params: {
        ...metricImpactPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getMetricCategory(orgId: string, metricCategoryPayload: HighestEmittingProductCategoryDTOReq) {
    return this.http.get<HighestEmittingProductCategoryDTORes>(`/products/overview/metric-highest-category`, {
      params: {
        ...metricCategoryPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getMetricHighestEmittingProduct(orgId: string, metricHighestEmittingProductPayload: HighestEmittingProductDTOReq) {
    return this.http.get<HighestEmittingProductDTORes>(`/products/overview/metric-highest-product`, {
      params: {
        ...metricHighestEmittingProductPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getProductsV2(orgId: string, productsPayload: ProductListDTOReq) {
    return this.http.get<ProductListDTORes>('/products/overview', {
      params: {
        ...productsPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getProductFootprint({ productId, orgId }: { productId: string; orgId: string }) {
    return this.http.get<ApiResponseInner<{ product: ProductWithFootprint }>>('/products/get', {
      params: {
        productId,
        orgId,
      },
    })
  }

  getCategoriesV2(orgId: string, categoriesPayload: CategoryListDTOReq) {
    return this.http.get<CategoryListDTORes>('/products/overview/categories', {
      params: {
        ...categoriesPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getCategoryNames(orgId: string, categoryNamesPayload: CategoryNamesDTOReq) {
    return this.http.get<CategoryNamesDTORes>('/products/overview/categories/names', {
      params: {
        ...categoryNamesPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getActivityNames(orgId: string, activityNamesPayload: ActivityNamesDTOReq) {
    return this.http.get<ActivityNamesDTORes>(`/products/${activityNamesPayload.productId}/activities`, {
      params: {
        ...activityNamesPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getCategories(orgId: string) {
    return this.http.get<ApiResponseInner<{ categories: ProductCategory[] }>>('/products/getCategories', {
      params: {
        orgId,
      },
    })
  }

  getMaterials(materialsRequestPayload: MaterialsRequestPayload) {
    return this.http.get<ApiResponseInner<Material[]>>('/products/materials', {
      params: {
        ...materialsRequestPayload,
      },
    })
  }

  getMaterialsDetails(materialDetailRequestPayload: MaterialDetailRequestPayload) {
    return this.http.get<ApiResponseInner<MaterialDetail>>('/products/materials/:id', {
      params: {
        ...materialDetailRequestPayload,
      },
    })
  }

  getSharedMaterials(materialsRequestPayload: MaterialsRequestPayload) {
    return this.http.get<ApiResponseInner<MaterialDetail>>('/products/shared-materials/:id', {
      params: {
        ...materialsRequestPayload,
      },
    })
  }

  getDataSourceTemplate(orgId: string) {
    return this.http.get<ApiResponseInner<unknown>>('/products/getdatasourcetemplate', {
      params: {
        orgId,
      },
    })
  }

  // Product footprint v2 work
  getProductDetails({ orgId, productId, startMonth, endMonth }: ProductInfoDTOReq & { orgId: string }) {
    return this.http.get<ProductInfoDTORes>(`/products/${productId}/detail`, {
      params: {
        startMonth,
        endMonth,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getProductTotalCarbonImpact({
    productId,
    startMonth,
    endMonth,
    orgId,
    facilityId,
    buId,
  }: ProductTotalImpactDTOReq & { orgId: string }) {
    return this.http.get<ProductTotalImpactDTORes>(`/products/${productId}/metric-carbon-impact`, {
      params: {
        startMonth,
        endMonth,
        facilityId,
        buId,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getProductTopFacilityEmissions({
    productId,
    startMonth,
    endMonth,
    orgId,
  }: HighestProductFacilityEmissionsDTOReq & { orgId: string }) {
    return this.http.get<HighestProductFacilityEmissionsDTORes>(`/products/${productId}/metric-highest-facility`, {
      params: {
        startMonth,
        endMonth,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getProductEmissionsByActivity({
    productId,
    startMonth,
    endMonth,
    orgId,
  }: ProductEmissionsByActivityDTOReq & { orgId: string }) {
    return this.http.get<ProductEmissionsByActivityDTORes>(`/products/${productId}/metric-activity-emissions`, {
      params: {
        startMonth,
        endMonth,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getEmissionsPerFacility(orgId: string, emissionsPerFacilityPayload: ProductEmissionsPerFacilityDTOReq) {
    return this.http.get<ProductEmissionsPerFacilityDTORes>(
      `/products/${emissionsPerFacilityPayload.productId}/facilities/details`,
      {
        params: {
          ...emissionsPerFacilityPayload,
        },
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  }

  getMaterialsAndPackaging(orgId: string, materialsAndPackagingPayload: ProductPartsDTOReq) {
    return this.http.get<ProductPartsRes>(`/products/${materialsAndPackagingPayload.productId}/parts`, {
      params: materialsAndPackagingPayload,
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getProductSnapshotDetails(orgId: string, productSnapshotPayload: SnapshotDetailsDTOReq) {
    const { productId, facilityId, snapshotId } = productSnapshotPayload

    return this.http.get<SnapshotDetailsDTORes>(
      `/products/${productId}/snapshots/facilities/${facilityId}/${snapshotId}`,
      {
        params: {
          ...productSnapshotPayload,
        },
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  }

  getProductFacilitySnapshotDetails(orgId: string, productSnapshotPayload: SnapshotDetailsDTOReq) {
    const { productId, facilityId, snapshotId } = productSnapshotPayload

    return this.http.get<SnapshotDetailsDTORes>(
      `/products/${productId}/facilities/${facilityId}/snapshots/${snapshotId}`,
      {
        params: {
          ...productSnapshotPayload,
        },
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  }

  getProductBUSnapshotDetails(orgId: string, productSnapshotPayload: SnapshotDetailsDTOReq) {
    const { productId, buId, snapshotId } = productSnapshotPayload

    return this.http.get<SnapshotDetailsDTORes>(`/products/${productId}/bus/${buId}/snapshots/${snapshotId}`, {
      params: {
        ...productSnapshotPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getProductFacilitySnapshots(orgId: string, productSnapshotsPayload: ProductSnapshotsDTOReq) {
    const { productId, facilityId } = productSnapshotsPayload

    return this.http.get<ProductSnapshotsDTORes>(`/products/${productId}/facilities/${facilityId}/snapshots`, {
      params: {
        ...productSnapshotsPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getProductBUSnapshots(orgId: string, productSnapshotsPayload: ProductSnapshotsDTOReq) {
    const { productId, buId } = productSnapshotsPayload

    return this.http.get<ProductSnapshotsDTORes>(`/products/${productId}/bus/${buId}/snapshots`, {
      params: {
        ...productSnapshotsPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getFacilities(orgId: string, productFacilitiesPayload: ProductFacilitiesDTOReq) {
    return this.http.get<ProductFacilitiesDTORes>(`/products/${productFacilitiesPayload.productId}/facilities`, {
      params: {
        ...productFacilitiesPayload,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }
}
