// Get initiative detail
// [GET] /initiatives/:initiativeUUID

import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { INITIATIVE_CONTROLLER, type InitiativeResponseObject } from './controller.common-vars'

// export const CONTROLLER = '/initiatives'
export const INITIATIVE_DETAIL_SUBPATH = ':initiativeId'
export const INITIATIVE_DETAIL_URI = `${INITIATIVE_CONTROLLER}/${INITIATIVE_DETAIL_SUBPATH}`

export class InitiativeDetailDTOReq extends ValidateDTO {
  @IsUUID()
  initiativeId!: string
}

export type InitiativeDetailDTORes = {
  initiative: InitiativeResponseObject
}
