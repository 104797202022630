import { defineStore } from 'pinia'

import { Api } from '@services/api.service'

import { showToast } from '@lib/plugins/Toastie'

import { useOrganizationStore } from '@/client/store/organization.pinia'

import type { LegalEntityV2, LegalEntityV2Initial } from '@/imports/@types/LegalEntity'

type State = {
  legalEntities: LegalEntityV2[]
  state: {
    error: string
    isLoading: boolean
  }
}

const initialState = (): State => ({
  legalEntities: [],
  state: {
    error: '',
    isLoading: false,
  },
})

export const useLegalEntitiesStore = defineStore('legalEntities', {
  state: initialState,

  getters: {
    orgId(): string | undefined {
      const orgStore = useOrganizationStore()

      return orgStore.activeOrganization?.id
    },
  },

  actions: {
    async getLegalEntities() {
      this.state = {
        isLoading: true,
        error: '',
      }

      if (!this.orgId) throw new Error('/legalEntities/getForOrganization >>> No active organisation found')

      try {
        const {
          data: { legalEntities },
        } = await Api.legalEntities.getLegalEntities({ orgId: this.orgId })

        this.legalEntities = legalEntities
      } catch (err) {
        console.error(err)

        this.showError('get')
      }

      this.state.isLoading = false
    },

    async removeLegalEntity(id: string) {
      this.state = {
        isLoading: true,
        error: '',
      }

      if (!this.orgId) throw new Error('/legalEntities/delete >>> No active organisation found')

      try {
        await Api.legalEntities.removeLegalEntity({ orgId: this.orgId, id })
        await this.getLegalEntities()
      } catch (err) {
        console.error(err)

        this.showError('remove')
      }

      this.state.isLoading = false
    },

    async editLegalEntity(entity: LegalEntityV2) {
      this.state = {
        isLoading: true,
        error: '',
      }

      if (!this.orgId) throw new Error('/legalEntities/update >>> No active organisation found')

      try {
        await Api.legalEntities.updateLegalEntity({ ...entity, orgId: this.orgId })
        await this.getLegalEntities()
      } catch (err) {
        console.error(err)

        this.showError('update')
      }

      this.state.isLoading = false
    },

    // TODO: Discuss in future originalSupplierHash
    async createLegalEntity(entity: LegalEntityV2Initial, originalSupplierHash?: string) {
      this.state = {
        isLoading: true,
        error: '',
      }

      if (!this.orgId) throw new Error('/legalEntities/create >>> No active organisation found')

      try {
        await Api.legalEntities.createLegalEntity({
          ...entity,
          orgId: this.orgId,
          ...(originalSupplierHash ? { originalSupplierHash } : {}),
        })
        await this.getLegalEntities()
      } catch (err) {
        console.error(err)

        this.showError('create')
      }

      this.state.isLoading = false
    },

    showError(method: string) {
      let errorMessage = ''

      if (method === 'get') {
        errorMessage = 'Failed to receive legal entities. Please try again later.'
      } else {
        errorMessage = `Failed to ${method} legal entity. Please try again later.`
      }

      showToast({
        type: 'is-danger',
        message: errorMessage,
      })

      this.state.error = errorMessage
    },
  },
})
