/**
 * Set the intended path that the user should arrive at
 *
 * @param {string} path - e.g. `/measure`
 */
export const setIntendedUrlPath = (path: string) => {
  sessionStorage.setItem('intendedUrlPath', path)
}

/**
 * Retrieve the intended path from storage
 *
 * @returns {string}
 */
export const getIntendedUrlPath = () => sessionStorage.getItem('intendedUrlPath')

/**
 * Retrieve and remove the intended path at once
 *
 * @returns {string}
 */
export const pullIntendedUrlPath = () => {
  const path = getIntendedUrlPath()

  if (path) {
    clearIntendedUrlPath()
  }

  return path
}

/**
 * Remove the intended path from storage
 */
export const clearIntendedUrlPath = () => {
  sessionStorage.removeItem('intendedUrlPath')
}
