import { AUDIT_LOG_CONTROLLER_PATH } from './controller.audit-log.vars'

export const AUDIT_LOG_GET_USERS_SUBPATH = 'users'
export const AUDIT_LOG_GET_USERS_URI = `${AUDIT_LOG_CONTROLLER_PATH}/${AUDIT_LOG_GET_USERS_SUBPATH}`

export type AuditLogsGetUsersDTORes = {
  users: {
    email: string
    userName: string
    avatar: string
    id: string
  }[]
}
