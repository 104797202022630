import type { NavigationGuard, RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

import type { ACTION } from '@lib/constants/permission/permissionConstants'

import { ROLE_KEYS } from '@/imports/lib/constants/permission/permissionConstants'

import { Api } from '@/imports/lib/services/api.service'
import FeatureFlagService from '@/imports/lib/services/featureFlagService'
import PermissionService from '@/imports/lib/services/permissionService'

import { useOrganizationStore } from '@/client/store/organization.pinia'
import { useUserStore } from '@/client/store/user.pinia'
import { setIntendedUrlPath } from '@/imports/lib/utilities/urlRedirector'
import { ORGANIZATION_MODULES } from '@/imports/@enums/organizations.enums'

/**
 * @type {{ADMIN: string, SA: string}}
 */
const ROLES = {
  ADMIN: ROLE_KEYS.ADMIN,
  SA: ROLE_KEYS.SOLUTION_ADVISOR,
}

/**
 * Check if a user has one or more permissions based on their role
 * - If 1 persmission is passed, it must match to continue routing
 * - If >1 permission is passed, it continues routing when at least 1 permission passes
 */
export const hasPermissions =
  (permissions: ACTION | ACTION[]): NavigationGuard =>
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (PermissionService.hasPermissions(permissions)) return next()

    return next({ name: 'noAccess' })
  }

const userHasRoles = (roles: string[]): boolean => {
  const orgStore = useOrganizationStore()

  return roles.includes(orgStore.userRole)
}

/**
 * Checks and initializes the user's session
 * Called before each route is run
 */
export const initializeSession: NavigationGuard = async (to, from, next) => {
  const userStore = useUserStore()

  const userId = userStore.id
  const sessionToken = localStorage.getItem('sessionToken')

  if (!userId && !to.meta.public) {
    if (!sessionToken) {
      if (!to.meta.dontUseAsIntendedPath) {
        setIntendedUrlPath(to.path)
      }
      return next({ name: 'login' })
    }

    const orgStore = useOrganizationStore()

    try {
      const { data: session } = await Api.user.resumeSession(sessionToken)

      if (session) {
        userStore.setSession({ session })

        await orgStore.getUserOrganizations()
        await orgStore.setActiveOrganizationById()

        // if NO_ACCESS role, redirect to request access
        const currentRole = orgStore.userRole
        if (currentRole === ROLE_KEYS.NO_ACCESS) {
          return next({ name: 'request-access' })
        }
      }
    } catch (e) {
      console.warn(e)
      localStorage.removeItem('sessionToken')
      if (!to.meta.dontUseAsIntendedPath) {
        setIntendedUrlPath(to.path)
      }
      return next({ name: 'login' })
    }
  }

  next()
}

export const checkForUserRole: NavigationGuard = (to, from, next) => {
  const userStore = useUserStore()
  const userId = userStore.user.id

  if (userId) return next()
  return next({ name: 'login' })
}

export const checkForAdminRole: NavigationGuard = (to, from, next) => {
  const userStore = useUserStore()
  const orgStore = useOrganizationStore()

  const userRole = orgStore.userRole
  const isSuperUser = userStore.isSuperUser

  if ([ROLE_KEYS.ADMIN, ROLE_KEYS.SUPPLIER_ADMIN].includes(userRole as ROLE_KEYS) || isSuperUser) return next()

  return next({ name: 'index' })
}

export const checkForSuperuserRole: NavigationGuard = (to, from, next) => {
  const userStore = useUserStore()
  const isSuperUser = userStore.isSuperUser

  if (isSuperUser) return next()

  return next({ name: 'index' })
}

export const checkForAdvisorRole: NavigationGuard = (to, from, next) => {
  const userStore = useUserStore()
  const isSuperUser = userStore.isSuperUser

  if (isSuperUser) return next()

  if (userHasRoles([ROLES.SA])) return next()

  return next({ name: 'index' })
}

export const checkForEnabledFeatures: NavigationGuard = (to, from, next) => {
  const requiredFlags = to.meta?.requiredFeatureFlags as string[]
  if (typeof requiredFlags === 'undefined') return next()

  const hasFeature = requiredFlags.some(flag => FeatureFlagService.isEnabled(flag))

  if (hasFeature) return next()

  return next({ name: 'index' })
}

export const checkForAllEnabledFeatures: NavigationGuard = (to, from, next) => {
  const requiredFlags = to.meta?.requiredFeatureFlags as string[]
  if (typeof requiredFlags === 'undefined') return next()

  const hasFeature = requiredFlags.every(flag => FeatureFlagService.isEnabled(flag))

  if (hasFeature) return next()

  return next({ name: 'index' })
}

export const checkForOrgModuleEnabled =
  (requiredOrgModule: ORGANIZATION_MODULES): NavigationGuard =>
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const orgStore = useOrganizationStore()

    const moduleEnabled = orgStore.enabledModules.includes(requiredOrgModule)
    if (moduleEnabled) return next()

    return next({ name: 'index' })
  }

export const checkIsActiveOrgRootOrg: NavigationGuard = (to, from, next) => {
  const orgStore = useOrganizationStore()
  const { activeOrganization, rootOrgId } = orgStore

  if (!rootOrgId || !activeOrganization) return next(from)

  if (activeOrganization?.id === rootOrgId) return next()

  return next(from)
}

/**
 * Validates a request token passed through to the PDF request route
 * @returns {Promise<void>}
 */
export const checkForPDFToken: NavigationGuard = async (to, from, next) => {
  const orgStore = useOrganizationStore()

  // Also possible to access the page when logged in and an admin
  if (orgStore.userRole) return checkForAdminRole(to, from, next)

  const requestKey = to.query.requestKey
  const orgId = to.params.orgId

  if (!requestKey || !orgId) next({ name: 'login' })

  const keyValid = await Api.call('pdfRequest.isRequestTokenValid', { requestKey, orgId })

  return keyValid?.result ? next() : next({ name: 'login' })
}

/**
 * We use this check when we want to select which of two components should be loaded
 * for a shared path e.g. when we are rolling out the new implementation of an existing feature.
 */
export const checkForFeatureFlagExpectedRoute: NavigationGuard = async (to, from, next) => {
  type featureFlagForRoute = {
    flag: string
    name: string
  }

  const { flag, name } = to.meta?.featureFlagForRoute as featureFlagForRoute

  const isFeatureEnabled = FeatureFlagService.isEnabled(flag)

  const route = {
    name,
    params: to.params,
  }

  /** If the feature flag is enabled, we load the component as specified by our new route.
   *  If not, we use the existing component as specified by the origial route.
   */
  return isFeatureEnabled ? next() : next(route)
}

export const checkIfSupplier: NavigationGuard = (to, from, next) => {
  const orgStore = useOrganizationStore()

  const { isSupplier } = orgStore

  return isSupplier ? next() : next({ name: 'index' })
}

export const checkIfBasic: NavigationGuard = (to, from, next) => {
  const orgStore = useOrganizationStore()

  const { isBasic } = orgStore

  return isBasic ? next() : next({ name: 'index' })
}
