<template>
  <component
    :is="element"
    ref="rootElement"
    :class="['dropdown-item', { 'small-padding': smallPadding, selected, disabled }]"
    data-ci="dropdown-item"
    @click="deferClicksToChild"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
  import { ref } from 'vue'

  type Props = {
    smallPadding?: boolean
    element?: string
    selected?: boolean
    disabled?: boolean
  }

  withDefaults(defineProps<Props>(), {
    element: 'li',
  })

  const rootElement = ref<HTMLElement | null>(null)

  const deferClicksToChild = (event: MouseEvent) => {
    const targetMatches = event.target === event.currentTarget
    const rootElementExists = rootElement.value instanceof HTMLElement
    const rootElementHasChildren = rootElementExists && rootElement.value?.children[0]

    const firstChild = rootElement.value?.children[0]

    if (targetMatches && rootElementHasChildren && firstChild instanceof HTMLElement) {
      firstChild.click()
    }
  }
</script>

<style lang="scss" scoped>
  $module: 'dropdown-item';

  .#{$module} {
    color: $grey-1;
    font-size: $font-size-7;
    line-height: 1.5;
    padding: 10px 16px;
    cursor: pointer;
    border-radius: $radius;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &:focus-within,
    &.selected,
    &:has(*:focus) {
      background-color: $grey-7;
    }

    :deep(*:focus) {
      outline: 0;
    }

    &.small-padding {
      padding: $grid-size-padding;
    }

    &.disabled,
    &.disabled :deep(> *) {
      color: $grey-3;
    }

    &.disabled,
    &.disabled :deep(*) {
      cursor: not-allowed;
    }
  }
</style>
