<template>
  <ul class="recursive-bu-item__items">
    <template
      v-for="bu in orgStructure"
      :key="bu.orgId"
    >
      <li
        v-if="bu"
        :class="[
          'recursive-bu-item__parent',
          {
            'recursive-bu-item__parent--is-open': isAccordionOpen[bu.orgId],
            'has-orgStructure': bu.children?.length,
          },
        ]"
        data-ci="bu-root-li"
        :data-id="bu.orgId.slice(0, 8)"
      >
        <div :class="['recursive-bu-item__item', { 'recursive-bu-item__item--closed': !bu.isOpen }]">
          <a
            class="recursive-bu-item__accordion"
            data-ci="accordion-toggle"
            @click="toggleAccordion(bu.orgId)"
          >
            <Chevron
              :class="[
                'recursive-bu-item__accordion-chevron',
                {
                  'recursive-bu-item__accordion-chevron--invisible':
                    !bu.children?.length || currentNestingLevel >= maxNestingLevel,
                },
              ]"
              width="8"
              :direction="isAccordionOpen[bu.orgId] ? 'down' : 'right'"
            />
            <img
              v-if="bu.orgLogo"
              class="recursive-bu-item__logo"
              :src="bu.orgLogo"
              :alt="`${bu?.name} logo`"
              data-ci="bu-logo"
            />
            <span
              v-else
              class="recursive-bu-item__empty-logo"
              data-ci="empty-bu-logo"
            >
              <div v-if="isUserRestricted(bu)">R</div>
              <div v-else>{{ abbreviateName(bu.name) }}</div>
            </span>
            <span class="recursive-bu-item__name">
              <EllipsisText
                :noWrap="true"
                :title="bu.name"
              >
                <div v-if="isUserRestricted(bu)">Restricted</div>
                <div v-else>
                  <tippy v-if="!bu.isOpen">
                    <span class="recursive-bu-item__closed-dot mr-1"></span>
                    <template v-slot:content> Currently closed </template>
                  </tippy>
                  {{ bu.name }}
                </div>
              </EllipsisText>
            </span>
            <div
              v-if="isRootOrg(bu.orgId)"
              class="recursive-bu-item__globe"
            >
              <i
                class="far fa-globe"
                data-ci="rootorg-indicator"
              ></i>
            </div>
          </a>

          <!-- This slot passed on the org as props to whatever it renders -->
          <div class="recursive-bu-item__right">
            <slot
              :org="bu"
              name="right"
            ></slot>
          </div>
        </div>

        <Accordion
          v-if="currentNestingLevel < maxNestingLevel && bu.children.length"
          :open="isAccordionOpen[bu.orgId]"
          :fullWidth="true"
          class="recursive-bu-item__child-accordion"
        >
          <ul class="recursive-bu-item__orgStructure">
            <RecursiveBUItem
              :orgStructure="bu.children"
              :currentNestingLevel="currentNestingLevel + 1"
              :maxNestingLevel="maxNestingLevel"
              :rootOrgId="rootOrgId"
              :isExpanded="isExpanded"
              :data-id="bu.orgId.slice(0, 8)"
            >
              <!-- 
                The content of the parent slot needs to be passed on to the orgStructure
                But this new (recursively rendered) template also receives props from the slot
                So these are set again on the component

                What happens next is that the slot is replaced with this component
                And the correct orgId is set
                
                It's quite magical and difficult to wrap your head around, but it works 🪄
               -->
              <template v-slot:right="rightSlotProps">
                <component
                  :is="$slots.right"
                  :org="rightSlotProps.org"
                  :data-id="bu.orgId.slice(0, 8)"
                />
              </template>
            </RecursiveBUItem>
          </ul>
        </Accordion>
      </li>
    </template>
  </ul>
</template>

<script lang="ts" setup>
  import { ref } from 'vue'

  import Chevron from '@components/icons/Chevron.vue'
  import Accordion from '@components/designsystem/Accordion/Accordion.vue'
  import EllipsisText from '@components/EllipsisText/EllipsisText.vue'

  import { abbreviateName } from '@/imports/lib/utilities/organizationStructureUtilities'

  import { useUserStore } from '@/client/store/user.pinia'
  import type { BusinessUnitResponseStructure } from '@/imports/@types/organizationStructure/v2/OrganizationStructure'
  import { ROLE_KEYS } from '@/imports/lib/constants/permission/permissionConstants'

  type Props = {
    orgStructure: BusinessUnitResponseStructure[]
    currentNestingLevel?: number
    maxNestingLevel: number
    rootOrgId?: string
    isExpanded: boolean
    hideRestricted?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    currentNestingLevel: 0,
    isExpanded: false,
    rootOrgId: '',
    hideRestricted: true,
  })

  const isAccordionOpen = ref<{ [key: string]: boolean }>({})

  // expand the accordians if defined
  props.orgStructure.forEach(c => {
    isAccordionOpen.value[c.id] = props.isExpanded && c.children.length > 0
  })

  const toggleAccordion = (accordionId: string) => {
    isAccordionOpen.value[accordionId] = !isAccordionOpen.value[accordionId]
  }

  const isUserRestricted = (bu: BusinessUnitResponseStructure) => {
    const userStore = useUserStore()
    if (userStore.isSuperUser) return false

    const currentUserRole = bu.users.find(u => u.email === userStore.user.email)

    return props.hideRestricted && (currentUserRole?.role === ROLE_KEYS.NO_ACCESS || !currentUserRole)
  }

  const isRootOrg = (id: string) => props.rootOrgId === id
</script>

<style lang="scss" scoped>
  $module: 'recursive-bu-item';
  $action-btn-width: 250px;

  .#{$module} {
    &__items {
      width: 100%;
    }

    &__logo {
      width: 28px;
      height: 28px;
      margin-right: $grid-size-margin;
      margin-left: $grid-size-margin;
      border-radius: 6px;
    }

    &__empty-logo {
      @extend .#{$module}__logo;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $font-size-8;
      font-weight: 500;
      background-color: $grey-6;
      color: $grey-1;
      width: 28px;
      height: 28px;
      line-height: 0;
      margin: 0 $grid-size-margin;
      flex-shrink: 0;

      .#{$module}__item--closed & {
        color: $grey-3;
      }
    }

    &__accordion {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $grey-1;
      font-weight: 500;
      min-width: 0;

      &:not(.has-orgStructure &) {
        cursor: default;
      }
    }

    &__right {
      display: flex;
      flex-shrink: 0;
      min-width: 0;
    }

    &__accordion-chevron {
      flex-grow: 0;
      flex-shrink: 0;

      &--invisible {
        opacity: 0;
      }
    }

    &__parent {
      position: relative;
      width: 100%;

      &--is-open::before {
        content: '';
        position: absolute;
        bottom: 0%;
        left: 9px;
        width: 1px;
        height: calc(100% - 48px);
        background-color: $grey-5;
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: $grid-size-margin;
    }

    &__name {
      margin-right: $grid-size-margin;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: $font-size-8;
      font-weight: 500;

      .#{$module}__item--closed & {
        color: $grey-3;
      }
    }

    &__globe {
      color: $grey-2;
      flex-shrink: 0;
    }

    &__orgStructure {
      padding-left: $grid-size-padding * 9;
    }

    &__closed-dot {
      display: inline-block;
      background-color: $red-dark;
      height: 8px;
      width: 8px;
      border-radius: 5px;
    }
  }
</style>
