<template>
  <div id="impersonating-layout">
    <SideNav />
    <NavBar />
    <div
      class="page-content has-sidebar"
      data-scroll="page-content"
      :class="[{ impersonating: isImpersonating }]"
    >
      <BetaBanner v-if="showBetaHeader" />
      <div class="page-content__padding">
        <Notification
          v-if="isImpersonating"
          class="impersonate mb-2"
          variant="danger"
        >
          <template v-slot:content> NOTE: You are impersonating an organisation.</template>
        </Notification>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'

  import SideNav from '@components/SideNav/SideNav.vue'

  import BetaBanner from '@components/designsystem/BetaBanner/BetaBanner.vue'
  import NavBar from '@components/NavBar/NavBar.vue'
  import Notification from '@components/designsystem/Notification/Notification.vue'

  import { useUserStore } from '@/client/store/user.pinia'

  const isImpersonating = useUserStore().isImpersonating

  const props = defineProps<{
    showBetaHeader: boolean
  }>()

  const showBetaHeader = computed(() => props.showBetaHeader || false)
</script>

<style lang="scss">
  .page-content {
    position: absolute;
    top: $topbar-height;
    bottom: 0;
    right: 0;
    left: $sidebar-width;
    overflow-y: scroll;
    overflow-x: hidden;

    &.has-sidebar.has-mobile-state {
      @media only screen and (max-width: 1440px) {
        left: 93px;
      }

      @media only screen and (min-width: 1441px) {
        left: $sidebar-width;
      }
    }

    // rollout-navigation-v2
    &.has-sidebar:not(.has-mobile-state) {
      left: $sidebar-width-v2;

      @media (max-width: 850px) {
        padding: $grid-size-padding * 4 $grid-size-padding * 6;
      }
    }

    &.impersonating {
      border: 3px solid red;
    }
    &__padding {
      padding: $grid-size-padding * 10;
    }
  }
</style>
