import { ROLE_KEYS } from './permission/permissionConstants'

export const AVAILABLE_INTENSITY_METRICS = [
  {
    value: 'facility_count',
    name: 'Per facility/office',
    baselinePrompt: 'Facilities/offices at the end of baseline period',
    allowedOrgTypes: ['financialServices', 'supportServices', 'brands', 'farmBrands', 'FMCGsRetailers'],
  },
  {
    value: 'location_count',
    name: 'Per location',
    baselinePrompt: '',
    allowedOrgTypes: [],
  },
  {
    value: 'employee_count',
    name: 'Per employee',
    baselinePrompt: 'Employees at end of the baseline period',
    allowedOrgTypes: [
      'financialServices',
      'supportServices',
      'B2BLogistics',
      'B2CLogistics',
      'brands',
      'farmBrands',
      'FMCGsRetailers',
    ],
  },
  {
    value: 'total_revenue',
    name: 'Per unit of revenue',
    baselinePrompt: 'Recognised revenue in 12m baseline period',
    allowedOrgTypes: [],
  },
  {
    value: 'customer_count',
    name: 'Per unique customer',
    baselinePrompt: 'Unique customers sold to in 12m baseline period',
    allowedOrgTypes: ['brands', 'farmBrands', 'FMCGsRetailers'],
  },
  {
    value: 'order_count',
    name: 'Per order',
    baselinePrompt: 'Orders completed in 12m baseline period',
    allowedOrgTypes: ['brands', 'farmBrands', 'FMCGsRetailers'],
  },
  {
    value: 'deal_count',
    name: 'Per deal',
    baselinePrompt: 'Deals closed in 12m to end of baseline priod',
    allowedOrgTypes: ['financialServices'],
  },
  {
    value: 'fund_count',
    name: 'Per fund',
    baselinePrompt: 'Funds open to end of baseline period',
    allowedOrgTypes: ['financialServices'],
  },
  {
    value: 'project_count',
    name: 'Per project',
    baselinePrompt: 'Projects completed in 12m to end of baseline period',
    allowedOrgTypes: ['supportServices'],
  },
  {
    value: 'client_count',
    name: 'Per client',
    baselinePrompt: 'Active clients at end of baseline period',
    allowedOrgTypes: ['supportServices', 'B2BLogistics', 'B2CLogistics'],
  },
  {
    value: 'delivery_km',
    name: 'Per delivery KM',
    baselinePrompt: 'Total logistics kilometers travelled in 12m baseline period',
    allowedOrgTypes: ['B2BLogistics', 'B2CLogistics'],
  },
  {
    value: 'trip_count',
    name: 'Per trip',
    baselinePrompt: 'Total logistics kilometers travelled in 12m baseline period',
    allowedOrgTypes: ['B2BLogistics', 'B2CLogistics'],
  },
  {
    value: 'product_sold_count',
    name: 'Per product sold',
    baselinePrompt: 'SKU types sold in 12m baseline period',
    allowedOrgTypes: ['brands', 'farmBrands'],
  },
  {
    value: 'hectare_farmed',
    name: 'Per hectare farmed',
    baselinePrompt: 'Total land use farmed by company in 12m baseline period',
    allowedOrgTypes: ['farmBrands'],
  },
]

export const DEFAULT_INTENSITY_METRICS = [
  {
    name: 'Per $ revenue',
    description: 'Revenue in year',
    values: {},
    isVisibleOnDashboard: false,
  },
  {
    name: 'Per employee',
    description: '# employees at end of the year',
    values: {},
    isVisibleOnDashboard: false,
  },
  {
    name: 'Per unique customer',
    description: '# unique customers sold to in year',
    values: {},
    isVisibleOnDashboard: false,
  },
  {
    name: 'Per facility',
    description: '# facilities at the end of the year',
    values: {},
    isVisibleOnDashboard: false,
  },
  {
    name: 'Per order',
    description: '# orders completed in year',
    values: {},
    isVisibleOnDashboard: false,
  },
]

export const INTENSITY_METRIC_COLORS = [
  {
    name: 'per $ revenue',
  },
]

export const AVAILABLE_PAGE_KEYS = ['products', 'projects', 'facilities', 'businessTravel']

export const SCOPE_OPTIONS = [
  {
    label: 'Scope 1',
    value: 1,
  },
  {
    label: 'Scope 2',
    value: 2,
  },
  {
    label: 'Scope 3',
    value: 3,
  },
]

export const SCOPE_COLORS = ['#005B5E', '#FFC67B', '#E8B3E8'] // Scope 1, 2, 3
export const SCOPE_COLOR_NAMES = {}

export const SCOPE_OR_ACTIVITY_AREA_COLOR_NAMES = {
  'Scope 1': 'kelp',
  'Scope 2': 'sahara',
  'Scope 3': 'noctiluca',
  'Utility Use': 'grass',
  'Waste Generation': 'cornflower',
  'Waste generation': 'cornflower',
  Waste: 'cornflower',
  'Use of Products': 'lavender',
  'Direct Gas Release': 'coral',
  Purchases: 'yellow-orange',
  Transportation: 'picton-blue',
  Miscellaneous: 'misc',
  other: 'grey-3',
  'Total Impact': 'kelp',
  'Uk Average': 'grey-3',
  Logistics: 'picton-blue',
  Other: 'grey-3',
  'Electricity Use': 'mac-n-cheese',
  Heating: 'mac-n-cheese',
  'Combustion for stationary operations': 'coral',
  'Wastewater generation': 'cornflower',
  'Post-Consumer Waste': 'cornflower',
  'Release of Fugitive Emissions': 'cornflower',
  'Fugitive Emissions': 'cornflower',
  Commuting: 'picton-blue',
  'Processing of sold goods / services': 'grey-3',
  'Raw Materials': 'lavender',
  'Shared Packaging': 'yellow-orange',
  Materials: 'yellow-orange',
  Packaging: 'yellow-orange',
}

export const CHART_GREY_BASE = '#DDDDDD'
export const CHART_GREEN_BASE = '#2FB694'
export const CHART_RED_BASE = '#FF4949'
export const CHART_TRENDLINE_COLOR = '#B3B3B3'
export const CHART_ANNOTATION_FONT_COLOR = '#666666'
export const CHART_ANNOTATION_BORDER_COLOR = '#E8E8E8'
export const CHART_BAR_COLOR = '#00767A'

export const ACTIVITY_GHG_TOGGLE_BUTTONS = [
  {
    key: 'activities',
    text: 'Activities',
    academyTooltip: {
      link: 'https://academy.altruistiq.com/blog/activities-methodolody',
      text: "Our methodology is built in accordance with the GHG Protocol's Corporate Reporting Standard.",
    },
  },
  {
    key: 'ghg',
    text: 'GHG Protocol',
  },
]

export const SCOPE3_GHG_CATEGORIES = {
  purchased_goods_and_services: 'Purchased Goods & Services',
  capital_goods: 'Capital Goods',
  other_fuel_and_energy_related_activities: 'Other Fuel & Energy Related Activities',
  upstream_transportation_and_distribution: 'Upstream Transportation & Distribution',
  waste_generated_in_operations: 'Waste Generated in Operations',
  business_travel: 'Business Travel',
  employee_commuting: 'Employee Commuting',
  upstream_leased_assets: 'Upstream Leased Assets',
  downstream_transportation_distribution: 'Downstream Transportation & Distribution',
  processing_of_sold_products: 'Processing of Sold Products',
  use_of_sold_products: 'Use of Sold Products',
  downstream_leased_assets: 'Downstream Leased Assets',
  franchises: 'Franchises',
  investments: 'Investments',
  end_of_life_treatment_of_sold_products: 'End-of-Life Treatment of Sold Products',
}

export const SCOPE_INDEXES = {
  scope1: 0,
  scope2: 1,
  scope3: 2,
}

/**
 * The following constants are used to map the different currency types to their respective symbols and titles
 * We use different types of props to cover both DATA/APP level usage
 */
export const CURRENCY_MAPPING = {
  pounds: { symbol: '£', title: '£ Pounds' },
  dollar: { symbol: '$', title: '$ Dollars' },
  euro: { symbol: '€', title: 'Euro €' },
}

export const DATA_CURRENCY_MAPPING = {
  EUR: { symbol: '€', title: 'Euro €' },
  GBP: { symbol: '£', title: '£ Pounds' },
  USD: { symbol: '$', title: '$ Dollars' },
}

export const APP_TO_DATA_CURRENCY_CONVERSION = {
  pounds: DATA_CURRENCY_MAPPING.GBP,
  dollar: DATA_CURRENCY_MAPPING.USD,
  euro: DATA_CURRENCY_MAPPING.EUR,
}

export const CONTRACT_SCOPES_TO_NAMES_MAP = {
  scopes_1_2: 'Scope 1 & 2',
  scopes_1_2_3_upstream: 'Scope 1 & 2 + Upstream Scope 3',
}

export const CONTRACT_SCOPES_ADDON_TYPES = [
  {
    value: 'product_footprint_module',
    label: 'Product Footprint Module',
    selected: false,
  },
  {
    value: 'supplier_engagement_module',
    label: 'Supplier Engagement Module',
    selected: false,
  },
]

export const CONTRACT_SCOPES_CLIENT_TYPES = {
  self: 'Enterprise',
  full: 'Pro',
  supplier: 'Supplier',
}

export const ORGANIZATION_SECTIONS = [
  { component: 'EditClientDetails', page: 'scope' },
  { component: 'EditContractScope', page: 'scope' },
  { component: 'EditContractedEntities', page: 'scope' },
  { component: 'EditAsanaCompanyMapping', page: 'scope' },
  { component: 'EditOrganizationDetails', page: 'details' },
  { component: 'EditOrganizationSettings', page: 'details' },
  { component: 'EditOrganizationDetails', page: 'supplierOrgDetails' },
  { component: 'EditOrganizationSettings', page: 'supplierSettings' },
  { component: 'EditDeepDivePages', page: 'deepDivePages' },
  { component: 'EditIntensityMetrics', page: 'metrics' },
  { component: 'EditReportingRequirements', page: 'reporting' },
  { component: 'EditAuthenticationType', page: 'authentication' },
  { component: 'EditOAuthIntegrations', page: 'authentication', hideOnPage: 'create' },
  { component: 'EditDataLandingZone', page: 'dataLanding' },
  { component: 'UserTable', page: 'users' },
]

export const ORGANIZATION_STEPS = [
  {
    page: 'scope',
    label: 'Contract scope',
    requiredValidations: ['EditClientDetails', 'EditContractScope', 'EditAsanaCompanyMapping'],
    appearanceRules: [
      {
        userType: ROLE_KEYS.ADMIN,
        mode: 'create',
        orgType: 'full',
        isVisible: false,
      },
      {
        userType: ROLE_KEYS.ADMIN,
        mode: 'edit',
        orgType: 'full',
        isVisible: false,
      },
      {
        userType: ROLE_KEYS.ADMIN,
        mode: 'create',
        orgType: 'self',
        isVisible: false,
      },
      {
        userType: ROLE_KEYS.ADMIN,
        mode: 'edit',
        orgType: 'self',
        isVisible: false,
      },
      {
        userType: ROLE_KEYS.SUPER_USER,
        mode: 'create',
        orgType: 'full',
        isVisible: true,
      },
      {
        userType: ROLE_KEYS.SUPER_USER,
        mode: 'edit',
        orgType: 'full',
        isVisible: true,
      },
      {
        userType: ROLE_KEYS.SUPER_USER,
        mode: 'create',
        orgType: 'self',
        isVisible: true,
      },
      {
        userType: ROLE_KEYS.SUPER_USER,
        mode: 'edit',
        orgType: 'self',
        isVisible: true,
      },
    ],
  },
  {
    page: 'details',
    label: 'Details & settings',
    requiredValidations: [],
    appearanceRules: [
      {
        userType: ROLE_KEYS.ADMIN,
        mode: 'create',
        orgType: 'full',
        isVisible: false,
      },
      {
        userType: ROLE_KEYS.ADMIN,
        mode: 'edit',
        orgType: 'full',
        isVisible: true,
      },
      {
        userType: ROLE_KEYS.ADMIN,
        mode: 'create',
        orgType: 'self',
        isVisible: true,
      },
      {
        userType: ROLE_KEYS.ADMIN,
        mode: 'edit',
        orgType: 'self',
        isVisible: true,
      },
      {
        userType: ROLE_KEYS.SUPER_USER,
        mode: 'create',
        orgType: 'full',
        isVisible: true,
      },
      {
        userType: ROLE_KEYS.SUPER_USER,
        mode: 'edit',
        orgType: 'full',
        isVisible: true,
      },
      {
        userType: ROLE_KEYS.SUPER_USER,
        mode: 'create',
        orgType: 'self',
        isVisible: false,
      },
      {
        userType: ROLE_KEYS.SUPER_USER,
        mode: 'edit',
        orgType: 'self',
        isVisible: false,
      },
    ],
  },
  {
    page: 'deepDivePages',
    label: 'Deep Dive Pages',
    requiredValidations: [],
    appearanceRules: [
      {
        userType: ROLE_KEYS.ADMIN,
        mode: 'create',
        orgType: 'full',
        isVisible: false,
      },
      {
        userType: ROLE_KEYS.ADMIN,
        mode: 'edit',
        orgType: 'full',
        isVisible: true,
      },
      {
        userType: ROLE_KEYS.ADMIN,
        mode: 'create',
        orgType: 'self',
        isVisible: false,
      },
      {
        userType: ROLE_KEYS.ADMIN,
        mode: 'edit',
        orgType: 'self',
        isVisible: true,
      },
      {
        userType: ROLE_KEYS.SUPER_USER,
        mode: 'create',
        orgType: 'full',
        isVisible: true,
      },
      {
        userType: ROLE_KEYS.SUPER_USER,
        mode: 'edit',
        orgType: 'full',
        isVisible: true,
      },
      {
        userType: ROLE_KEYS.SUPER_USER,
        mode: 'create',
        orgType: 'self',
        isVisible: false,
      },
      {
        userType: ROLE_KEYS.SUPER_USER,
        mode: 'edit',
        orgType: 'self',
        isVisible: false,
      },
    ],
  },
  {
    page: 'supplierOrgDetails',
    label: 'Organisation details',
    requiredValidations: [],
    appearanceRules: [
      {
        userType: ROLE_KEYS.SUPPLIER_ADMIN,
        mode: 'edit',
        orgType: 'self',
        isVisible: true,
      },
      {
        userType: ROLE_KEYS.SUPPLIER_ADMIN,
        mode: 'edit',
        orgType: 'full',
        isVisible: true,
      },
    ],
  },
  {
    page: 'supplierSettings',
    label: 'Settings',
    requiredValidations: [],
    appearanceRules: [
      {
        userType: ROLE_KEYS.SUPPLIER_ADMIN,
        mode: 'edit',
        orgType: 'self',
        isVisible: true,
      },
      {
        userType: ROLE_KEYS.SUPPLIER_ADMIN,
        mode: 'edit',
        orgType: 'full',
        isVisible: true,
      },
    ],
  },
]

export const ACTIVITY_DESCRIPTIONS = {
  1: "Your company does not use any electricity that's either purchased from the grid or from a supplier via direct transfer, or generated on-site.",
  2: 'Your company does not operate in or own any facilities that are heated or cooled using direct fuel transfer, for example boilers in your offices, or direct heat / steam transfers.',
  3: 'Your company does not use any purchased water from the mains supply.',
  4: "Your company does not burn any fuels for the completion of operations. This doesn't include fuels burned for heating (e.g. a boiler) but does include burning fuels e.g. to smelt glass.",
  5: 'Your company does not generate any solid waste in its operations.',
  6: 'Your company does not generate any wastewater in its operations. If there is any use of mains supply water, there will also be a level of wastewater generation.',
  7: "Your company does not produce any products or services that would lead to the generation of waste after completion of the company's operations.",
  8: 'Your company does not own or use any refrigeration or air conditioning equipment, or your company has had fugitive emissions measured as zero for this equipment.',
  9: 'Your company does not create any GHG emissions as a direct result of chemical or biological processes in its operations. E.g. direct emissions would result from management of cattle or manufacture of concrete.',
  10: 'Your company does not use or own any logistics operations. Logistics is defined as any vehicle transportation of goods or services, which could be upstream (inbound), between facilities or downstream of your company.',
  11: 'Your company does not use any forms of business transport.',
  12: "Your company's employees do not commute to any working spaces.",
  13: 'Your company has not purchased any materials, goods or services.',
  14: "Your company's goods or services are final products sold directly to consumers, without any further business processing.",
  15: "The use of your company's sold goods or services by consumers does not generate any direct emissions. Indirect emissions (e.g. those from heating or refrigeration of food) are optionally reported.",
  16: 'Your company does not lease any capital goods from upstream vendors that are still managed by a 3rd party e.g. co-working spaces or large machinery maintained by the lessee.',
  17: 'Your company does not have any investments, and does not provide financial services.',
  18: 'Your company does not have any franchises with active business ongoing.',
}

export const ACTIVITY_UPLOAD_TEMPLATE_FILES = {
  waste: 'templates/activity-uploads/example/data-request-example-template.xlsx',
  direct: 'templates/activity-uploads/example/data-request-example-template.xlsx',
  utility: 'templates/activity-uploads/example/data-request-example-template.xlsx',
}

export const ACTIVITY_UPLOAD_VALIDATION_FILES = {
  waste: 'templates/activity-uploads/example/data-request-example-validation.json',
  direct: 'templates/activity-uploads/example/data-request-example-validation.json',
  utility: 'templates/activity-uploads/example/data-request-example-validation.json',
}

export const AQ_ICONS = {
  dashboard: 'DashboardIcon',
  trolley: 'TrolleyIcon',
  layers: 'LayersIcon',
  people: 'PeopleIcon',
  target: 'TargetIcon',
  lineChart: 'LineChartIcon',
  customers: 'CustomersIcon',
  reports: 'ReportsIcon',
  sadface: 'SadFaceIcon',
  products: 'ProductsIcon',
  puzzle: 'puzzlePieceIcon',
  supplierProducts: 'SupplierProductsIcon',
  supplier: 'SupplierIcon',
  flag: 'FlagIcon',
  facilities: 'OfficeIcon',
}

/**
 * Onboarding
 */
export const DATASOURCE_ALLOWED_UPDATE_FREQUENCIES = [
  'yearly',
  'quarterly',
  'monthly',
  'fortnightly',
  'weekly',
  'variable',
]

/**
 * Types that are valid within the `type` field in the tag_templates table
 * If a new tag type is required for a new component, add it to this array
 * @type {Array}
 */
export const TAG_TEMPLATE_TYPES = ['data_source_file_issues']

/**
 * The following subActivityIds and calcMethodIds exist within the config.v_onboarding_activities view
 * But we don't want to show them in the frontend. These arrays list the relevant IDs that we don't want to show
 */
export const COMMUTING_SURVEY_SUB_ACTIVITY_CODE = 'CommutingSurvey'
export const COMMUTING_ACTIVITY_CODE = 'Commuting'
export const COMMUTING_ACTIVITY_NAME = 'Commuting and WFH Emissions'

export const DATA_SOURCE_EXCLUDED_SUB_ACTIVITY_CODES = [
  COMMUTING_SURVEY_SUB_ACTIVITY_CODE,
  'Processing',
  'UseOfSold',
  'PostConsumerWaste',
  'UseOfLeased',
  'Investments',
  'Franchises',
]

/**
 * Different activities will need to be associated to facilities or BUs in onboarding,
 * this constant defines that mapping
 */
export const BU_BASED_ACTIVITIES = [
  {
    activityId: 7,
    activityCode: 'PostConsumerWaste',
  },
  {
    activityId: 10,
    activityCode: 'Logistics',
  },
  {
    activityId: 11,
    activityCode: 'BusinessTravel',
  },
  {
    activityId: 13,
    activityCode: 'ProductInputs',
  },
  {
    activityId: 14,
    activityCode: 'Processing',
  },
  {
    activityId: 15,
    activityCode: 'UseOfSold',
  },
  {
    activityId: 16,
    activityCode: 'UseOfLeased',
  },
  {
    activityId: 17,
    activityCode: 'Investments',
  },
  {
    activityId: 18,
    activityCode: 'Franchises',
  },
  {
    activityId: 19,
    activityCode: 'CapitalGoods',
  },
  {
    activityId: 20,
    activityCode: 'IndirectPurchases',
  },
]

export const FOOTPRINTS_BU_BASED_ACTIVITIES = BU_BASED_ACTIVITIES.map(activity => activity.activityId)
export const FOOTPRINTS_BU_BASED_ACTIVITY_CODES = BU_BASED_ACTIVITIES.map(activity => activity.activityCode)

export const BUSINESS_UNIT_TYPES = [
  'Business unit',
  'Sub-business unit',
  'Brand',
  'Sub-brand',
  'Region',
  'Sub-region',
  'Country',
  'Division',
  'Function',
  'Department',
  'Subsidiary',
  'Grower',
]

export const LEGAL_ENTITY_TYPES = [
  {
    value: 'PARENT_COMPANY',
    label: 'Parent company',
  },
  {
    value: 'HOLDING_COMPANY',
    label: 'Holding company',
  },
  {
    value: 'SUBSIDIARY',
    label: 'Subsidiary',
  },
]
export const LEGAL_ENTITY_STATUS = [
  {
    value: 'true',
    label: 'Legally active',
  },
  {
    value: 'false',
    label: 'Inactive',
  },
]
export const LEGAL_ENTITY_MIN_NAME_LENGTH = 2
export const DATA_SOURCE_FILE_STATUSES = {
  file_validation_pending: 'in validation',
  file_validation_success: 'approved',
  file_validation_failed: 'errors',
  submitted: 'submitted',
  in_progress: 'in progress',
}

export const MAX_PERMITTED_ORGANIZATION_STRUCTURE_LEVELS = 5
export const TOP_TIER_ORGANIZATION_LEVEL = 1
export const LEGAL_ENTITY_CURRENCY_OPTIONS = [
  {
    value: 'GBP',
    label: '£ Pounds',
  },
  {
    value: 'USD',
    label: '$ Dollars',
  },
  {
    value: 'EUR',
    label: 'Euro €',
  },
]

export const FACILITY_TABLE_HEADERS = [
  { name: 'Name' },
  { name: 'Postcode' },
  { name: 'Type' },
  { name: 'Ownership' },
  { name: 'Active period' },
  { name: 'Capacity' },
  { name: '' },
]

export const OWNERSHIP_TYPES = {
  OWNED_OR_LEASED_SOLE_OCCUPANT: 'Owned or leased building - business unit is sole occupant',
  OWNED_OR_LEASED_SHARED_OCCUPANT: 'Owned or leased building - shared with other business units',
  OWNED_WITH_SUBTENANTS: 'Owned - some space rented to subtenants',
  PARTIALLY_LEASED: 'Leased building shared with others/ co-working space',
}

export const FACILITIES_TABLE_PAGE_SIZE = 200

export const FACILITY_TYPE_ICONS = {
  office: 'chair-office',
  factory: 'industry',
  distributionCentre: 'truck-fast',
  warehouse: 'boxes',
  farm: 'tractor',
  retailLocation: 'cart-shopping',
  dataCentre: 'server',
}

export const DEFAULT_CAPACITY_UNIT = 'm2'

export const LIST_OF_REGIONS = [
  'Africa',
  'Americas',
  'Asia',
  'Europe',
  'Oceania',
  'Australia and New Zealand',
  'Central Asia',
  'Eastern Asia',
  'Eastern Europe',
  'Latin America and the Caribbean',
  'Melanesia',
  'Micronesia',
  'Northern Africa',
  'Northern America',
  'Northern Europe',
  'Polynesia',
  'South-eastern Asia',
  'Southern Asia',
  'Southern Europe',
  'Sub-Saharan Africa',
  'Western Asia',
  'Western Europe',
]
