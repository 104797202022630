import type { BusinessUnitResponseStructure } from '@/imports/@types/organizationStructure/v2/OrganizationStructure'

export const findActiveOrgInBusinessUnitStructure = (
  structure: BusinessUnitResponseStructure,
  id: string,
): BusinessUnitResponseStructure | null => {
  if (structure.orgId === id) {
    return structure
  }

  for (const child of structure.children) {
    const found = findActiveOrgInBusinessUnitStructure(child, id)
    if (found !== null) {
      return found
    }
  }

  return null
}
