import type { AxiosInstance } from 'axios'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'
import type { Message } from '@/imports/@types/Message'

export const useMessagesApi = (http: AxiosInstance) => ({
  getForOrganization(payload: { orgId: string; relatedRecordId: string; relatedRecordType: string; scope?: string }) {
    return http.get<ApiResponseInner<{ rows: Message[] }>>('/message/getfororganization', {
      params: payload,
    })
  },
})
