import { ValidateDTO } from '..'

import { INITIATIVE_CONTROLLER } from './controller.common-vars'

export const INPUT_TYPE_LIST_SUBPATH = 'input-types'
export const INPUT_TYPE_LIST_URI = `${INITIATIVE_CONTROLLER}/${INPUT_TYPE_LIST_SUBPATH}`

export class InitiativesInputTypesDTOReq extends ValidateDTO {}

export type InitiativesInputTypesDTORes = {
  inputTypes: string[]
}
