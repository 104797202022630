// /* eslint-disable camelcase */
// import Vue from 'vue'; t
import { uniq } from 'lodash-es'
import { defineRule } from 'vee-validate'
import { required, confirmed, length, email, min_value, min, numeric, max_value } from '@vee-validate/rules'

// install the 'required' rule.
defineRule('required', required)

defineRule('email', email)

defineRule('passwordMatch', confirmed)

defineRule('length', length)

defineRule('numeric', numeric)

defineRule('numericRange', value => {
  const allowedTypes = ['number', 'undefined']

  // loose check for null here as 0 is a permissable value but null/undefined
  // should be classed as empty
  if (!allowedTypes.includes(typeof value[0]) || !allowedTypes.includes(typeof value[1]) || value[0] > value[1]) {
    return 'Range is invalid'
  }

  return true
})

// defineRule('min', min)
defineRule('minLength', (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true
  }
  if (value.length < limit) {
    return `This field must be at least ${limit} characters`
  }
  return true
})

defineRule('maxLength', (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true
  }
  if (value.length > limit) {
    return `This field must be less than ${limit} characters`
  }
  return true
})

defineRule('password', min)

defineRule('min_value', min_value)

defineRule('max_value', max_value)

// defineRule('dateRange', {
//     validate: (value, args) => true,
//     getMessage: (field, values) => 'works!',
// })

defineRule('dateRange', () => {})

defineRule('financialYear', value => {
  const financialYearRegex = /^\d{1,2}-\d{1,2}$/

  if (!financialYearRegex.test(value)) {
    return 'Financial year format is not valid'
  }

  return true
})

defineRule('emailsList', value => {
  if (value) {
    const emails = value.split('\n')
    const uniqueEmails = uniq(emails)
    const filteredEmails = uniqueEmails.filter(e => e !== '')

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!filteredEmails.every(e => emailRegex.test(e))) {
      return 'One or more email addresses are not valid'
    }
    return true
  }
})
