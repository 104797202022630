// [POST] /initiatives

import { ArrayMinSize, IsArray, IsDateString, IsString } from 'class-validator'

import { ValidateDTO } from '..'

import { INITIATIVE_CONTROLLER } from './controller.common-vars'

export const INITIATIVE_CREATE_SUBPATH = ''
export const INITIATIVE_CREATE_URI = `${INITIATIVE_CONTROLLER}/${INITIATIVE_CREATE_SUBPATH}`

export class InitiativesCreateDTOReq extends ValidateDTO {
  @IsString()
  name!: string

  @IsArray()
  @ArrayMinSize(1)
  outgoingInputIds!: string[] // input UUIDs / identifiers

  @IsDateString()
  startDate!: string

  @IsDateString()
  endDate!: string
}

export type InitiativesCreateDTORes = {
  initiativeId: string
}
