import { ValidateDTO } from '..'
import { SORT_ORDER } from '../../../@enums/common.enums'
import type { PaginationMeta } from '../generic/pagination-meta.dto'

import { INITIATIVE_CONTROLLER } from './controller.common-vars'

export const INITIATIVE_LIST_SUBPATH = ''
export const INITIATIVE_LIST_URI = `${INITIATIVE_CONTROLLER}/${INITIATIVE_LIST_SUBPATH}`

export enum INITIATIVE_LIST_SORT_FIELDS {
  NAME = 'name',
  EMISSIONS_CHANGE_PER_KG = 'emissionsChangePerKGFactor',
  EMISSIONS_CHANGE_PER_YEAR = 'emissionsChangeVsLastYearFactor',
  UPDATED_AT = 'updatedAt',
}

export class InitiativesListDTOReq extends ValidateDTO {
  searchTerm?: string

  perPage?: number

  pageNo?: number

  sortOrder?: SORT_ORDER

  sortBy?: INITIATIVE_LIST_SORT_FIELDS
}

export type InitiativeMeta = Omit<PaginationMeta, 'total'> & {
  sortBy?: INITIATIVE_LIST_SORT_FIELDS
  searchTerm?: string
}

export type InitiativeListItem = {
  id: string
  name: string
  businessUnit: string
  emissionsChangePerKGFactor: number
  emissionsChangeVsLastYearFactor: number
  updatedAt: string
}

export type InitiativesListDTORes = {
  initiatives: InitiativeListItem[]
  meta: PaginationMeta & {
    sortBy: INITIATIVE_LIST_SORT_FIELDS
  }
}
