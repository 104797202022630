import { getDefaultNav } from './FullAndSelfServe'
import { getSupplierNav } from './Suppliers'
import { getInternalUserNav } from './InternalUsers'

import type { NavGroup, GetNavListParams } from '@/imports/@types/Navigation'

export const getNavList = ({ orgType, isInternalUser }: GetNavListParams): NavGroup[] => {
  const initialList: NavGroup[] = isInternalUser ? getInternalUserNav() : []

  if (['supplier'].includes(orgType)) {
    return getSupplierNav().concat(initialList)
  }

  return getDefaultNav().concat(initialList)
}
