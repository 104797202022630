import type { AxiosInstance } from 'axios'

import { useOrganizationStore } from '@/client/store/organization.pinia'

import type {
  TBasicCalculatorCreateFootprintPayload,
  TBasicCalculatorCachePayload,
  TBasicCalculatorCacheResponse,
  TUpdateFacilityDTO,
} from '@/imports/@types/basicCalculator/BasicCalculator'

import { USED_FACILITIES_SUB_PATH } from '@/imports/lib/DTO/basic-calculations/used-facilities.dto'

const CALCULATIONS_BASE_PATH = 'basic-calculations'

export const useBasicCalculatorApi = (http: AxiosInstance) => ({
  createBasicCalculatorWizard(year: number, payload: TBasicCalculatorCachePayload) {
    const orgStore = useOrganizationStore()

    if (!orgStore.activeOrganization) throw new Error('Error: missing active organisation')

    return http.post<TBasicCalculatorCacheResponse>(
      `/${CALCULATIONS_BASE_PATH}/cache`,
      { year, ...payload },
      {
        headers: {
          'x-org-id': orgStore.activeOrganization.id,
        },
      },
    )
  },

  getBasicCalculatorWizard(wizardId: string) {
    const orgStore = useOrganizationStore()

    if (!orgStore.activeOrganization) throw new Error('Error: missing active organisation')

    return http.get<TBasicCalculatorCacheResponse>(`/${CALCULATIONS_BASE_PATH}/cache/${wizardId}`, {
      headers: {
        'x-org-id': orgStore.activeOrganization.id,
      },
    })
  },

  updateBasicCalculatorWizard(wizardId: string, state: TBasicCalculatorCachePayload) {
    const orgStore = useOrganizationStore()

    if (!orgStore.activeOrganization) throw new Error('Error: missing active organisation')

    return http.put(`/${CALCULATIONS_BASE_PATH}/cache/${wizardId}`, state, {
      headers: {
        'x-org-id': orgStore.activeOrganization.id,
      },
    })
  },

  submitBasicCalculatorWizard(payload: TBasicCalculatorCreateFootprintPayload) {
    const orgStore = useOrganizationStore()

    if (!orgStore.activeOrganization) throw new Error('Error: missing active organisation')

    return http.post(`/${CALCULATIONS_BASE_PATH}`, payload, {
      headers: {
        'x-org-id': orgStore.activeOrganization.id,
      },
    })
  },

  getUsedFacilities() {
    const orgStore = useOrganizationStore()

    if (!orgStore.activeOrganization) throw new Error('Error: missing active organisation')

    return http.get<{ facilities: string[] }>(`${CALCULATIONS_BASE_PATH}/${USED_FACILITIES_SUB_PATH}`, {
      headers: {
        'x-org-id': orgStore.activeOrganization.id,
      },
    })
  },

  updateFacility(facilityId: string, payload: TUpdateFacilityDTO) {
    const orgStore = useOrganizationStore()

    if (!orgStore.activeOrganization) throw new Error('Error: missing active organisation')

    return http.patch<{ success: boolean }>(`${CALCULATIONS_BASE_PATH}/facilities/${facilityId}`, payload, {
      headers: {
        'x-org-id': orgStore.id || '',
      },
    })
  },
})
