/**
 * Note this is for client-side only!
 */
import { useUserStore } from '@/client/store/user.pinia'
import { useOrganizationStore } from '@/client/store/organization.pinia'

import { env } from '@/client/helpers'

import { ROLES_KEYS_MAP, ROLE_KEYS } from '@/imports/lib/constants/permission/permissionConstants'
import { CONTRACT_SCOPES_CLIENT_TYPES } from '@/imports/lib/constants/constants'

export const getAnalyticsProperties = () => {
  const orgStore = useOrganizationStore()
  const userStore = useUserStore()

  const { activeOrganization, rootOrg } = orgStore

  return {
    orgName: activeOrganization?.name,
    orgId: activeOrganization?.id,
    rootOrgName: rootOrg?.name,
    rootOrgId: rootOrg?.id,
    rootOrgType: rootOrg?.configuration.internalType,
    rootOrgSubscription: CONTRACT_SCOPES_CLIENT_TYPES[rootOrg?.configuration.clientType],
    role: userStore.isSuperUser
      ? ROLES_KEYS_MAP[ROLE_KEYS.SUPER_USER]
      : ROLES_KEYS_MAP[userStore.user.roleAssignment.role],
    env: env(),
  }
}
