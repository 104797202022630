import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { INTERCOM_CONTROLLER } from './controller.common-vars'

export const GET_DATASET_DELETION_TICKET_SUBPATH = 'ticket/dataset/deletion/:orgId/:datasetId'
export const GET_DATASET_DELETION_TICKET_URI = `${INTERCOM_CONTROLLER}/${GET_DATASET_DELETION_TICKET_SUBPATH}`

export class getDataSetDeletionTicketDTOReq extends ValidateDTO {
  @IsUUID()
  orgId!: string

  @IsUUID()
  datasetId!: string
}

export type getDataSetDeletionTicketDTORes = {
  ticketId: number | undefined
}
