import moment from 'moment'

import { convertEmissionUnits } from '@lib/utilities/convertEmissionUnits'
import { getLocaleNumber } from '@lib/utilities/getLocaleNumber.helper'

/**
 * Vue Mixin to help conversion of units
 */
const ConversionHelpersMixin = {
  methods: {
    /**
     * Converts date to new date with date parts
     * @param { string } date         - Date to convert
     * @param { boolean } withSeconds - Include seconds in new date
     *
     * @returns { string }            - A human readable date with hours, minutes, and optionally seconds
     */
    mixin_humanDate(date: string, { withSeconds = false } = {}) {
      if (!date) return

      const dateFormat = withSeconds ? 'L HH:mm:ss' : 'L HH:mm'

      return moment(date).format(dateFormat)
    },

    /**
     * Returns cents expressed as the cent part of a number e.g. .10
     * @param { number } cents  - Value to convert
     *
     * @returns { string }      - Cents returned as the decimal part of a value e.g. 10 -> .10
     */
    mixin_localCurrency(cents: number) {
      const euro = Math.round((cents + Number.EPSILON) * 100) / 100 / 100
      if (euro === 0) return '0,-'
      return euro.toString().replace('.', ',')
    },

    /**
     * Capitalises first character in a string
     * @param { string } str  - String to capitalise
     *
     * @returns { string }    - Capitalised string e.g 'the big lebowski' -> 'The big lebowski'
     */
    mixin_capitalize(str: string) {
      return `${str?.charAt(0).toUpperCase()}${str?.slice(1)}`
    },

    /**
     * Formats a number based on the browser locale
     * @param { number } number         - The number to format
     * @param { number } decimalPlaces  - The number of decimal places
     * @param { boolean } forceDecimals - Will ensure that decimal places are still shown on round numbers
     * @param isEstimated               - Will append double tilde to value if true
     *
     * @returns { string }              - To the number of decimal places requested
     *
     * Unit tests: https://codesandbox.io/s/accuracy-scores-k4kuqf?file=/src/mixins/conversionHelpers.test.js
     *
     */
    mixin_localeNumber: getLocaleNumber,

    /**
     * Formats valid emissions as a localised number
     * @param { number } emissions  - The emissions to format

     * @returns { string }          - Formatted emissions to localised string value
     *
     * Unit tests: https://codesandbox.io/s/accuracy-scores-k4kuqf?file=/src/mixins/conversionHelpers.test.js
     *
     */
    mixin_emission(emission: number) {
      if (!emission) return 0

      return this.mixin_localeNumber(emission / 1000)
    },

    /**
     * Returns an emissions percentage value to 1 decimal place
     * @param { number } emission - emissions value
     *
     * @returns { number }        - emissions as a percentage to 1 decimal point
     */
    mixin_emission_perc(emission: number) {
      return parseFloat((emission || 0).toFixed(1))
      // New ticket: https://app.shortcut.com/altruistiq/story/5619/update-mixin-emission-perc-function-to-return-number-to-fixed-decimal-place?vc_group_by=day&ct_workflow=all&cf_workflow=500000019
      // Why? Because the current code does not return a fixed decimal place
      // Why is this not fixed?
      // This change needs to be checked against all uses because the updated code returns a string instead of a number
    },

    /**
     * Returns a string indicating percentage value, and indication if it exceeds 100%
     * @param { number } perc - Percentage value
     *
     * @returns { string }    - Percentage value or indication of range e.g. >100%
     */
    mixin_format_long_percentages(perc: number) {
      if (perc > 100 || perc < -100) return '>100 %'

      return `${perc} %`
    },

    /**
     * Convert an emission value from KG to g or tonne.
     * @param { number } emission               - Emission value in KG
     * @param { number } decimalPlaces          - Decimal places required
     * @param { boolean } forceDecimals         - Will ensure that decimal places are still shown on round numbers
     * @param { boolean } isEstimated           - Will append double tilde to value if true
     * @param { boolean } formatIntensityNumber - Will format the intensity value using mixin_localeNumber
     *
     * @param { number | null } kgDecimalPlaces - Allows you to format KGs individually. decimalPlaces is used by default if this is not provided
     *
     * Moved to convertEmissionUnits utility method
     */
    mixin_emission_units: convertEmissionUnits,

    /**
     *
     * @param { number } emissions    - In kilogrammes
     * @param { boolean } isEstimated - Is this value estimated
     *
     * @returns { string }            - The emissions value for display in metric tonnes
     *
     * Unit tests: https://codesandbox.io/s/altruistiq-unit-tests-qb4yrc?file=/src/mixins/mixin_emissions_display.test.js
     *
     */
    mixin_emissions_display(emissions: number, isEstimated: boolean) {
      if (emissions === null) {
        return '-'
      }
      if (emissions === 0) {
        return '0.0'
      }
      if (emissions < 100) {
        // emissions are provided in kilogrammes, but the display is in tonnes (0.1 * 1000 = 100)
        return '<0.1'
      }

      return this.mixin_localeNumber(emissions / 1000, { decimalPlaces: 1, isEstimated, forceDecimals: true })
    },

    /**
     *
     * @param { number } percentageOfTotal  - Percentage of emissions
     *
     * @returns { string }                  - Percentage with special provision for small values
     *
     *  Unit tests: https://codesandbox.io/s/altruistiq-unit-tests-qb4yrc?file=/src/mixins/mixin_percentage_of_total_display.test.js
     */
    mixin_percentage_of_total_display(percentageOfTotal: number) {
      if (percentageOfTotal === null) {
        return ''
      }
      if (percentageOfTotal < 0.1 && percentageOfTotal > 0) {
        return '<0.1'
      }

      return (percentageOfTotal || 0).toFixed(1)
    },

    /**
     *
     * @param { number } percentageOfTotal  - Percentage of emissions
     *
     * @returns { number }                  - Percentage of progress made
     */
    mixin_percentage_of_total_progress_display(percentageOfTotal: number) {
      return percentageOfTotal === null ? 0 : percentageOfTotal
    },
  },
}

export default ConversionHelpersMixin
