<template>
  <div id="impersonating-layout">
    <SideNav />
    <NavBar />
    <div
      class="page-content has-sidebar"
      data-scroll="page-content"
      :class="[{ impersonating: isImpersonating }]"
    >
      <BetaBanner v-if="showBetaHeader" />

      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'

  import SideNav from '@components/SideNav/SideNav.vue'

  import NavBar from '@components/NavBar/NavBar.vue'

  import BetaBanner from '@reinga/BetaBanner/BetaBanner.vue'

  import { useUserStore } from '@/client/store/user.pinia'

  const isImpersonating = computed(() => useUserStore().isImpersonating)

  const props = defineProps<{
    showBetaHeader: boolean
  }>()

  const showBetaHeader = computed(() => props.showBetaHeader || false)
</script>

<style lang="scss" scoped>
  .page-content {
    position: absolute;
    top: $topbar-height;
    bottom: 0;
    right: 0;
    left: $sidebar-width;
    overflow-y: hidden;
    padding: 0;
    overflow-x: hidden;

    &.has-sidebar.has-mobile-state {
      @media only screen and (max-width: 1440px) {
        left: 93px;
      }

      @media only screen and (min-width: 1441px) {
        left: $sidebar-width;
      }
    }

    // rollout-navigation-v2
    &.has-sidebar:not(.has-mobile-state) {
      left: $sidebar-width-v2;

      @media (max-width: 850px) {
        padding: 0;
      }
    }

    &.impersonating {
      border: 3px solid red;
    }
  }
</style>
