// Get static dashboard URI

import { ValidateDTO } from '..'

import { ANALYTICS_CONTROLLER, type AnalyticsIframeFormatting } from './controller.common-vars'

export const ANALYTICS_PERSONAL_COLLECTION_SUBPATH = 'collections/personal'
export const ANALYTICS_PERSONAL_COLLECTION_DASHBOARD_URI = `${ANALYTICS_CONTROLLER}/${ANALYTICS_PERSONAL_COLLECTION_SUBPATH}`

export class AnalyticsPersonalCollectionDashboardDTOReq extends ValidateDTO {
  formatting?: AnalyticsIframeFormatting
}

export type AnalyticsPersonalCollectionDashboardDTORes = {
  uri: string
}
