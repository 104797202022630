// Get shared collection dashboard URI

import { ValidateDTO } from '..'

import { ANALYTICS_CONTROLLER, type AnalyticsIframeFormatting } from './controller.common-vars'

export const ANALYTICS_SHARED_COLLECTION_SUBPATH = 'collections/shared'
export const ANALYTICS_SHARED_COLLECTION_DASHBOARD_URI = `${ANALYTICS_CONTROLLER}/${ANALYTICS_SHARED_COLLECTION_SUBPATH}`

export class AnalyticsSharedCollectionDashboardDTOReq extends ValidateDTO {
  formatting?: AnalyticsIframeFormatting
}

export type AnalyticsSharedCollectionDashboardDTORes = {
  uri: string
}
