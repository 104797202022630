import { IsNumber, IsOptional } from 'class-validator'

import type { IFranchisor } from '../../../@types/franchises.types'

import { FRANCHISORS_CONTROLLER } from './franchisors.common-vars'

export class GetFranchisorsDTO {
  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number
}

export const GET_FRANCHISORS_SUB_PATH = ''
export const GET_FRANCHISORS_PATH = `${FRANCHISORS_CONTROLLER}/${GET_FRANCHISORS_SUB_PATH}`

export type GetFranchisorsDtoRes = {
  franchisors: IFranchisor[]
  meta: {
    totalCount: number
    pageNo: number
    perPage: number
  }
}
