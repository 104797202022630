export const DATASOURCE_CONTROLLER = 'dataSource'

export type DataSource = {
  id: string
  name: string
  description: string
  status: string
  updateFrequency: string
  // eslint-disable-next-line
  calculationMethods: any
  // eslint-disable-next-line
  assumptions: any
  asanaTaskId: string
  labelStudioProjectId: string
  type: string
  startDate: string
  endDate: string
  lookupName: string
  targetModule: string
  isCustomCalculation?: boolean
  isDeletionAllowed: boolean
}

export type DataContract = {
  id: string
  dataContractUrl: string
  dataContractName: string
  // eslint-disable-next-line
  status: any
  orgId: string
  uploadedBy: string
  dataSourceId: string
}
