import { round, range } from 'lodash-es'

import { showToast } from '@lib/plugins/Toastie'

import { SCOPE3_GHG_CATEGORIES } from '@lib/constants/constants'

import { useReportsStore } from '@/client/store/reports.pinia'

import {
  EMISSIONS_TARGETS_REDUCTION_TYPES,
  EMISSIONS_TARGETS_CONTRACTION_TYPES,
} from '@/imports/@enums/emissions-targets.enums'

export const calculateTargetReductionValues = (
  years: number[],
  reduction: number,
  reductionType: EMISSIONS_TARGETS_REDUCTION_TYPES,
  totalEmissions: number,
) => {
  if (reductionType === EMISSIONS_TARGETS_REDUCTION_TYPES.COMPOUND) {
    return calculateCompoundTargetReductionValues(years, reduction, totalEmissions)
  }

  const annualDecrease = (totalEmissions * reduction) / (years.length - 1)

  return years.map((_, i) => round(totalEmissions - i * annualDecrease, 3))
}

export const calculateCompoundTargetReductionValues = (
  years: number[],
  targetReduction: number,
  totalEmissions: number,
) => {
  const annualReductionRate = 1 - (1 - (targetReduction === 1 ? 0.999 : targetReduction)) ** (1 / (years.length - 1))
  let remainingEmissions = totalEmissions // Start with the initial total emissions

  const emissionsValues = years.map(_ => {
    remainingEmissions *= 1 - annualReductionRate // Apply the reduction for this year

    return remainingEmissions
  })

  return [totalEmissions, ...emissionsValues]
}

export const getAllYearsOfTarget = (startYear: number, endYear: number) => range(startYear, endYear + 1)

export function calculateIntensityEmission(
  emission: number,
  contractionType: EMISSIONS_TARGETS_CONTRACTION_TYPES,
  intensityMetricId: string,
  startYear: number,
  intensityMetrics: ReturnType<typeof useReportsStore>['intensityMetrics'],
) {
  if (contractionType !== EMISSIONS_TARGETS_CONTRACTION_TYPES.INTENSITY || !intensityMetricId || !startYear) {
    return emission
  }

  const intensityMetricData = intensityMetrics.find(
    metric => metric.id === intensityMetricId && metric.values[startYear],
  )

  if (!intensityMetricData) {
    showToast({
      type: 'is-danger',
      message: `There was a problem retrieving your chosen intensity metric's data for ${startYear}. Please check that ${startYear} is populated for your chose metric.`,
    })

    return emission
  }

  /**
   * This type assertion is needed due to TS limitations around discerning that the existence of the
   * property has already been checked on line 434
   */
  const intensityValue = intensityMetricData.values[+startYear] as number

  return emission / intensityValue
}

export const getTotalEmissionsForStartYear = (
  selectedScopes: number[],
  startYearReport: ReturnType<typeof useReportsStore>['reports'][0],
  scope3Categories: string[],
) => {
  return selectedScopes.reduce((acc, scopeIndex) => {
    if (scopeIndex === 3) {
      const scope3Emissions =
        startYearReport.data.ghg.data[2].emissionDrivers.reduce<number>((scope3EmissionsTotal, scope3Category) => {
          const selectedCategoryNames = scope3Categories.map(categoryId => SCOPE3_GHG_CATEGORIES[categoryId])

          if (selectedCategoryNames.includes(scope3Category.categoryName)) {
            scope3EmissionsTotal += scope3Category.emission
          }

          return scope3EmissionsTotal
        }, 0) || 0

      return acc + scope3Emissions
    }

    const reportEmissions = startYearReport.data.ghg.emissionsPerScope[scopeIndex - 1]

    const emissions = reportEmissions || 0

    return acc + emissions
  }, 0)
}
