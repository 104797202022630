import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

import { env } from '@/client/helpers'

const SENTRY_ENVS: { [key: string]: string } = {
  dev: 'dev.seamink.dev',
  staging: 'staging',
  production: 'production',
}

const gitSHA = () => import.meta.env.VITE_GIT_SHA

// Only init Sentry if a Git SHA is set (via CI)
// In other words, disabled in development
if (gitSHA()) {
  Sentry.init({
    dsn: 'https://526b2b97f3f441c79851a726edc0e86b@o889347.ingest.sentry.io/5838678',
    release: gitSHA(), // Git SHA set in GH action
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: SENTRY_ENVS[env()],
    // debug: true,
  })
}
