<template>
  <div
    ref="$root"
    class="accordion"
    :style="styles"
    :class="{ opened: open && opened }"
    :data-state="open ? 'open' : ''"
    data-ci="accordion"
    @transitionend="toggleOpenedState"
  >
    <div
      ref="$inner"
      class="accordion__inner"
      :class="{ 'full-width': fullWidth }"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, computed, onMounted, nextTick } from 'vue'
  import { useResizeObserver } from '@vueuse/core'

  type Props = {
    open?: boolean
    direction?: 'vertical' | 'horizontal'
    fullWidth?: boolean
    duration?: number
  }

  const props = withDefaults(defineProps<Props>(), {
    direction: 'vertical',
    duration: 0.15,
  })

  const emit = defineEmits<{
    (e: 'opened'): void
  }>()

  const height = ref(0)
  const width = ref(0)
  const opened = ref(props.open)
  const $inner = ref<HTMLElement | null>(null)
  const $root = ref<HTMLElement | null>(null)

  const currentHeight = computed(() => {
    return props.open ? `${height.value}px` : '0px'
  })

  const currentWidth = computed(() => {
    return props.open ? `${width.value}px` : '0px'
  })

  const styles = computed(() => {
    const styles: { [key: string]: string | undefined } = {}

    if (props.direction === 'vertical') {
      styles['max-height'] = currentHeight.value
    }

    if (props.direction === 'horizontal') {
      styles['max-width'] = currentWidth.value
    }

    return styles
  })

  const transitionDuration = computed(() => {
    return `${props.duration}s`
  })

  const getDimensions = () => {
    updateHeight(getInnerHeight())
    updateWidth(getInnerWidth())
  }

  const getInnerHeight = () => {
    if (!($inner.value instanceof HTMLElement)) {
      return 0
    }

    return $inner.value.offsetHeight
  }

  const getInnerWidth = () => {
    if (!($inner.value instanceof HTMLElement)) {
      return 0
    }

    return $inner.value.offsetWidth
  }

  const updateHeight = (_height: number) => {
    if (typeof _height === 'number') {
      height.value = _height
    }
  }

  const updateWidth = (_width: number) => {
    if (typeof _width === 'number') {
      width.value = _width
    }
  }

  const toggleOpenedState = (e: TransitionEvent) => {
    if (e.target !== $root.value) return

    opened.value = !opened.value

    if (opened.value) {
      emit('opened')
    }
  }

  onMounted(async () => {
    getDimensions()

    await nextTick()

    useResizeObserver($inner, getDimensions)
  })
</script>

<style lang="scss" scoped>
  $module: 'accordion';

  .#{$module} {
    overflow: hidden;

    &:not(.opened) {
      transition:
        max-height v-bind(transitionDuration) linear,
        max-width v-bind(transitionDuration) linear;
    }

    &.opened {
      overflow: visible;
    }

    &__inner {
      display: inline-block;

      &.full-width {
        display: block;
      }
    }
  }
</style>
