import { IsUUID } from 'class-validator'

import { DATASET_DELETE_ACTIONS } from '../dataset/dataset-common.dto'

import { ValidateDTO } from '..'

import { FILE_UPLOAD_CONTROLLER } from './controller.common-vars'

export const FILE_UPLOAD_DELETE_SUBPATH = 'dataset/:fileUploadId'
export const FILE_UPLOAD_DELETE_URI = `${FILE_UPLOAD_CONTROLLER}/${FILE_UPLOAD_DELETE_SUBPATH}`

export class FileUploadDeleteDTOReq extends ValidateDTO {
  @IsUUID()
  fileUploadId!: string
}

type FileUpload = {
  id: string
  fileName: string
  metaData: { dataSourceId: string }
  S3Url: string
  status: string
  validationErrors: unknown
  tags: unknown
  system: string
  comments: string
}

export type FileUploadDeleteDTORes = {
  file: FileUpload
  fileId: string
  status: DATASET_DELETE_ACTIONS
}
