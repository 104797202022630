import { IsString, IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { BASIC_FACILITIES_CONTROLLER } from './controller.common-vars'

export const BASIC_FACILITY_UPDATE_SUBPATH = ':facilityId'
export const BASIC_FACILITY_UPDATE_URI = `${BASIC_FACILITIES_CONTROLLER}/${BASIC_FACILITY_UPDATE_SUBPATH}`

export class BasicFacilityUpdateDTOReq extends ValidateDTO {
  @IsUUID()
  facilityId: string

  @IsString()
  facilityName: string

  @IsString()
  postalCode: string

  @IsString()
  country: string
}

export type BasicFacilityUpdateDTORes = {
  success: boolean
}
