import { getLocaleNumber } from '@lib/utilities/getLocaleNumber.helper'

type ConvertEmissionsUnitOptions = {
  decimalPlaces?: number
  forceDecimals?: boolean
  isEstimated?: boolean
  formatIntensityNumber?: boolean
  kgDecimalPlaces?: number | null
  approximateZeroValues?: boolean
  lowerBound?: number
  upperBound?: number
}

export const convertEmissionUnits = (
  emission: number,
  {
    decimalPlaces = 1,
    forceDecimals = false,
    isEstimated = false,
    formatIntensityNumber = true,
    kgDecimalPlaces = null,
    approximateZeroValues = false,
    lowerBound,
    upperBound,
  }: ConvertEmissionsUnitOptions = {},
) => {
  const intensityInGrams = emission * 1000
  const grTonneFactor = 1000000 // 1 mln gr === 1t
  const maxGrams = 100 // 0.1kg
  const maxKgGrams = 100000 // 0.1t

  // show gram up till 0.1KG (maxGrams)
  if (intensityInGrams <= maxGrams) {
    return {
      intensity: formatIntensityNumber
        ? getLocaleNumber(intensityInGrams, {
            decimalPlaces,
            forceDecimals,
            isEstimated,
            approximateZeroValues,
            lowerBound,
            upperBound,
          })
        : intensityInGrams,
      shortUnit: 'g',
      descriptiveUnit: 'grams',
      unit: 'gCO2e',
      htmlunit: 'gCO<sub>2</sub>e',
    }
  }

  // show kg up till 0.1MT (maxKgGrams)
  if (intensityInGrams > maxGrams && intensityInGrams <= maxKgGrams) {
    return {
      intensity: formatIntensityNumber
        ? getLocaleNumber(intensityInGrams / 1000, {
            decimalPlaces: kgDecimalPlaces ?? decimalPlaces,
            forceDecimals,
            isEstimated,
            approximateZeroValues,
            lowerBound,
            upperBound,
          })
        : intensityInGrams / 1000,
      shortUnit: 'kg',
      descriptiveUnit: 'kilograms',
      unit: 'kgCO2e',
      htmlunit: 'kgCO<sub>2</sub>e',
    }
  }

  // show MT
  return {
    intensity: formatIntensityNumber
      ? getLocaleNumber(intensityInGrams / grTonneFactor, {
          decimalPlaces,
          forceDecimals,
          isEstimated,
          approximateZeroValues,
          lowerBound,
          upperBound,
        })
      : intensityInGrams / grTonneFactor,
    shortUnit: 't',
    descriptiveUnit: 'metric tons',
    unit: 'tCO2e',
    htmlunit: 'tCO<sub>2</sub>e',
  }
}

export const convertKgToTonnes = (kgWeight: number) => kgWeight / 1000
