<template>
  <div class="bu-dropdown-action">
    <div class="bu-dropdown-action__currentuser-role mr-2">
      <Tag
        :state="getRoleToTagColor(getCurrentUserRole(props.org))"
        isRounded
      >
        <template v-slot:content>
          {{ getHumanReadeableRole(getCurrentUserRole(props.org)) }}
        </template>
      </Tag>
    </div>

    <div class="bu-dropdown-action__btn-template-width">
      <AqButton
        v-if="getCurrentUserRole(props.org) !== ROLE_KEYS.NO_ACCESS"
        label="View"
        variant="secondary"
        size="small"
        class="bu-dropdown-action__view-button"
        @click="selectItem(props.org.orgId)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import Tag from '@components/designsystem/Tag/Tag.vue'

  import AqButton from '@components/designsystem/AqButton/AqButton.vue'

  import { getHumanReadeableRole, getRoleToTagColor } from '@/imports/lib/utilities/roleHelpers'

  import { useUserStore } from '@/client/store/user.pinia'

  import { ROLE_KEYS } from '@/imports/lib/constants/permission/permissionConstants'

  import type { BusinessUnitResponseStructure } from '@/imports/@types/organizationStructure/v2/OrganizationStructure'

  type Props = {
    org: BusinessUnitResponseStructure
  }

  const props = defineProps<Props>()

  const userStore = useUserStore()

  const getCurrentUserRole = (bu: BusinessUnitResponseStructure): ROLE_KEYS => {
    const currentUserRole = bu.users.find(u => u.email === userStore.user.email)

    if (!currentUserRole && userStore.user.isSuperUser) return ROLE_KEYS.SUPER_USER
    return (currentUserRole?.role || ROLE_KEYS.NO_ACCESS) as ROLE_KEYS
  }

  // Emits the selected item id
  const emit = defineEmits<{ (event: 'onSelected', itemId: string): void }>()

  const selectItem = (orgId: string) => {
    emit('onSelected', orgId)
  }
</script>

<style scoped lang="scss">
  $module: 'bu-dropdown-action';

  .#{$module} {
    display: flex;

    &__currentuser-role {
      align-items: center;
      display: flex;
    }

    &__view-button {
      margin-left: auto;
      color: $grey-1;
      flex-shrink: 0;
      width: 100%;
    }

    &__btn-template-width {
      width: 70px;
    }
  }
</style>
