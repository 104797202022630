import { defineStore } from 'pinia'
import moment from 'moment/min/moment-with-locales'
import { orderBy } from 'lodash-es'

import { showToast } from '@lib/plugins/Toastie'
import { getApiErrorMessage } from '@lib/utilities/getApiErrorMessage'

import { getFirstUnlockedDate } from '@lib/utilities/orgStore/orgStore.utils'

import { useUserStore } from './user.pinia'
import { useReportsStore } from './reports.pinia'
import { useLegalEntitiesStore } from './legalEntities/legalEntities.pinia'

import { Api } from '@/imports/lib/services/api.service'
import { CURRENCY_MAPPING } from '@/imports/lib/constants/constants'
import { TimePeriods } from '@/imports/@types/TimePeriods'
import FeatureFlagService from '@/imports/lib/services/featureFlagService'
import { ROLE_KEYS } from '@/imports/lib/constants/permission/permissionConstants'
import { AUTH_MODES } from '@/imports/@types/AuthModes'
import { USER_ORG_RELATION } from '@/imports/@types/UserOrgRelation'

import type {
  EnableTwoFactorPayload,
  DisableTwoFactorPayload,
  FetchCustomerDataPayload,
} from '@/imports/lib/services/apiNamespaces/organization.api'
import {
  type Organization,
  type PartialOrganization,
  isOrganization,
  type OrganizationMetrics,
  type Authentication,
} from '@/imports/@types/Organization'
import type { DataSourceUploadType } from '@/imports/@types/DataSource'
import type { BusinessUnitResponse } from '@/imports/@types/organizationStructure/v2/OrganizationStructure'
import type { AvailableRole } from '@/imports/@types/Roles'
import { ORGANIZATION_CLIENT_TYPES } from '@/imports/@enums/organizations.enums'

import { SUPPLIER_FOOTPRINT_ENTITY_TYPE } from '@/imports/@enums/supplier-footprints.enums'

type State = {
  organizations: (PartialOrganization | Organization)[]
  activeOrganization: Organization | PartialOrganization | null
  activeFinancialYear: number
  availableYearsDetail: boolean
  orgStructure: BusinessUnitResponse
  availableProductYears: []
  availableRoles?: AvailableRole[]
  rootOrgId?: string
  minAvailableDate?: Date
}

function fyDatesHelper(year: number, asString: true): string
function fyDatesHelper(year: number, asString: false): [moment.Moment, moment.Moment]
function fyDatesHelper(
  this: ReturnType<typeof useOrganizationStore>,
  year: number,
  asString: boolean,
): [moment.Moment, moment.Moment] | string {
  const startDate = this.financialYearFirstDate(year)
  const endDate = startDate.clone().add(1, 'year').add(-1, 'days')

  if (asString) {
    return `${startDate.format('MMM DD, YYYY')} - ${endDate.format('MMM DD, YYYY')}`
  }

  return [startDate, endDate]
}

export const useOrganizationStore = defineStore('organization', {
  state: (): State => ({
    activeOrganization: null,
    organizations: [],
    activeFinancialYear: moment().utc().year(),
    availableYearsDetail: false,
    orgStructure: [],
    availableRoles: undefined,
    rootOrgId: undefined,
    minAvailableDate: undefined,
    availableProductYears: [],
  }),

  getters: {
    accessiblePages: state => state.activeOrganization?.configuration.accessiblePages || {},

    baselineYear: state => state.activeOrganization?.configuration.baselineYear,

    rootOrg(): PartialOrganization | undefined {
      return this.organizations.find(org => org.id === this.rootOrgId)
    },

    authMode(): string {
      return this.authentication?.mode || AUTH_MODES.CREDENTIALS
    },

    enforceTwoFactor(): boolean {
      return !!this.authentication?.enforceTwoFactorAuth
    },

    authentication(): Authentication | undefined {
      return this.rootOrg?.authentication
    },
    /**
     * Returns a function which takes a calendar year argument and
     * returns the financial year start date for that calendar
     * year.
     */
    financialYearFirstDate(): (year: number) => moment.Moment {
      return (year: number): moment.Moment => {
        let financialYear = moment.utc().startOf('year')

        if (!this.activeOrganization?.configuration?.financialYearSettings?.useCalendarYear) {
          financialYear = moment.utc(
            this.activeOrganization?.configuration?.financialYearSettings?.financialYearStartsAt,
          )
        }

        return financialYear.year(year - this.financialYearOffset)
      }
    },

    financialYearRange(): typeof fyDatesHelper {
      return fyDatesHelper.bind(this)
    },

    /**
     * Returns a number defining the number of months
     * difference between the financial year and the calendar
     * year
     */
    financialYearOffset(): number {
      if (
        !this.activeOrganization ||
        !this.baselineYear ||
        this.activeOrganization?.configuration?.financialYearSettings?.useCalendarYear
      ) {
        return 0
      }

      const financialYear = Number(
        this.activeOrganization?.configuration?.financialYearSettings?.financialYearStartsAt.split('-')[0],
      )

      return this.baselineYear - financialYear
    },

    financialYearSettings: state => state.activeOrganization?.configuration.financialYearSettings,

    minFinancialYearDate(): Date | undefined {
      if (!this.baselineYear) return

      return moment(`${this.baselineYear - 1}-01-01`).toDate()
    },

    /**
     * Returns the first calendar month number for the active org.
     * If the company does not have a custom financial year this is
     * always 1. If they do it is the financial year start value
     */
    firstCalendarMonth: (state): number => {
      if (state.activeOrganization?.configuration?.financialYearSettings?.useCalendarYear) return 1

      return Number(
        state.activeOrganization?.configuration?.financialYearSettings?.financialYearStartsAt.split('-')[1] || 1,
      )
    },

    get:
      state =>
      (id: string): PartialOrganization | Organization | undefined =>
        state.organizations.find(a => a.id === id),

    hasMultipleOrganizations: state => !!state.organizations.length,

    id: state => state.activeOrganization?.id,

    isFirstTime(): boolean {
      if (!isOrganization(this.activeOrganization)) return false

      // TODO tidyup and handle RBAC properly.
      return !!this.activeOrganization.roles[0]?.isFirstTime && this.activeOrganization.roles[0].role !== 'SUPER_USER'
    },

    isPortfolioOrganization(): boolean {
      if (!isOrganization(this.activeOrganization)) return false

      return this.activeOrganization?.type === 'portfolio'
    },

    isProductOrganization(): boolean {
      if (!isOrganization(this.activeOrganization)) return false

      return this.activeOrganization?.type === 'product'
    },

    isServiceOrganization(): boolean {
      if (!isOrganization(this.activeOrganization)) return false

      return this.activeOrganization?.type === 'professional_services'
    },

    isEnterpriseOrganization: state => state.activeOrganization?.configuration.clientType === 'self',

    isSupplier: state => state.activeOrganization?.configuration.clientType === 'supplier',

    isBasic: state => state.activeOrganization?.configuration.clientType === ORGANIZATION_CLIENT_TYPES.BASIC,

    logo: state => state.activeOrganization?.logo,

    name: state => state.activeOrganization?.name,

    organizationConfiguration: state => state.activeOrganization?.configuration,

    organizationCurrencySymbol(state) {
      const currency = state.activeOrganization?.configuration?.currency || 'pounds'

      return CURRENCY_MAPPING[currency as keyof typeof CURRENCY_MAPPING]?.symbol
    },

    organizationMetrics(): OrganizationMetrics | undefined {
      if (!isOrganization(this.activeOrganization)) return

      return this.activeOrganization?.organizationMetrics
    },

    userRole(): string {
      if (!isOrganization(this.activeOrganization)) return ''

      return this.activeOrganization.roles[0]?.role
    },

    currentOrgIsRootOrg: state => state.activeOrganization?.id === state.rootOrgId,

    activeOrganisationHasChildren: state => state.orgStructure[0]?.children.length > 0,

    enabledModules(state) {
      const enabledModules: { [key: string]: boolean } = state.activeOrganization?.configuration?.enabledModules || {}

      return Object.keys(enabledModules).filter(module => enabledModules[module])
    },
  },

  actions: {
    async getUserOrganizations() {
      const userStore = useUserStore()
      const { lastSelectedOrgId } = userStore.user
      if (!lastSelectedOrgId) return

      const {
        data: { result },
      } = await Api.organization.listForUser({ rootOnly: false })

      this.organizations = result
    },

    async setMinAvailableDate(orgId: string) {
      try {
        const {
          data: { result },
        } = await Api.corporateFootprints.getRootOrgCompleteYearStatus({
          orgId,
        })

        const usesCalendarYear = !!this.activeOrganization?.configuration.financialYearSettings.useCalendarYear
        const financialYearStartsAt = this.activeOrganization?.configuration.financialYearSettings.financialYearStartsAt

        this.minAvailableDate = getFirstUnlockedDate(
          result,
          Number(this.baselineYear),
          usesCalendarYear,
          financialYearStartsAt,
        )
      } catch (err) {
        console.error(err)
      }
    },

    /**
     * Sets the active organization in the state and initiates loading everything related to
     * that organization. Conditionally stores the orgId in localstorage
     */
    async setActiveOrganizationById(orgId?: string) {
      const userStore = useUserStore()
      const reportStore = useReportsStore()
      const legalEntityStore = useLegalEntitiesStore()

      const activeOrgId = orgId || userStore.user.lastSelectedOrgId

      if (!activeOrgId) return

      const {
        data: { result },
      } = await Api.organization.get(activeOrgId)

      this.activeOrganization = result

      legalEntityStore.getLegalEntities()

      // cache org structure, only fetch if rootOrg changes
      if (this.rootOrgId !== this.activeOrganization.rootOrgId || !this.orgStructure.length) {
        await this.fetchBusinessUnitStructure()
      }

      this.rootOrgId = this.activeOrganization.rootOrgId

      // always set the last selected org
      await Api.user.setLastSelectedOrganization(activeOrgId)

      // store activeOrgId in localstorage for faster login
      // but only store it if the user has a role on the org,
      // as the user can't login on an org where he has no role
      if (!userStore.isSuperUser) window.localStorage.setItem('orgId', activeOrgId)

      reportStore.resetAllReports()

      // update user store user role
      const role = result.roles[0]?.role || ROLE_KEYS.CONTRIBUTOR
      userStore.updateRole(role as ROLE_KEYS, result.roles[0]?.relation || USER_ORG_RELATION.INTERNAL)

      /**
       * IMPORTANT:
       * This is a temporary Basic organisation enhancement. The short term goal for
       * supplier footprints is that they are ingested and included in the response
       * when fetching reports. Right now, that is the case for Basic Calculator reports
       * only. Basic Surveys are not yet ingested, but in order to show that they are
       * processing we need to include them in the organisations available years.
       *
       * We do that by fetching them here if the org is a basic type, currently 'Supplier'.
       * We then merge any completed Basic Survey years with the reports store, preserving
       * uniqueness (see below). This code will be removed as soon as supplier surveys are
       * ingested by the data layer.
       */
      let availableSupplierSurveyYears: number[] = []

      if (result.configuration.clientType === 'supplier') {
        try {
          const { data } = await Api.supplierFootprints.getYearlyBreakdown()

          availableSupplierSurveyYears = Object.keys(data.yearlyBreakdown)
            .filter(year => {
              return data.yearlyBreakdown[year].type === SUPPLIER_FOOTPRINT_ENTITY_TYPE.SUPPLIER_SURVEY
            })
            .map(year => +year)
        } catch (err) {
          showToast({
            type: 'is-danger',
            message: getApiErrorMessage(err),
          })
        }
      }

      await FeatureFlagService.setContext({
        user: userStore.user,
        org: this.activeOrganization,
      })

      if (result.availableYears.length > 0 || availableSupplierSurveyYears.length) {
        reportStore.setAvailableYears({
          // here is where we merge the basic survey years
          availableYears: [...new Set([...result.availableYears, ...availableSupplierSurveyYears])],
          baselineYear: result.configuration.baselineYear,
        })

        await reportStore.fetchReports({
          orgId: activeOrgId,
          year: this.activeFinancialYear,
        })

        await reportStore.fetchReports({
          orgId: activeOrgId,
          year: this.activeFinancialYear,
          timePeriod: TimePeriods.QUARTER,
        })

        await reportStore.fetchIntensityMetrics({ orgId: activeOrgId })
      }

      this.availableProductYears = result.availableProductYears

      await this.setMinAvailableDate(activeOrgId)

      // load roles
      await this.getAvailableRoles()

      this.clearMemoizations()
    },

    /**
     * Fetches an org and returns the orgName
     */
    async fetchOrgName(orgId: string) {
      const {
        data: { result },
      } = await Api.organization.get(orgId)

      return result.name
    },

    /**
     * Fetches an org and returns it
     */
    async fetchOrg(orgId: string) {
      const {
        data: { result },
      } = await Api.organization.get(orgId)

      return result
    },

    /**
     * @param { orgId } string
     * @param { secret } string
     * @param { totpToken } string
     *
     * @returns { string }
     */
    async enableTwoFactorEnforcement({ orgId, secret, totpToken }: EnableTwoFactorPayload) {
      if (!orgId || !totpToken || !secret) {
        throw new Error('organization/enableTwoFactorEnforcement: Please provide a valid orgId, secret, and totpToken')
      }

      // update the two factor flag on the org
      const {
        data: { result },
      } = await Api.organization.enableTwoFactor({ orgId, secret, totpToken })

      // get the new state of the org from the backend
      await this.setActiveOrganizationById(orgId)

      return result.twoFactorBackupCode
    },

    async disableTwoFactorEnforcement({ orgId, totpToken }: DisableTwoFactorPayload): Promise<void> {
      if (!orgId || !totpToken) {
        throw new Error('organization/disableTwoFactorEnforcement: Please provide a valid orgId and totpToken')
      }

      // update the two factor flag on the org
      await Api.organization.disableTwoFactor({ orgId, totpToken })

      // get the new state of the org from the backend
      await this.setActiveOrganizationById(orgId)
    },

    /**
     * Fetches customer data from API
     */
    async fetchCustomerData({ orgId, year, metaQuery, month }: FetchCustomerDataPayload) {
      if (!year) throw new Error('organization/fetchCustomerData >>> Please provide a valid year')
      if (!orgId) throw new Error('organization/fetchCustomerData >>> Please provide a valid orgId')

      const {
        data: { result },
      } = await Api.organization.fetchCustomerData({
        orgId,
        year,
        metaQuery,
        month,
      })

      return result
    },

    /**
     * Fetches an organization's total stats from API
     *
     * Returns an array of organization metrics by year
     * Returns detail metrics like total carbon impact
     * and total KM travelled by all customers.
     */
    async fetchOrganizationMetrics(orgId: string) {
      if (!this.activeOrganization || !isOrganization(this.activeOrganization)) {
        throw new Error('organization/fetchOrganizationMetrics >>> No active org')
      }

      const organizationId = orgId || this.activeOrganization?.id

      const {
        data: { result },
      } = await Api.organization.getTotalStats(organizationId)

      this.activeOrganization.organizationMetrics = result
    },

    /**
     * Fetches all business units for an org
     */
    async fetchBusinessUnitStructure() {
      const {
        data: { result: structure },
      } = await Api.organizationStructure.getOrgStructure({
        orgId: this.activeOrganization?.id || '',
        requestFullOrgTree: true,
        effectiveDate: moment().format('YYYY-MM-DD'),
      })

      if (!structure) throw new Error('missing org structure from response')

      this.orgStructure = structure
    },

    /**
     * Fetch users who belong to organization
     */
    async fetchOrgUser() {
      const orgId = this.activeOrganization?.id

      if (!orgId) throw new Error('organization/fetchOrgUsers >>> no orgId available')

      const {
        data: { result },
      } = await Api.organization.getUsers(orgId)

      return orderBy(result, ['userName'], 'desc')
    },

    setOrganizations(organizations: Organization[]) {
      this.organizations = organizations
    },

    setActiveOrganization({ organization }: { organization: Organization }) {
      this.activeOrganization = organization
    },

    toggleIsFirstTime() {
      if (!isOrganization(this.activeOrganization)) return

      this.activeOrganization.roles[0].isFirstTime = false
    },

    updateOrganizationMetrics(metrics: OrganizationMetrics) {
      if (!isOrganization(this.activeOrganization)) return

      this.activeOrganization.organizationMetrics = metrics
    },

    async updateProductUploadType({
      orgId,
      productUploadType,
    }: {
      orgId?: string
      productUploadType: DataSourceUploadType
    }) {
      const {
        data: { result },
      } = await Api.organization.updateProductUploadType({
        orgId: orgId || '',
        uploadType: productUploadType,
      })

      if (this.activeOrganization) {
        this.activeOrganization.configuration = result
      }
    },

    async refreshOrgState() {
      if (!this.activeOrganization) return

      const [newState] = await Promise.all([
        this.fetchOrg(this.activeOrganization.id),
        this.fetchBusinessUnitStructure(),
      ])

      this.activeOrganization = newState
    },

    async updateOrgState() {
      // TODO: move update logic from edit org components to here
    },

    async getAvailableRoles() {
      const userStore = useUserStore()
      const { lastSelectedOrgId } = userStore.user

      if (!lastSelectedOrgId) return

      const {
        data: { result },
      } = await Api.organization.getAvailableRoles(lastSelectedOrgId)

      // TODO: resolve this TS error
      this.availableRoles = result as unknown as Array<typeof result>
    },

    /**
     * When the org store switches organisation we need to clear out some request caches
     */
    clearMemoizations() {
      Api.supplierFootprints.getYearlyBreakdown.clear()
    },
  },
})
