import type { AxiosInstance } from 'axios'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'
import type { TagTemplate } from '@/imports/@types/advisorApp/TagTemplate'

export const useTagTemplateApi = (http: AxiosInstance) => ({
  get(payload: { orgId: string; type: string }) {
    return http.get<ApiResponseInner<TagTemplate[]>>('/tagtemplate/get', {
      params: {
        ...payload,
      },
    })
  },

  create(payload: { orgId: string; type: string; tag: string }) {
    return http.post<ApiResponseInner<undefined>>('/tagtemplate/create', payload)
  },
})
