// [GET] /initiatives/inputs

import { SORT_ORDER } from '../../../@enums/common.enums'
import type { PaginationMeta } from '../generic/pagination-meta.dto'
import { ValidateDTO } from '..'

import { INITIATIVE_CONTROLLER } from './controller.common-vars'

export const INITIATIVE_GET_INPUTS_SUBPATH = 'inputs'
export const INITIATIVE_GET_INPUTS_URI = `${INITIATIVE_CONTROLLER}/${INITIATIVE_GET_INPUTS_SUBPATH}`

export enum INPUTS_LIST_SORT_FIELDS {
  NAME = 'name',
  SUPPLIER = 'supplier',
  ACCURACY_SCORE = 'accuracyScore',
  EMISSIONS_PER_KG = 'emissionsPerKg',
  QUANTITY = 'quantity',
  TOTAL_EMISSIONS = 'totalEmissions',
}

export type InputType = {
  name: string
  value: string
}

export class InitiativesInputsDTOReq extends ValidateDTO {
  selectedInputs!: string[]

  getSelectedOnly!: boolean

  searchTerm?: string

  perPage?: number

  pageNo?: number

  sortOrder?: SORT_ORDER

  sortBy?: INPUTS_LIST_SORT_FIELDS

  inputType?: string
}

export type InitiativeInputItem = {
  id: string
  name: string
  supplier: string
  // per kg view
  accuracyScore: number
  emissionsPerKg: number
  inputType: InputType[]
  year: number
  geography: string

  // past year view
  quantity: number
  quantityUnit: string
  totalEmissions: number
  totalEmissionsUnit: string

  productCount: number
  proportion?: number
}

export type InitiativesInputsDTORes = {
  inputs: InitiativeInputItem[]
  dateRange: {
    startDate: string
    endDate: string
  }
  meta: PaginationMeta & {
    sortBy: INPUTS_LIST_SORT_FIELDS
  }
}
