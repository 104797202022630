/* eslint-disable no-param-reassign */
/* eslint-disable prefer-rest-params */
import { isE2ETesting } from '@/client/helpers'

// eslint-disable-next-line arrow-body-style
const hotjarId = () => {
  return import.meta.env.VITE_HOTJAR_SITE_ID
}

export default function init() {
  if (isE2ETesting()) return // Default hotjar.com snippet
  ;(function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        ;(h.hj.q = h.hj.q || []).push(arguments)
      }
    h._hjSettings = { hjid: hotjarId(), hjsv: 6 }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    r.nonce = '4nP6ae3wtePj9y'
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}
