import { IsBoolean, IsOptional, IsUUID } from 'class-validator'

import { DATASET_DELETE_ACTIONS } from '../dataset/dataset-common.dto'

import { ValidateDTO } from '..'

import { FILE_UPLOAD_CONTROLLER } from './controller.common-vars'

export const FILE_UPLOAD_REQUEST_DELETE_SUBPATH = 'dataset/:fileUploadId/request-file-deletion'
export const FILE_UPLOAD_REQUEST_DELETE_URI = `${FILE_UPLOAD_CONTROLLER}/${FILE_UPLOAD_REQUEST_DELETE_SUBPATH}`

export class FileUploadRequestDeleteDTOReq extends ValidateDTO {
  @IsUUID()
  fileUploadId!: string

  @IsBoolean()
  @IsOptional()
  isUserPlanningToModify?: boolean
}

type FileUpload = {
  id: string
  fileName: string
  metaData: { dataSourceId: string }
  S3Url: string
  status: string
  validationErrors: unknown
  tags: unknown
  system: string
  comments: string
}

export type FileUploadRequestDeleteDTORes = {
  file: FileUpload
  fileId: string
  status: DATASET_DELETE_ACTIONS
  isUserPlanningToModify: boolean
  ticketId: string
}
