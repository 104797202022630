<template>
  <div
    class="category-icon"
    :class="{ 'p-2': includePadding }"
  >
    <div
      v-if="includeBackground"
      class="category-icon--mask"
      :class="`has-background-${colors[category] || 'grey-2'}`"
    />
    <div
      v-if="isComplete"
      class="category-icon__checked"
    >
      <CheckCircleIcon
        class="active"
        :width="20"
      />
    </div>
    <Icon
      v-if="icon"
      :icon="icon"
      pack="fa"
      :class="`has-text-${colors[category] || 'grey-2'}`"
    />
    <slot name="label" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  import Icon from '@components/Icon.vue'
  import CheckCircleIcon from '@components/icons/CheckCircleIcon.vue'

  import {
    SCOPE_OR_ACTIVITY_AREA_COLOR_NAMES,
    CATEGORY_ACTIVITY_ITEMS_ICONS,
  } from '@/imports/lib/constants/categoryIconConstants'
  import { CALC_METHODS_COLORS, CALC_METHODS_ICONS } from '@/imports/lib/constants/calcMethodsConstants'

  import { EMISSION_FACTORS_FILTER_ICONS } from '@/imports/lib/constants/efCatalogueConstants'

  export default defineComponent({
    name: 'CategoryIcon',

    components: {
      Icon,
      CheckCircleIcon,
    },

    props: {
      category: {
        // COLOR: Scope (Scope 1) or ActivityArea (Utility Use)
        type: String,
        default: '',
        validator: (value: string) => typeof value === 'string',
      },

      categoryItem: {
        // ICON: Scope CategoryName (Stationary Combustion) or Activity (Electricity Use)
        type: String,
        default: '',
        validator: (value: string) => typeof value === 'string',
      },

      includePadding: {
        type: Boolean,
        default: true,
      },

      includeBackground: {
        type: Boolean,
        default: true,
      },

      isComplete: {
        type: Boolean,
      },

      /**
       * Category type: activity | calcMethod | efFilter
       */
      categoryType: {
        type: String,
        default: 'activity',
      },
    },

    computed: {
      colors() {
        if (this.categoryType === 'calcMethod') {
          return CALC_METHODS_COLORS
        }

        return SCOPE_OR_ACTIVITY_AREA_COLOR_NAMES
      },

      icon() {
        const getIcon =
          CATEGORY_ACTIVITY_ITEMS_ICONS[this.categoryItem.toLowerCase()] ||
          CATEGORY_ACTIVITY_ITEMS_ICONS[this.categoryItem]

        if (this.categoryType === 'calcMethod') {
          return CALC_METHODS_ICONS[this.categoryItem]
        }

        if (this.categoryType === 'efFilter') {
          return (
            EMISSION_FACTORS_FILTER_ICONS[this.categoryItem] ||
            EMISSION_FACTORS_FILTER_ICONS[this.categoryItem.toLowerCase()]
          )
        }

        return getIcon || 'tag'
      },
    },
  })
</script>

<style lang="scss" scoped>
  $module: 'category-icon';

  .#{$module} {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &--mask {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      z-index: 0;
      opacity: 0.1;
    }

    &__checked {
      position: absolute;
      left: -7px;
      top: -7px;
    }
  }
</style>
