import type { AxiosInstance } from 'axios'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'
import type { IntensityMetric } from '@/imports/@types/IntensityMetric'
import type { Report } from '@/imports/@types/Report'

type GetAllReportsPayload = {
  orgId: string
  timePeriod: string
}

type CreateIntensityMetricPayload = {
  orgId: string
  intensityMetricsData: {
    name: string
    description: string
    values: Record<string, unknown>
    isVisibleOnDashboard: boolean
  }[]
}

export const useReportsApi = (http: AxiosInstance) => ({
  getAllReports({ orgId, timePeriod }: GetAllReportsPayload) {
    return http.get<ApiResponseInner<Report[]>>('/organization/getallreports', {
      params: {
        orgId,
        timePeriod,
      },
    })
  },

  getIntensityMetrics(orgId: string) {
    return http.get<ApiResponseInner<IntensityMetric[]>>('/intensitymetrics/getfororganization', {
      params: {
        orgId,
      },
    })
  },

  /**
   * Create or update an intensity metric
   */
  createIntensityMetric(payload: CreateIntensityMetricPayload) {
    return http.post<ApiResponseInner<IntensityMetric[]>>('intensitymetrics/upsert', payload)
  },
})
