import { IsUUID } from 'class-validator'

import { SELF_SERVE_UPLOAD_CONTROLLER } from './controller.common-vars'

export const SELF_SERVE_UPLOAD_DELETE_SUBPATH = 'dataset/:selfServeUploadId'
export const SELF_SERVE_UPLOAD_DELETE_URI = `${SELF_SERVE_UPLOAD_CONTROLLER}/${SELF_SERVE_UPLOAD_DELETE_SUBPATH}`

export class SelfServeUploadDeleteDTOReq {
  @IsUUID()
  selfServeUploadId!: string
}
