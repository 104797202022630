/**
 * Rounds accuracy score according to business rules:
 * Rounding:
 * For decimals of .5 and below, round down. For decimals of .51 and above, round up
 * Except in the range of 0.00-0.05 - in this case always round up to 1, to avoid having an accuracy score of 0  *
 * Examples:
 * Absolute score 4.1 --> Assigned score 4
 * Absolute score 4.5 --> Assigned score 4
 * Absolute score 4.51 --> Assigned score 5
 * Absolute score 0.1 --> Assigned score 1 (estimate)
 * Absolute score 0.5 --> Assigned score 1 (estimate)
 * Absolute score -1 --> Do not assign a score (will be due to an error somewhere)
 * For unit tests see: https://codesandbox.io/s/altruistiq-unit-tests-qb4yrc?file=/src/roundAccuracyScore.test.js
 *
 * @param { number } accuracyScore
 *
 * @returns { number | null }
 */
export const roundAccuracyScore = (accuracyScore: number | null) => {
  if (accuracyScore === null) return null

  if (accuracyScore <= 1 && accuracyScore >= 0) return 1

  return -1 * Math.round(accuracyScore * -1) // by default JavaScript rounds UP, we need to round DOWN
}
