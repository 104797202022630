import type { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { useTargetsStore } from '@/client/store/targets/targets.pinia'
import { ACTIVE_EMISSION_TARGET_ROUTE_STATE } from '@/imports/@enums/emissions-targets.enums'

export const updateActiveTarget: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> => {
  const targetsStore = useTargetsStore()
  const state =
    to.name === 'Edit sustainability target'
      ? ACTIVE_EMISSION_TARGET_ROUTE_STATE.EDIT
      : ACTIVE_EMISSION_TARGET_ROUTE_STATE.VIEW

  await targetsStore.setActiveTarget(to.params.id.toString() || '', state)

  return next()
}
