import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { FILE_UPLOAD_CONTROLLER } from './controller.common-vars'

export const FILE_UPLOAD_DELETABLE_SUBPATH = ':fileUploadId/deletable'
export const FILE_UPLOAD_DELETABLE_URI = `${FILE_UPLOAD_CONTROLLER}/${FILE_UPLOAD_DELETABLE_SUBPATH}`

export class FileUploadDeletableTOReq extends ValidateDTO {
  @IsUUID()
  fileUploadId!: string
}
