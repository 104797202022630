<template>
  <div
    class="level"
    :class="alignmentClass"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'

  type Props = {
    position?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    position: 'center',
  })

  const alignmentClass = computed(() => `is-align-items-${props.position}`)
</script>
