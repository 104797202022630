import axios, { AxiosError } from 'axios'

export const getApiErrorMessage = (err: unknown): string => {
  if (axios.isAxiosError(err)) {
    const axiosErr = err as AxiosError
    const data = axiosErr.response?.data as { error?: string }
    return data?.error || err.message
  }

  return `${err}`
}
