import { IsUUID } from 'class-validator'

import { MAINTENANCE_SERVICE_ERRORS, MAINTENANCE_SERVICE_WARNINGS } from '../../../@enums/data-source.enums'

import { SELF_SERVE_UPLOAD_CONTROLLER } from './controller.common-vars'

export const SELF_SERVE_UPLOAD_DELETABLE_SUBPATH = ':selfServeUploadId/deletable'
export const SELF_SERVE_UPLOAD_DELETABLE_URI = `${SELF_SERVE_UPLOAD_CONTROLLER}/${SELF_SERVE_UPLOAD_DELETABLE_SUBPATH}`

// Shared type between fileUpload & selfServeUpload
export type DeletionRules = {
  canCustomerDelete: boolean
  canCustomerRequestDeletion: boolean
  canInternalUserDelete: boolean
  fileIsIngested: boolean
}

export function isDeletionRules(obj: unknown): obj is DeletionRules {
  const _obj = obj as DeletionRules
  return (
    _obj !== undefined &&
    typeof _obj === 'object' &&
    _obj !== null &&
    typeof _obj?.canCustomerDelete === 'boolean' &&
    typeof _obj?.canCustomerRequestDeletion === 'boolean' &&
    typeof _obj?.canInternalUserDelete === 'boolean' &&
    typeof _obj?.fileIsIngested === 'boolean'
  )
}

interface MaintenanceError {
  activityCode: string
  errors: MAINTENANCE_SERVICE_ERRORS[]
}

interface MaintenanceWarning {
  activityCode: string
  warnings: MAINTENANCE_SERVICE_WARNINGS[]
}

export interface MaintenanceErrors {
  errors: MaintenanceError[]
  warnings: MaintenanceWarning[]
}

export class SelfServeUploadDeletableDTOReq {
  @IsUUID()
  selfServeUploadId!: string
}
