<template>
  <template v-if="enabled">
    <slot name="enabled"></slot>
  </template>

  <template v-else>
    <slot name="fallback"></slot>
  </template>
</template>

<script lang="ts" setup>
  /**
   * Higher order component used for wrapping features that are
   * conditionally enabled for the current user
   * and displays the fallback slot if user doesn't meet the flag criteria
   *
   * See FeatureFlags.md for more info about feature flags
   */

  import { computed } from 'vue'

  import FeatureFlagService from '@/imports/lib/services/featureFlagService'

  const props = defineProps<{
    flagName: string | string[]
  }>()

  const enabled = computed(() => FeatureFlagService.isEnabled(props.flagName))
</script>
