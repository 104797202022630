import { IsOptional, IsString, IsNumber, IsEnum, IsArray } from 'class-validator'

import { ValidateDTO } from '..'
import { SORT_ORDER } from '../../../@enums/common.enums'

import type { Paginated } from '../../../../imports/@types/Paginated'
import type { BasicFacility } from '../../../@types/BasicFacility'

import { BASIC_FACILITY_LIST_SORT_BY, BASIC_FACILITY_DATA_STATUS } from '../../../@enums/basic-facilities.enum'

import { BASIC_FACILITIES_CONTROLLER } from './controller.common-vars'

export const BASIC_FACILITY_SUBPATH = ''
export const BASIC_FACILITY_URI = `${BASIC_FACILITIES_CONTROLLER}/${BASIC_FACILITY_SUBPATH}`

export class BasicFacilityListDTOReq extends ValidateDTO {
  @IsString()
  effectiveDate!: string

  @IsNumber()
  @IsOptional()
  pageNo?: number

  @IsNumber()
  @IsOptional()
  perPage?: number

  @IsString()
  @IsOptional()
  searchQuery?: string

  @IsEnum(BASIC_FACILITY_LIST_SORT_BY)
  @IsOptional()
  sortBy?: string

  @IsEnum(SORT_ORDER)
  @IsOptional()
  sortOrder?: SORT_ORDER

  @IsArray()
  @IsOptional()
  dataStatus?: BASIC_FACILITY_DATA_STATUS[]

  @IsArray()
  @IsOptional()
  countries?: string[]
}

export type BasicFacilityListDTORes = {
  facilitiesOverviewEmissions: Paginated<{
    id: string
    facilityName: string
    effectiveDate: string
    country: string
    postalCode: string
    type: string
    dataStatus: BASIC_FACILITY_DATA_STATUS
  }>
}

export type BasicFacilityListResponse = {
  facilityStructure: BasicFacility[]
  totalCount: number
}
