<template>
  <div
    class="aq-loader"
    data-ci="aq-loader"
    :class="{ 'full-page': fullPage }"
  >
    <Spinner
      v-if="variant === 'cubes'"
      class="aq-loader__spinner"
      :isBlock="true"
      :color="color"
    ></Spinner>

    <div
      v-if="variant === 'circle'"
      class="aq-loader__circle-spinner"
    ></div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, type PropType } from 'vue'

  import Spinner from '@reinga/Spinner/Spinner.vue'

  export default defineComponent({
    name: 'AqLoader',

    components: {
      Spinner,
    },

    props: {
      variant: {
        type: String as PropType<'cubes' | 'circle'>,
        default: 'cubes',
      },

      fullPage: {
        type: Boolean,
      },

      /**
       * Color value which will be passed to Spinner component
       */
      color: {
        type: String,
        default: 'blue',
      },
    },
  })
</script>

<style lang="scss" scoped>
  $module: 'aq-loader';

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .#{$module} {
    &.full-page {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($grey-6, 0.7);
      z-index: 10;
    }

    &__spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &__circle-spinner {
      width: 24px;
      height: 24px;
      border: 2px solid $kelp;
      border-bottom-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
      border-radius: 50%;
      display: inline-block;
      animation: rotation 1s linear infinite;

      .aq-btn & {
        width: 12px;
        height: 12px;
      }
    }
  }
</style>
