import { useMemoize } from '@vueuse/core'

import type { AxiosInstance } from 'axios'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'

import type {
  FacilityPayload,
  FacilityResponse,
  FacilityDetailPayload,
  FacilityDetail,
  GetFieldHistoryPayload,
  GetFieldHistoryResponse,
  DeleteFacilityProperty,
  EditFacilityProperty,
  CreateFacilityProperty,
  CreateFacilityPropertyResponse,
  CreatePayload,
  CreateResponse,
  FacilityGetTagsResponse,
  FacilityDeletePayload,
} from '@/imports/@types/facilities/v2/FacilitiesStructure'

import type {
  AllFacilitiesEmissionsPayload,
  FacilityEmissionsPayload,
  FacilityEmissionsOverview,
  FacilityEmissions,
  FacilityEmissionsMonthlyBreakdown,
} from '@/imports/@types/facilities/FacilityEmissions'

export const useFacilitiesPropertiesApi = (http: AxiosInstance) => ({
  getOrganizationFacilities(payload: FacilityPayload) {
    return http.get<FacilityResponse>('/facilityStructure/get', {
      params: payload,
    })
  },

  getFacilityNames(payload: { orgId: string }) {
    return http.get<ApiResponseInner<FacilityGetTagsResponse>>('/facility/getFacilityNames', {
      params: payload,
    })
  },

  get(payload: FacilityDetailPayload) {
    return http.get<{ facility: FacilityDetail }>('/facility/get', {
      params: payload,
    })
  },

  getFieldHistory(payload: GetFieldHistoryPayload) {
    return http.get<GetFieldHistoryResponse>('/facilityProperties/getFieldHistory', {
      params: payload,
    })
  },

  deleteFacilityProperty(payload: DeleteFacilityProperty) {
    return http.delete<boolean>('/facilityProperties/delete', { params: payload })
  },

  editFacilityProperty(payload: EditFacilityProperty) {
    return http.patch<ApiResponseInner<CreateFacilityPropertyResponse>>('facilityProperties/update', payload)
  },

  createFacilityProperty(payload: CreateFacilityProperty) {
    return http.post<ApiResponseInner<CreateFacilityPropertyResponse>>('facilityProperties/create', payload)
  },

  getFacilityTags(orgId: string) {
    return http.get<FacilityGetTagsResponse>('/facility/getFacilityTags', {
      params: {
        orgId,
      },
    })
  },

  createFacility(payload: CreatePayload) {
    return http.post<CreateResponse>('/facility/create', payload)
  },

  fetchFacilityOverviewEmissions: useMemoize((payload: AllFacilitiesEmissionsPayload) =>
    http.get<{ facilitiesOverviewEmissions: FacilityEmissionsOverview[] }>('/facilities/emissions/getoverview', {
      params: payload,
    }),
  ),

  fetchFacilityEmissions(payload: FacilityEmissionsPayload) {
    return http.get<{ emissionDetails: FacilityEmissions }>('/facilities/emissions/getActivityDetails', {
      params: payload,
    })
  },

  fetchFacilityActivityBreakdown(payload: FacilityEmissionsPayload & { activityCode: string }) {
    return http.get<{ subActivityMonthBreakdown: FacilityEmissionsMonthlyBreakdown }>(
      '/facilities/emissions/getsubactivitydetails',
      {
        params: payload,
      },
    )
  },

  deleteFacility(payload: FacilityDeletePayload) {
    return http.delete('/facility/delete', {
      params: payload,
    })
  },
})
