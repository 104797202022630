// rollout flag rollout-paginated-facilities-lists
// TODO: rename and delete v1 when rollout complete

import { useMemoize } from '@vueuse/core'

import type { AxiosInstance } from 'axios'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'

import type { FacilityType } from '@/imports/@types/facilities/FacilityType'

import type {
  FacilityPayload,
  FacilityResponse,
  FacilityDetailPayload,
  FacilityDetailResponse,
  GetFieldHistoryPayload,
  GetFieldHistoryResponse,
  DeleteFacilityProperty,
  EditFacilityProperty,
  CreateFacilityProperty,
  CreateFacilityPropertyResponse,
  CreatePayload,
  CreateResponse,
  FacilityGetTagsResponse,
  FacilityDeletePayload,
} from '@/imports/@types/facilities/v2/FacilitiesStructure'

import type {
  FacilityEmissionsPayload,
  FacilityEmissions,
  FacilityEmissionsMonthlyBreakdown,
} from '@/imports/@types/facilities/FacilityEmissions'

import type {
  FacilitiesOverviewDTOReq,
  FacilitiesOverviewDTORes,
} from '@/imports/lib/DTO/facilities/facilities-overview.dto'

import type {
  FacilitiesOverviewStatsDTOReq,
  FacilitiesOverviewStatsDTORes,
} from '@/imports/lib/DTO/facilities/facilities-overview-stats.dto'

import type {
  FacilitiesOverviewTypesDTOReq,
  FacilitiesOverviewTypesDTORes,
} from '@/imports/lib/DTO/facilities/facilities-overview-types.dto'

export const useFacilitiesPropertiesApi = (http: AxiosInstance) => ({
  getOrganizationFacilities(payload: FacilityPayload) {
    return http.get<FacilityResponse>('/facilityStructure/get', {
      params: payload,
    })
  },

  getFacilityNames(payload: { orgId: string }) {
    return http.get<ApiResponseInner<FacilityGetTagsResponse>>('/facility/getFacilityNames', {
      params: payload,
    })
  },

  get(payload: FacilityDetailPayload) {
    return http.get<FacilityDetailResponse>('/facility/get', {
      params: payload,
    })
  },

  getFieldHistory(payload: GetFieldHistoryPayload) {
    return http.get<GetFieldHistoryResponse>('/facilityProperties/getFieldHistory', {
      params: payload,
    })
  },

  deleteFacilityProperty(payload: DeleteFacilityProperty) {
    return http.delete<boolean>('/facilityProperties/delete', { params: payload })
  },

  editFacilityProperty(payload: EditFacilityProperty) {
    return http.patch<ApiResponseInner<CreateFacilityPropertyResponse>>('facilityProperties/update', payload)
  },

  createFacilityProperty(payload: CreateFacilityProperty) {
    return http.post<ApiResponseInner<CreateFacilityPropertyResponse>>('facilityProperties/create', payload)
  },

  getFacilityTags(orgId: string) {
    return http.get<FacilityGetTagsResponse>('/facility/getFacilityTags', {
      params: {
        orgId,
      },
    })
  },

  createFacility(payload: CreatePayload) {
    return http.post<CreateResponse>('/facility/create', payload)
  },

  fetchFacilityOverviewStats: useMemoize((orgId: string, payload: FacilitiesOverviewStatsDTOReq) =>
    http.get<FacilitiesOverviewStatsDTORes>('/facilities/emissions/stats', {
      params: payload,
      headers: {
        'x-org-id': orgId,
      },
    }),
  ),

  fetchFacilityOverviewEmissions: (orgId: string, payload: FacilitiesOverviewDTOReq) =>
    http.get<FacilitiesOverviewDTORes>('/facilities/emissions/paginated-overview', {
      params: payload,
      headers: {
        'x-org-id': orgId,
      },
    }),

  fetchFacilityOverviewTypeEmissions: useMemoize((orgId: string, payload: FacilitiesOverviewTypesDTOReq) =>
    http.get<FacilitiesOverviewTypesDTORes>('/facilities/emissions/types', {
      params: payload,
      headers: {
        'x-org-id': orgId,
      },
    }),
  ),

  fetchFacilityEmissions(payload: FacilityEmissionsPayload) {
    return http.get<{ emissionDetails: FacilityEmissions }>('/facilities/emissions/getActivityDetails', {
      params: payload,
    })
  },

  fetchFacilityActivityBreakdown(payload: FacilityEmissionsPayload & { activityCode: string }) {
    return http.get<{ subActivityMonthBreakdown: FacilityEmissionsMonthlyBreakdown }>(
      '/facilities/emissions/getsubactivitydetails',
      {
        params: payload,
      },
    )
  },

  deleteFacility(payload: FacilityDeletePayload) {
    return http.delete('/facility/delete', {
      params: payload,
    })
  },

  fetchFacilityTypes(orgId: string) {
    return http.get<ApiResponseInner<{ types: FacilityType[] }>>('/facility/gettypes', {
      params: {
        orgId,
      },
    })
  },
})
