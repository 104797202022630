import type { AxiosInstance } from 'axios'

import type { FileUploadDataStatsResponse } from '@/imports/@types/Dashboard'

export const useFileUploadDataStatsApi = (http: AxiosInstance) => ({
  getFileUploadStats(orgId: string) {
    return http.get<FileUploadDataStatsResponse>('/file-upload-data-stats/', {
      params: {
        orgId,
      },
    })
  },
})
