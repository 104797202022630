import { IsNumber } from 'class-validator'

import { FRANCHISE_REQUESTS_CONTROLLER } from './franchise-requests.common-vars'

export class GetFranchiseRequestsPendingReviewsCountDTO {
  @IsNumber()
  year!: number
}

export const GET_FRANCHISE_REQUESTS_PENDING_REVIEWS_COUNT_SUB_PATH = 'pending-review/count'

export const GET_FRANCHISE_REQUESTS_PENDING_REVIEWS_COUNT_PATH = `${FRANCHISE_REQUESTS_CONTROLLER}/${GET_FRANCHISE_REQUESTS_PENDING_REVIEWS_COUNT_SUB_PATH}`

export type GetFranchiseRequestsPendingReviewsCountResponse = {
  result: number
}
