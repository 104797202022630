import type { AxiosInstance } from 'axios'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'
import type { CreateOAuthClientPayload, CreateOAuthClientResponse } from '@/imports/@types/api/CreateOAuthClient'
import type { IndexOAuthClientsPayload } from '@/imports/@types/api/IndexOAuthClients'
import type { DeleteOAuthClientPayload } from '@/imports/@types/api/DeleteOAuthClient'
import type { OAuthClient } from '@/imports/@types/api/OAuthClient'
import type {
  RotateOAuthCredentialsPayload,
  RotateOAuthCredentialsResponse,
} from '@/imports/@types/api/RotateOAuthCredentials'

export const useOAuthApi = (http: AxiosInstance) => ({
  createClient(payload: CreateOAuthClientPayload) {
    return http.post<ApiResponseInner<CreateOAuthClientResponse>>('/oauth/clients/create', { ...payload })
  },

  listClients(payload: IndexOAuthClientsPayload) {
    return http.get<ApiResponseInner<OAuthClient[]>>('/oauth/clients/list', { params: payload })
  },

  deleteClient(payload: DeleteOAuthClientPayload) {
    return http.delete<{ success: boolean }>('/oauth/clients/delete', { data: payload })
  },

  rotateSecret(payload: RotateOAuthCredentialsPayload) {
    return http.post<ApiResponseInner<RotateOAuthCredentialsResponse>>('/oauth/clients/rotate', { ...payload })
  },
})
