import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { DATASOURCE_CONTROLLER } from './controller.common-vars'

export const DATACONTRACT_DELETE_SUBPATH = 'dataContracts/:dataContractId'
export const DATACONTRACT_DELETE_URI = `${DATASOURCE_CONTROLLER}/${DATACONTRACT_DELETE_SUBPATH}`

export class DeleteDataContractDTOReq extends ValidateDTO {
  @IsUUID()
  dataContractId!: string
}
