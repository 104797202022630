import ConversionHelpersMixin from '../../mixins/conversionHelpersMixin/conversionHelpersMixin'

/**
 * Compare current emission vs previous or base year => in %
 *
 * @param { number } emission
 * @param { number } emissionTwo
 *
 * @returns { "-" | number } emission
 *
 */
export const compareEmissions = (emission?: number | null, emissionTwo?: number | null): number | string | null => {
  if (!emission || !emissionTwo || emission === emissionTwo) {
    return '-'
  }

  const change = ((emission - emissionTwo) / emissionTwo) * 100

  return Number(ConversionHelpersMixin.methods.mixin_emission_perc(change)) || null
}
