import { isActivityReport, isQuarterlyActivityReport } from '@/imports/@types/Report'
import { existingActivitiesStatus } from '@/imports/lib/utilities/existingActivitiesStatus'

import type { ActivityReports, QuarterlyActivityReports } from '@/imports/@types/Report'
import type { PeriodData } from '@/imports/@types/PeriodData'

// check number of activities in year and quarter
export const getActivityDataCompletion = (
  activityReports: ActivityReports | QuarterlyActivityReports,
): PeriodData[] => {
  const periodData = []

  // eslint-disable-next-line guard-for-in
  for (const year in activityReports) {
    const report = activityReports[year]

    if (!report) return []

    // Get the activities for the report, but strip out the other or misc options
    let activities: string[] | string[][]

    // Handle the year input
    if (isActivityReport(report)) {
      const activityStatus = existingActivitiesStatus(report, year)

      periodData.push(activityStatus)
    }

    // Handle the quarterly input
    if (isQuarterlyActivityReport(report)) {
      activities = report.map(activity =>
        activity
          .reduce(
            (acc, curr) => (['Other', 'Misc'].includes(curr.activityArea) ? acc : [...acc, curr.activityArea]),
            [] as string[],
          )
          .flat(),
      )

      activities.forEach(() => {
        const activityStatus = existingActivitiesStatus(report.flat(), year)
        periodData.push(activityStatus)
      })
    }
  }

  return periodData.flat()
}
