<template>
  <nav
    id="top-bar"
    class="nav-bar"
  >
    <div class="nav-bar__right">
      <div class="nav-bar__group">
        <div class="nav-bar__icon-links">
          <a
            v-tippy="{ content: 'Altruistiq Academy' }"
            class="nav-bar__icon-link"
            href="https://academy.altruistiq.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="academy">
              <Icon
                class="academy__icon"
                icon="graduation-cap"
                pack="far"
              />
            </div>
          </a>

          <div v-tippy="{ content: 'Help centre' }">
            <a
              href="https://help.altruistiq.com"
              class="nav-bar__icon-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <i class="fa-sharp fa-regular fa-book"></i>
              </div>
            </a>
          </div>
          <button
            v-tippy="{ content: 'Ask your advisor' }"
            class="nav-bar__icon-link"
            @click="openIntercom"
          >
            <div>
              <i class="fa-sharp fa-regular fa-comment-dots" />
            </div>
          </button>
        </div>
      </div>

      <div class="nav-bar__group">
        <NavBarMenu
          :organization="organizationName"
          :showOrganizationsLink="hasMultipleOrganizations"
          :showAuditLogsLink="hasMultipleOrganizations"
          :user="user"
          @logout="requestLogout"
        />
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapActions, mapState } from 'pinia'

  import Icon from '@components/Icon.vue'

  import NavBarMenu from '@components/NavBarMenu.vue'

  import { useOrganizationStore } from '@/client/store/organization.pinia'
  import { useReportsStore } from '@/client/store/reports.pinia'
  import { useUserStore } from '@/client/store/user.pinia'

  export default defineComponent({
    name: 'NavBar',

    components: {
      Icon,
      NavBarMenu,
    },

    computed: {
      ...mapState(useOrganizationStore, {
        hasMultipleOrganizations: 'hasMultipleOrganizations',
        organizationConfiguration: 'organizationConfiguration',
        organizationName: 'name',
        organizationLogo: 'logo',
        organization: 'activeOrganization',
        orgId: 'id',
        userRole: 'userRole',
      }),

      ...mapState(useReportsStore, ['availableYearsDetail', 'activeFinancialYear']),

      ...mapState(useUserStore, ['user', 'isSuperUser']),
    },

    methods: {
      ...mapActions(useUserStore, ['logout']),

      async requestLogout() {
        await this.logout()

        window.location.replace('/login')
      },

      isActiveRoute(routeName: string) {
        return this.$route.name === routeName
      },

      openIntercom() {
        this.$intercom.show()
      },
    },
  })
</script>

<style lang="scss" scoped>
  $module: 'nav-bar';
  $height: 64px;

  $logo-chevron-box-size: 45px;

  .#{$module} {
    background-color: $grey-7;
    color: white;
    padding: $grid-size-padding * 2 $grid-size-padding * 11;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: $height;
    border-bottom: 1px solid $grey-5;

    &__left {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    #nav-bar-logo-dropdown :deep(.dropdown__items) {
      overflow: unset;
    }

    &__logo-menu {
      & .#{$module}__chevron {
        color: $white;
      }

      :deep(.dropdown__trigger) {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        padding: 0 $grid-size-padding;
        height: $logo-chevron-box-size;

        &.opened,
        &:hover {
          background-color: $kelp-dark;
        }
      }
    }

    &__logo {
      display: block;
    }

    &__right {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-left: auto;
      gap: $grid-size-margin * 2;
    }

    &__divider {
      height: 40px;
      width: 1px;
      background-color: $kelp-light;
    }

    &__home {
      border-radius: 8px;
      margin-right: $grid-size-padding;

      &:hover {
        background-color: $kelp-dark;
      }
    }

    &__icon-links {
      display: flex;
      flex-flow: row nowrap;
      color: $grey-2;
      gap: 14px;
      position: relative;
    }

    &__icon-link {
      color: inherit;
      font-size: 24px;
      border-radius: $radius;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;

      &:hover {
        background-color: $green-light;
        color: $kelp;
      }
    }
  }
</style>
