<template>
  <template v-if="!enabled">
    <slot></slot>
  </template>
</template>

<script lang="ts" setup>
  import { onBeforeUnmount, ref, onBeforeMount } from 'vue'
  /**
   * Higher order component used for wrapping features that are
   * conditionally enabled for the current user
   *
   * See FeatureFlags.md for more info about feature flags
   */

  import FeatureFlagService from '@/imports/lib/services/featureFlagService'

  const props = defineProps<{
    flagName: string
  }>()

  const enabled = ref(false)

  const updateEnabledState = () => {
    enabled.value = FeatureFlagService.isEnabled(props.flagName)
  }

  FeatureFlagService.subscribeToContext(updateEnabledState)

  onBeforeMount(updateEnabledState)

  onBeforeUnmount(() => {
    FeatureFlagService.unsubscribeToContext(updateEnabledState)
  })
</script>
