import type { AxiosInstance } from 'axios'
import { useMemoize } from '@vueuse/core'

import { ROLE_KEYS } from '@lib/constants/permission/permissionConstants'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'
import type { AsanaUser } from '@/imports/@types/Asana'
import type { Session, SessionWithoutToken } from '@/imports/@types/Session'
import type { User } from '@/imports/@types/User'
import type { ReplacePropertyTypes } from '@/imports/@types/generics/ReplacePropertyTypes'
import type { TIncomingUser } from '@/server/controllers-internal-api/roleAssignmentController'

type LoginPayload = {
  email: string
  password: string
  orgId: string
}

type LoginWithTOTPPayload = {
  sessionToken: string
  totpToken: string
  orgId?: string
}

type LoginWithBackupCodePayload = {
  sessionToken: string
  backupCode: string
}

type EnableTwoFactorPayload = {
  totpToken: string
  sessionToken: string
  secret: string
}

type ResetTwoFactorByBackupCodePayload = {
  backupCode: string
  sessionToken: string
}

export interface LoginResponse extends Session {
  success: boolean
}

type EnableTwoFactorResponse = {
  twoFactorBackupCode: string
}

type LDHashPayload = {
  orgId: string
  context: object
}

type LDHashResponse = {
  hash: string
}

export const useUserApi = (http: AxiosInstance) => ({
  login(payload: LoginPayload) {
    return http.post<ApiResponseInner<LoginResponse>>('/user/login', payload)
  },

  logout() {
    return http.post<ApiResponseInner<undefined>>('/user/logout')
  },

  loginWithTOTP(payload: LoginWithTOTPPayload) {
    return http.post<ApiResponseInner<SessionWithoutToken>>('/user/loginwithtotp', payload)
  },

  loginWithBackupCode(payload: LoginWithBackupCodePayload) {
    return http.post<ApiResponseInner<User>>('/user/loginwithbackupcode', payload)
  },

  getUser(userId: string) {
    return http.get<ApiResponseInner<User>>('/user/get', {
      params: {
        userId,
      },
    })
  },

  toggleIsSuperUser(userId: string) {
    return http.post<ApiResponseInner<undefined>>('/user/toggleissuperuser', { userIdToToggle: userId })
  },

  enableTwoFactor(payload: EnableTwoFactorPayload) {
    return http.post<ApiResponseInner<EnableTwoFactorResponse>>('/twofactorauth/enableforuser', payload)
  },

  resetTwoFactorByTotp(totpToken: string) {
    return http.post<ApiResponseInner<undefined>>('/twofactorauth/resetbytotptoken', { totpToken })
  },

  resetTwoFactorByBackupCode(payload: ResetTwoFactorByBackupCodePayload) {
    return http.post<ApiResponseInner<undefined>>('/twofactorauth/resetbybackupcode', payload)
  },

  setLastSelectedOrganization(orgId: string) {
    return http.post<ApiResponseInner<undefined>>('/user/setlastselectedorganization', { orgId })
  },

  resumeSession(sessionToken: string) {
    return http.post<Session>('/user/resumesession', { sessionToken })
  },

  getAsanaUsers: useMemoize(() => {
    return http.get<ApiResponseInner<AsanaUser[]>>('/user/getasanausers')
  }),

  async generateLDHash(payload: LDHashPayload) {
    return http.post<ApiResponseInner<LDHashResponse>>('/user/generateLDHash', payload)
  },

  toggleFirstTime({ orgId }: { orgId: string }) {
    return http.post<ApiResponseInner<undefined>>('/user/togglefirsttime', {
      orgId,
    })
  },

  updateRole(payload: ReplacePropertyTypes<TIncomingUser, { role: ROLE_KEYS }>, orgId: string) {
    return http.patch<ApiResponseInner<undefined>>(
      '/roleassignment/update',
      { user: payload },
      {
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  },
})
