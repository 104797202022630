import * as LDClient from 'launchdarkly-js-client-sdk'
import type { LDClient as LDCLientType } from 'launchdarkly-js-client-sdk'
import { isString } from 'lodash-es'

import { Api } from '@/imports/lib/services/api.service'
import { LAUNCHDARKLY_CLIENT_ID } from '@/client/helpers'

import type { Context, Flag, LDContext } from '@/imports/@types/LaunchDarkly'

class FeatureFlagService {
  context?: LDContext

  client?: LDCLientType

  isInitialized = false

  availableFeatures: Flag = {}

  // context switch event subscribers
  private contextSubscriptions: (() => void)[] = []

  /**
   * Creates a LD context
   * NOTE: This should always be in the context of the rootOrg, as we only define flags on root orgs
   */
  createContext(context: Context) {
    const { user, org } = context
    const { id: userId, userName, email, roleAssignment, isSuperUser } = user
    const { rootOrgId, name, configuration } = org

    this.context = {
      kind: 'multi',
      user: {
        key: userId,
        id: userId,
        userName,
        email,
        role: roleAssignment?.role,
        isSuperUser,
      },
      org: {
        id: rootOrgId,
        key: rootOrgId,
        name,
        clientType: configuration.clientType,
      },
    }

    return this.context
  }

  /**
   * Initializes the LD client
   */
  async initialize(hash: { hash: string }) {
    const LD_CLIENT_ID = LAUNCHDARKLY_CLIENT_ID()

    if (this.isInitialized || !this.context || !isString(LD_CLIENT_ID)) return

    try {
      this.client = LDClient.initialize(LD_CLIENT_ID, this.context, hash)
      await this.client.waitForInitialization()

      this.isInitialized = true
    } catch (e) {
      throw new Error('LD init error')
    }
  }

  /**
   * Get all features
   */
  getAllFeatures() {
    if (!this.client) return
    this.availableFeatures = this.client.allFlags()
  }

  /**
   * Sets the existing context
   * If it doesn't exist, initiate the LDClient with the given context
   * If it does exist, update the existing context
   *
   * Also fetches all the features for the context to make life easy
   */
  async setContext(context: Context) {
    try {
      this.createContext(context)
      if (!this.context) return

      const {
        data: { result },
      } = await Api.user.generateLDHash({
        context: this.context,
        orgId: context.org.id,
      })

      if (!this.isInitialized) {
        await this.initialize(result)
      } else {
        await this.client?.identify(this.context, result.hash)
      }

      this.getAllFeatures()
      this.emitToContextSubscribers()
    } catch (e) {
      console.error('LD set context error', e)
    }
  }

  /**
   * Check if a flag has value
   * NOTE: This is always checked in the context of the rootOrg, as we only define flags on root orgs
   *
   * @flagName - name of the flag to evaluate
   * @value    - value of flag
   *
   * @returns defaults to false if A) flag does not exist or B) client isn't initialized yet
   */
  evaluate(flagName: string, value: boolean | string | number) {
    // value returned by LD when FF doesn't exist.
    const DEFAULT_VALUE = false

    return this.client?.variation(flagName, DEFAULT_VALUE) === value
  }

  /**
   * Check if a flag is enabled (meaning it has a value)
   * NOTE: This is always checked in the context of the rootOrg, as we only define flags on root orgs
   * NOTE: when passing in an array of flags, only one needs to be enabled for this to return true
   *
   * @flagName - name of the flag(s) to evaluate
   *
   * @returns defaults to false if A) flag does not exist or B) client isn't initialized yet
   */
  isEnabled(flagName: string | string[]) {
    // value returned by LD when FF doesn't exist.
    const DEFAULT_VALUE = false

    if (Array.isArray(flagName)) return flagName.some(flag => !!this.client?.variation(flag, DEFAULT_VALUE))

    return !!this.client?.variation(flagName, DEFAULT_VALUE)
  }

  /**
   * If featureName return x, otherwise return y
   * Useful for situations where you need a fallback value
   * for falsy isEnabled responses
   *
   * Example code:
   * route.path = featureFlagService.getValueBasedOnFeatureStatus('some-flag-name', '/some-path-v2', '/some-path')
   */
  getValueBasedOnFeatureStatus<T>(featureName: string, x: T, y: T): T {
    return this.isEnabled(featureName) ? x : y
  }

  /**
   * Track an event, see documentation
   */
  track(key: string, data: unknown, metricValue: number) {
    return this.client?.track(key, data, metricValue)
  }

  // Subscriber callbacks will be called when contexts change
  subscribeToContext(callback: () => void) {
    this.contextSubscriptions.push(callback)
  }

  unsubscribeToContext(fn: () => void) {
    const index = this.contextSubscriptions.indexOf(fn)

    if (index !== -1) {
      this.contextSubscriptions.splice(index, 1)
    }
  }

  emitToContextSubscribers() {
    this.contextSubscriptions.forEach(fn => fn())
  }
}

export default new FeatureFlagService()
