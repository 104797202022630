export enum SUPPLIER_FOOTPRINT_ENTITY_STATUS {
  INGESTION_IN_PROGRESS = 'INGESTION_IN_PROGRESS',
  IN_PROGRESS = 'IN_PROGRESS',
  PUBLISHED = 'PUBLISHED',
}

export enum SUPPLIER_FOOTPRINT_ENTITY_TYPE {
  BASIC_CALC = 'BASIC_CALCULATION',
  SUPPLIER_SURVEY = 'SUPPLIER_SURVEY',
}
