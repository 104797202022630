import type { AxiosInstance } from 'axios'

import { replaceUriParams } from '../../DTO'

import {
  GET_DATASET_DELETION_TICKET_URI,
  type getDataSetDeletionTicketDTORes,
} from '../../DTO/intercom/get-dataset-deletion-ticket.dto'

export const useIntercomApi = (http: AxiosInstance) => ({
  getDataSetDeletionTicket(orgId: string, datasetId: string) {
    const requestURI = replaceUriParams(GET_DATASET_DELETION_TICKET_URI, {
      datasetId,
      orgId,
    })

    return http.get<getDataSetDeletionTicketDTORes>(requestURI, {
      headers: {
        'x-org-id': orgId,
      },
    })
  },
})
