// Get metabase SSO login URI
import { IsOptional } from 'class-validator'

import { ValidateDTO } from '..'

import { ANALYTICS_CONTROLLER } from './controller.common-vars'

export const ANALYTICS_LOGIN_SUBPATH = 'jwt-auth'
export const ANALYTICS_LOGIN_DASHBOARD_URI = `${ANALYTICS_CONTROLLER}/${ANALYTICS_LOGIN_SUBPATH}`

export class AnalyticsLoginDTOReq extends ValidateDTO {
  @IsOptional()
  redirectTo?: string
}

export type AnalyticsLoginDTORes = {
  uri: string
}
