import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { DATASOURCE_CONTROLLER, type DataContract } from './controller.common-vars'

export const DATACONTRACT_GET_SUBPATH = 'dataContracts/:dataSourceId'
export const DATACONTRACT_GET_URI = `${DATASOURCE_CONTROLLER}/${DATACONTRACT_GET_SUBPATH}`

export class GetDataContractDTOReq extends ValidateDTO {
  @IsUUID()
  dataSourceId!: string
}

export type GetDatacontractDTORes = {
  success: boolean
  result: DataContract
}
