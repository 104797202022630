<template>
  <div class="logo-container">
    <img
      class="logo-container__img"
      src="/altruistiq-logo-white.svg"
      alt="Altruistiq Logo"
      width="300px"
    />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
  .logo-container {
    position: absolute;
    z-index: 1;
    margin: 32px 0 0 32px;

    &__img {
      width: 100%;
    }
  }
</style>
