import type { AxiosInstance } from 'axios'

import type {
  CreateEmissionsTargetDTO,
  CreateEmissionsTargetDTORes,
} from '@lib/DTO/emissions-targets/create-emissions-target.dto'

import type { GetEmissionsTargetsDTORes } from '@lib/DTO/emissions-targets/get-emissions-targets.dto'

import type {
  UpdateEmissionsTargetDTOReq,
  UpdateEmissionsTargetDTORes,
} from '@lib/DTO/emissions-targets/update-emissions-target.dto'
import type { GetEmissionsTargetsGroupedDTORes } from '@lib/DTO/emissions-targets/get-emissions-targets-grouped.dto'
import type { UpdateEmissionsTargetStatusDTO } from '@lib/DTO/emissions-targets/update-emissions-target-status.dto'

const CONTROLLER_BASE_URL = '/emissions-targets'

export const useTargetsApi = (http: AxiosInstance) => ({
  fetchTargets(businessUnitId: string) {
    return http.get<GetEmissionsTargetsDTORes>(CONTROLLER_BASE_URL, {
      headers: {
        'x-org-id': businessUnitId,
      },
    })
  },

  fetchGroupedTargets(businessUnitId: string) {
    return http.get<GetEmissionsTargetsGroupedDTORes>(`${CONTROLLER_BASE_URL}/grouped`, {
      headers: {
        'x-org-id': businessUnitId,
      },
    })
  },

  createTarget(businessUnitId: string, payload: CreateEmissionsTargetDTO) {
    return http.post<CreateEmissionsTargetDTORes>(CONTROLLER_BASE_URL, payload, {
      headers: {
        'x-org-id': businessUnitId,
      },
    })
  },

  updateTarget(businessUnitId: string, targetId: string, payload: UpdateEmissionsTargetDTOReq) {
    return http.patch<UpdateEmissionsTargetDTORes>(`${CONTROLLER_BASE_URL}/${targetId}`, payload, {
      headers: {
        'x-org-id': businessUnitId,
      },
    })
  },

  updateTargetStatus(businessUnitId: string, targetId: string, payload: UpdateEmissionsTargetStatusDTO) {
    return http.patch<UpdateEmissionsTargetDTORes>(`${CONTROLLER_BASE_URL}/${targetId}/status`, payload, {
      headers: {
        'x-org-id': businessUnitId,
      },
    })
  },

  deleteTarget(businessUnitId: string, targetId: string) {
    return http.delete<{ success: boolean }>(`${CONTROLLER_BASE_URL}/${targetId}`, {
      headers: {
        'x-org-id': businessUnitId,
      },
    })
  },
})
