import { IsBoolean } from 'class-validator'

import { FRANCHISE_REQUESTS_CONTROLLER } from './franchise-requests.common-vars'

export class AddFranchiseRequestReviewDTO {
  @IsBoolean()
  isAccepted!: boolean
}

export const ADD_FRANCHISE_REQUEST_REVIEW_SUB_PATH = ':requestId/review'
export const ADD_FRANCHISE_REQUEST_REVIEW_PATH = `${FRANCHISE_REQUESTS_CONTROLLER}/${ADD_FRANCHISE_REQUEST_REVIEW_SUB_PATH}`
