// [GET] /initiatives/products

import { ValidateDTO } from '..'

import type { PaginationMeta } from '../generic/pagination-meta.dto'

import { INITIATIVE_CONTROLLER } from './controller.common-vars'

export const INITIATIVE_PRODUCTS_SUBPATH = 'products'
export const INITIATIVE_PRODUCTS_URI = `${INITIATIVE_CONTROLLER}/${INITIATIVE_PRODUCTS_SUBPATH}`

export class InitiativesProductsDTOReq extends ValidateDTO {
  selectedInputs!: string[]

  selectedProducts!: string[]

  getSelectedOnly!: boolean

  searchTerm?: string

  perPage?: number

  pageNo?: number
}

export type InitiativesProductItem = {
  name: string
  supplier: string

  // per kg view
  accuracyScore: number
  emissionsPerKg: number
  emissionUnit: string
}

export type InitiativesProductsDTORes = {
  inputs: InitiativesProductItem[]
  meta: PaginationMeta
}
