import PuzzlePieceIcon from '@components/icons/PuzzlePieceIcon.vue'
import ListIcon from '@components/icons/ListIcon.vue'
import GridIcon from '@components/icons/GridIcon.vue'
import FilesIcon from '@components/icons/FilesIcon.vue'
import TagIcon from '@components/icons/TagIcon.vue'
import AbacusIcon from '@components/icons/AbacusIcon.vue'

import type { NavGroup } from '@/imports/@types/Navigation'

export const getInternalUserNav = (): NavGroup[] => [
  {
    label: 'AQ Advisor',
    icon: PuzzlePieceIcon,
    children: [
      {
        label: 'Overview',
        to: {
          name: 'AQWelcome',
        },
      },

      {
        label: 'Tagging',
        to: {
          name: 'Tagging',
        },
      },

      {
        label: 'Your Calculations',
        to: {
          name: 'Calculations',
        },
      },
      {
        label: 'PDF Ingestion',
        to: {
          name: 'PDFIngestion',
        },
      },
      {
        label: 'PDF Ingestion - V2',
        to: {
          name: 'PDFPageGrouping',
        },
        featureFlag: 'rollout-pdf-v2',
      },

      {
        label: 'Methods',
        to: {
          name: 'CalculationMethods',
        },
      },

      {
        label: 'Emission Factors',
        to: {
          name: 'EmissionFactors',
        },
      },
    ],
  },
]

export const getAdvisorAppNav = (): NavGroup[] => [
  {
    label: 'Calculation Methods',
    icon: ListIcon,
    to: {
      name: 'CalculationMethods',
    },
  },
  {
    label: 'Emission Factors',
    icon: GridIcon,
    to: {
      name: 'EmissionFactors',
    },
  },
  {
    label: 'PDF Ingestion',
    icon: FilesIcon,
    to: {
      name: 'PDFIngestion',
    },
  },
  {
    label: 'PDF Ingestion - V2',
    icon: FilesIcon,
    to: {
      name: 'PDFPageGrouping',
    },
    featureFlag: 'rollout-pdf-v2',
  },
  {
    label: 'Tagging',
    icon: TagIcon,
    to: {
      name: 'Tagging',
    },
  },
  {
    label: 'Your Calculations',
    icon: AbacusIcon,
    to: {
      name: 'Calculations',
    },
  },
]
