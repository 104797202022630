<template>
  <div
    class="notification-bar mb-2"
    :class="notificationClass"
  >
    <slot name="content"></slot>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Notification',
    props: {
      variant: {
        type: String,
        default: 'danger',
      },
    },

    computed: {
      notificationClass() {
        return `notification-bar--${this.variant}`
      },
    },
  })
</script>

<style lang="scss">
  .notification-bar {
    border-radius: 6px;
    padding: 16px;

    &--danger {
      color: $red-dark;
      background-color: $red-light;
    }
    &--warning {
      color: $grey-1;
      background-color: $sahara;
    }
    &--info {
      color: $blue-dark;
      background-color: $blue-light;
    }
    &--success {
      color: $grey-8;
      background-color: $green-dark;
    }

    &--ghost {
      background-color: transparent;
      color: $grey-2;
      border: 1px solid $grey-5;
    }

    &--grey {
      background-color: $grey-6;
      color: $grey-1;
      border: 1px solid $grey-6;
    }

    &--amber {
      color: $amber-dark;
      background-color: $amber-light;
    }

    a {
      text-decoration: underline;
    }
  }
</style>
