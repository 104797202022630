// Delete initiative
// [DELETE] /initiatives/:initiativeUUID

// export const CONTROLLER = '/initiatives'
import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { INITIATIVE_CONTROLLER } from './controller.common-vars'

export const INITIATIVE_DELETE_SUBPATH = ':initiativeId'
export const INITIATIVE_DELETE_URI = `${INITIATIVE_CONTROLLER}/${INITIATIVE_DELETE_SUBPATH}`

export class InitiativeDeleteDTOReq extends ValidateDTO {
  @IsUUID()
  initiativeId!: string
}

export type InitiativeDeleteDTORes = void
