// Get static dashboard URI

import { ValidateDTO } from '..'

import { ANALYTICS_CONTROLLER, type AnalyticsIframeFormatting } from './controller.common-vars'

export const ANALYTICS_STATIC_SUBPATH = 'dashboard/:dashboardId'
export const ANALYTICS_STATIC_DASHBOARD_URI = `${ANALYTICS_CONTROLLER}/${ANALYTICS_STATIC_SUBPATH}`

export class AnalyticsStaticDashboardDTOReq extends ValidateDTO {
  dashboardId!: string

  formatting?: AnalyticsIframeFormatting
}

export type AnalyticsStaticDashboardDTORes = {
  uri: string
}
