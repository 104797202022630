import type { AxiosInstance } from 'axios'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'
import type { RecommendationStructure } from '@/imports/@types/recommendation/RecommendationStructure'

export type UpdateRecommendationPayload = {
  orgId: string
  recommendationId: string
  fields: {
    [key: string]: unknown
  }
}

/**
 * @deprecated
 * @param http
 */
export const useRecommendationsApi = (http: AxiosInstance) => ({
  /**
   * @deprecated
   * @param payload
   */
  get(payload: { orgId: string; recommendationId: string }) {
    return http.get<ApiResponseInner<RecommendationStructure>>('/recommendations/get', { params: payload })
  },

  /**
   * @deprecated
   * @param payload
   */
  update(payload: UpdateRecommendationPayload) {
    return http.patch<ApiResponseInner<undefined>>('/recommendations/update', payload)
  },
})
