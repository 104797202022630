import { IsNumber } from 'class-validator'

import { FRANCHISE_REQUESTS_CONTROLLER } from './franchise-requests.common-vars'

export class GetFranchiseRequestsProgressDTO {
  @IsNumber()
  year!: number
}

export const GET_FRANCHISE_REQUESTS_PROGRESS_SUB_PATH = 'progress'
export const GET_FRANCHISE_REQUESTS_PROGRESS_PATH = `${FRANCHISE_REQUESTS_CONTROLLER}/${GET_FRANCHISE_REQUESTS_PROGRESS_SUB_PATH}`

export type GetFranchiseRequestsProgressDTORes = {
  inProgress: number
  completed: number
  invitePending: number
}
