import { IsNumber, IsOptional, IsString } from 'class-validator'

import { FRANCHISES_CONTROLLER } from './franchises.common-vars'

export class GetUninvitedFranchisesDTO {
  @IsNumber()
  year?: number

  @IsOptional()
  @IsString()
  searchQuery?: string

  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number
}

export const GET_UNINVITED_FRANCHISES_SUB_PATH = 'uninvited'
export const GET_UNINVITED_FRANCHISES_PATH = `${FRANCHISES_CONTROLLER}/${GET_UNINVITED_FRANCHISES_SUB_PATH}`
