import type { Activity } from '@/imports/@types/activity/activity'
import type { EmissionDriver } from '@/imports/@types/EmissionDriver'

/**
 * Calculates the average accuracy score for an activity by averaging the scores of its sub activities
 * e.g. for Utilities, we have Electricity, Heating & Water
 * * For unit tests see: https://codesandbox.io/s/accuracy-scores-k4kuqf?file=/src/calculateActivityAccuracyScore.test.js
 *
 * TODO: Tidy up typings here to account for the union
 */
export const calculateAccuracyScore = (activities: Activity[] | EmissionDriver[] = []) => {
  const accuracyScores = activities.map(activity => activity.accuracyScore)

  if (accuracyScores.every(accuracyScore => typeof accuracyScore !== 'number')) return null

  const total = accuracyScores.reduce(
    (acc, accuracyScore) => (acc as number) + (accuracyScore === null ? 0 : accuracyScore),
    0,
  )

  return total ? total / activities.length : total
}
