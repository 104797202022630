import { IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { DATASOURCE_CONTROLLER } from './controller.common-vars'

export const GET_CUSTOM_CALCULATION_TEMPLATE_PATH = 'custom-calculation-template'

export const GET_CUSTOM_CALCULATION_TEMPLATE_URI = `${DATASOURCE_CONTROLLER}/${GET_CUSTOM_CALCULATION_TEMPLATE_PATH}`

export class GetCustomCalculationTemplateDTO extends ValidateDTO {
  @IsUUID()
  dataSourceId!: string
}
