import { defineStore } from 'pinia'

import { Api } from '@/imports/lib/services/api.service'

import type { Country } from '@/imports/@types/Country'
import type { SelectOption } from '@/imports/@types/SelectOption'
import { LIST_OF_REGIONS } from '@/imports/lib/constants/constants'

type State = {
  countries: Country[]
  loaders: {
    countries: boolean
    route: boolean
  }
  regions: string[]
}

export const useAppStore = defineStore('app', {
  state: (): State => ({
    countries: [],
    loaders: {
      countries: false,
      route: false,
    },
    regions: LIST_OF_REGIONS,
  }),

  getters: {
    countryOptions(): SelectOption<string>[] {
      return this.countries.map(({ code, name }) => ({ value: code, label: name }))
    },

    countryCodeFromCountryName(): (name: string) => string | undefined {
      return (name: string) => {
        return this.countryOptions.find(option => option.label === name)?.value
      }
    },

    countryNameFromCountryCode(): (code: string) => string | undefined {
      return (code: string) => {
        return this.countryOptions.find(option => option.value === code)?.label
      }
    },
  },

  actions: {
    async fetchCountriesList() {
      this.loaders.countries = true

      const {
        data: { result: countries },
      } = await Api.config.getCountries()

      this.countries = countries

      this.loaders.countries = false
    },
  },
})
