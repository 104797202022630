import { IsUUID } from 'class-validator'

import { FRANCHISE_REQUESTS_CONTROLLER } from './franchise-requests.common-vars'

export class AddFootprintToRequestDTO {
  @IsUUID()
  footprintId!: string
}

export const ADD_FOOTPRINT_TO_REQUEST_SUB_PATH = ':requestId/footprint'
export const ADD_FOOTPRINT_TO_REQUEST_PATH = `${FRANCHISE_REQUESTS_CONTROLLER}/${ADD_FOOTPRINT_TO_REQUEST_SUB_PATH}`
