/**
 * Formats a number based on the browser locale
 * @param number
 * @param decimalPlaces
 * @param forceDecimals Will ensure that decimal places are still shown on round numbers
 * @param isEstimated Will append double tilde to value if true
 * @returns {string}
 */
export const getLocaleNumber = (
  number: number,
  {
    decimalPlaces = 2,
    forceDecimals = false,
    isEstimated = false,
    approximateZeroValues = false,
    lowerBound = 0,
    upperBound = 0.09,
  } = {},
) => {
  if (approximateZeroValues && number > lowerBound && number < upperBound) return '~0'

  const toDecimalNum = Number((number || 0).toFixed(decimalPlaces))
  const browserLanguage = navigator.language
  const minMaxDPs = forceDecimals ? { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces } : {}

  let decimalNumber = toDecimalNum.toLocaleString(browserLanguage, minMaxDPs)

  if (isEstimated) decimalNumber = `≈${decimalNumber}`

  return decimalNumber
}
