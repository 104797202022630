import { isObject, isString } from 'lodash-es'

import { ROLE_KEYS } from '@/imports/lib/constants/permission/permissionConstants'

export type RoleAssignment = {
  role: ROLE_KEYS
  relation: string
  permissions?: string[]
}

export type User = {
  id: string
  isSuperUser: boolean
  userName: string
  email: string
  avatar: string | null
  roleAssignment: RoleAssignment
  auth?: {
    twoFactorEnabled: boolean
  }
  isDeveloperUser: boolean
  lastSelectedOrgId?: string
  hasTwoFactor?: boolean
}

export const isUser = (payload: unknown): payload is User =>
  isObject(payload) && 'id' in payload && isString(payload.id) && 'email' in payload && isString(payload.email)

export type OrganizationUser = {
  id: string
  avatar: string
  email: string
  userId: string
  orgId: string
  userName: string
  users: []
  roleAssignment: RoleAssignment
  role: ROLE_KEYS
}

export type OrganizationTeamMember = {
  avatar?: string
  email?: string
  role: ROLE_KEYS
  userId: string
  userName?: string
}
