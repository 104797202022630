import { defineStore } from 'pinia'

import { useOrganizationStore } from './organization.pinia'

import { Api } from '@/imports/lib/services/api.service'
import { env, isE2ETesting } from '@/client/helpers'
import { getAnalyticsProperties } from '@/imports/lib/analytics/getClientAnalyticsProperties'

import type { Session } from '@/imports/@types/Session'
import { isUser, type User } from '@/imports/@types/User'

import FeatureFlagService from '@/imports/lib/services/featureFlagService'
import { ROLE_KEYS, ROLE_PERMISSIONS } from '@/imports/lib/constants/permission/permissionConstants'
import { USER_ORG_RELATION } from '@/imports/@types/UserOrgRelation'

type State = {
  user: User
  organizationsAmount: number
  sessionToken: string
  intercomHash: string
}

export const useUserStore = defineStore('user', {
  state: (): State => ({
    user: {
      id: '',
      isSuperUser: false,
      userName: '',
      email: '',
      avatar: '',
      roleAssignment: {
        role: '',
        permissions: [],
        relation: USER_ORG_RELATION.INTERNAL,
      },
      auth: {
        twoFactorEnabled: false,
      },
      isDeveloperUser: false,
    },
    organizationsAmount: 1,
    sessionToken: '',
    intercomHash: '',
  }),

  getters: {
    id: state => state.user.id,
    userName: state => state.user.userName,
    avatar: state => state.user.avatar,
    isSuperUser: state => state.user.isSuperUser,
    isImpersonating: state => {
      const orgStore = useOrganizationStore()

      return state.user.isSuperUser && !orgStore.userRole
    },
    twoFactorEnabled: state => !!state.user.auth?.twoFactorEnabled,
    isDeveloperUser: state => state.user.isDeveloperUser,
    hasPermission:
      state =>
      (permission: string): boolean => {
        if (!state.user.roleAssignment.permissions) return false
        return state.user.roleAssignment.permissions.includes(permission)
      },
    hasRole:
      state =>
      (role: string | Array<string>): boolean => {
        if (Array.isArray(role)) {
          return role.some(r => state.user.roleAssignment.role === r)
        }

        return state.user.roleAssignment.role === role
      },
    hasFeature:
      () =>
      (flag: string): boolean =>
        FeatureFlagService.isEnabled(flag),
  },

  actions: {
    async login({ session }: { session: Session }): Promise<void> {
      const orgStore = useOrganizationStore()

      this.setSession({ session })

      if (session.role === ROLE_KEYS.NO_ACCESS) {
        window.location.replace('/request-access')
        return
      }

      await orgStore.getUserOrganizations()
      await orgStore.setActiveOrganizationById()
    },

    async logout() {
      await Api.user.logout()

      this.clearSession()

      const orgStore = useOrganizationStore()
      if (orgStore.rootOrgId) window.localStorage.setItem('orgId', orgStore.rootOrgId)
    },

    async loginWithTotpToken(totpToken: string, orgId?: string): Promise<void> {
      const {
        data: { result: sessionWithoutToken },
      } = await Api.user.loginWithTOTP({
        sessionToken: this.sessionToken,
        totpToken,
        orgId,
      })

      const sessionWithToken = Object.assign(sessionWithoutToken, { sessionToken: this.sessionToken })

      await this.login({ session: sessionWithToken })
    },

    async loginWithBackupCode(backupCode: string): Promise<void> {
      if (!backupCode) throw new Error('loginWithBackupCode: Backup code was undefined')

      const sessionToken = this.sessionToken

      const {
        data: { result: user },
      } = await Api.user.loginWithBackupCode({ sessionToken, backupCode })

      this.setUser(user)
    },

    async getUser(userId: string): Promise<User> {
      const {
        data: { result: user },
      } = await Api.user.getUser(userId)

      return user
    },

    async toggleIsSuperUser(userId: string): Promise<boolean | undefined> {
      const {
        data: { success },
      } = await Api.user.toggleIsSuperUser(userId)

      return success
    },

    async enableTwoFactor({ secret, totpToken }: { secret: string; totpToken: string }) {
      const sessionToken = this.sessionToken

      if (!sessionToken) throw new Error('user/enableTwoFactor: invalid session detected')

      if (!secret || !totpToken) {
        throw new Error('user/enableTwoFactor: Please provide a valid secret and totpToken')
      }

      const {
        data: { result },
      } = await Api.user.enableTwoFactor({ sessionToken, secret, totpToken })

      return result.twoFactorBackupCode
    },

    async resetTwoFactor({ backupCode, totpToken }: { backupCode: string; totpToken: string }) {
      if (!backupCode && !totpToken) {
        throw new Error('user/disableTwoFactor: Please provide a valid backupCode or totpToken')
      }

      if (totpToken) {
        return Api.user.resetTwoFactorByTotp(totpToken)
      }

      if (backupCode) {
        return Api.call('twoFactorAuth.resetByBackupCode', { backupCode, sessionToken: this.sessionToken })
      }
    },

    /**
     * Track a user event
     * For params see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
     *
     * NOTE: most events should be tracked on backend!
     *
     * @param {string} event      - event name, format "What happened"
     * @param {object} properties - properties
     */
    trackUserEvent({ event, properties, options }: { event: string; properties?: object; options?: unknown }) {
      if (isE2ETesting()) return

      if (env() !== 'production') {
        if (!FeatureFlagService.isEnabled('enable-track-analytics-in-non-production')) return
      }

      window.analytics?.track({
        event,
        properties: {
          ...properties,
          ...getAnalyticsProperties(),
        },
        options,
      })
    },

    /**
     * Track a page view
     * This is called by the router and in general you shouldn't explicitly call this
     */
    trackPage(category?: string, name?: string) {
      if (isE2ETesting()) return

      if (env() !== 'production') {
        if (!FeatureFlagService.isEnabled('enable-track-analytics-in-non-production')) return
      }

      window.analytics?.page(category, name, getAnalyticsProperties())
    },

    setSession({ session }: { session: Session }): void {
      window.localStorage.setItem('sessionToken', session.sessionToken)

      this.setUser(session.user)
      this.sessionToken = session.sessionToken
      this.intercomHash = session.intercomHash

      /**
       * This is a crucial call - the API needs to the auth token on
       * the Authorization header and when it's instantiated on a
       * non-authenticated client it doesn't have access to it.
       *
       * ANYWHERE that updates the session needs to call this
       */

      Api.updateAuthToken()
    },

    setUser(user: User): void {
      if (!isUser(user)) return

      this.user = user

      if (isE2ETesting()) return

      /**
       * Identify user in analytics
       */
      const { id, userName, email } = user

      window.analytics?.identify(id, {
        name: userName,
        email,
        env: env(),
      })
    },

    setSessionToken(token: string) {
      this.sessionToken = token
    },

    updateUserName(userName: string) {
      this.user.userName = userName
    },

    updateAvatar(avatar: string) {
      this.user.avatar = avatar
    },

    updateRole(role: ROLE_KEYS, relation: USER_ORG_RELATION) {
      const permissions = ROLE_PERMISSIONS[role]
      this.user.roleAssignment = { role, permissions, relation }
    },

    clearSession() {
      window.localStorage.removeItem('sessionToken')

      this.sessionToken = ''
    },

    updateLastSelectedOrganization(orgId: string) {
      this.user.lastSelectedOrgId = orgId
    },
  },
})
