// Update initiative
// [PATCH] /initiatives/:initiativeId/name

import { IsString, IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { INITIATIVE_CONTROLLER } from './controller.common-vars'

export const INITIATIVE_UPDATE_NAME_SUBPATH = ':initiativeId/name'
export const INITIATIVE_UPDATE_NAME_URI = `${INITIATIVE_CONTROLLER}/${INITIATIVE_UPDATE_NAME_SUBPATH}`

export class InitiativeUpdateNameDTOReq extends ValidateDTO {
  @IsUUID()
  initiativeId!: string

  @IsString()
  name!: string
}

export type InitiativeUpdateNameDTORes = void
