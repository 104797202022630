import type { AxiosInstance } from 'axios'

import type {
  CreateSupplierCalcFootprint,
  SupplierCalcFootprintCommon,
  SupplierCalcFootprintResponse,
  SupplierCalcFootprintsGetResponse,
  UpdateSupplierCalcFootprintResponse,
} from '@/imports/@types/supplierCalcFootprints/supplierCalcFootprints'

export const useSupplierCalcFootprintsApi = (http: AxiosInstance) => ({
  getSupplierCalcFootprint({ orgId, footprintId }: { orgId: string; footprintId: string }) {
    return http.get<SupplierCalcFootprintResponse>('/supplierCalcFootprint/get', {
      params: {
        orgId,
        footprintId,
      },
    })
  },

  getSupplierCalcFootprints({ orgId }: { orgId: string }) {
    return http.get<SupplierCalcFootprintsGetResponse>('/supplierCalcFootprint/getAll', {
      params: {
        orgId,
      },
    })
  },

  createSupplierCalcFootprint(payload: { orgId: string; requestId: string; from: string; to: string }) {
    return http.post<SupplierCalcFootprintCommon>('/supplierCalcFootprint/create', payload)
  },

  updateSupplierCalcFootprint(payload: {
    orgId: string
    footprintId: string
    supplierCalcFootprint: CreateSupplierCalcFootprint
    isDraft: boolean
  }) {
    return http.patch<UpdateSupplierCalcFootprintResponse>('/supplierCalcFootprint/update', payload)
  },

  shareCalcResults(payload: { orgId: string; footprintId: string; requesterId: string }) {
    return http.post<{ success: boolean }>('/supplierCalcFootprint/shareResults', payload)
  },
})
