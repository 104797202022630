/**
 * API methods relating to an org's franchisor(s). A franchisor is an organisation
 * that leases a franchise.
 */

import type { AxiosInstance } from 'axios'

import {
  GET_FRANCHISORS_PATH,
  GetFranchisorsDTO,
  type GetFranchisorsDtoRes,
} from '@lib/DTO/franchisors/get-franchisors.dto'

import {
  GET_FRANCHISORS_DATA_REQUESTS_PATH,
  type GetFranchisorsDataRequestsDTO,
  type GetFranchisorsDataRequestsDtoRes,
} from '@lib/DTO/franchisors/get-franchisor-requests.dto'

import { useOrganizationStore } from '@/client/store/organization.pinia'

export const useFranchisorsApi = (http: AxiosInstance) => ({
  getFranchisorRequests(payload: GetFranchisorsDataRequestsDTO) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetFranchisorsDataRequestsDtoRes>(GET_FRANCHISORS_DATA_REQUESTS_PATH, {
      params: payload,
      headers: {
        'x-org-id': orgId,
      },
    })
  },

  getFranchisors(payload: GetFranchisorsDTO = {}) {
    const orgId = useOrganizationStore().id || ''

    return http.get<GetFranchisorsDtoRes>(GET_FRANCHISORS_PATH, {
      params: payload,
      headers: {
        'x-org-id': orgId,
      },
    })
  },
})
