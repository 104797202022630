import type { EmissionsPerScope } from '@/imports/@types/EmissionsPerScope'
import type { GhgData } from '@/imports/@types/GhgData'
import type { Activity } from '@/imports/@types/activity/activity'

export interface GhgReport {
  data: GhgData[]
  emissionsPerScope: EmissionsPerScope
  totalEmissions: number
  isEstimated?: boolean
}

export interface GhgReportPerYear {
  [year: number]: GhgReport
  isEstimated?: boolean
}

export interface ActivityReport {
  activityArea: string
  activities: Activity[]
  emission: number
  percentageOfTotal: number
  isEstimated?: boolean
}

export interface Report {
  financialYear: number
  timePeriod: string
  data: {
    ghg: GhgReport
    activities: ActivityReport[]
    totalEmissions: number
  }
  userId: string
  orgId: string
  createdAt: string
  reportStartDate: string
  reportEndDate: string
  updatedAt: string
  id: string
}

export interface QuarterlyReport extends Report {
  timePeriodIndex?: number
}

export type ActivityReports = {
  [key: number]: ActivityReport[] | undefined
}

export function isActivityReport(payload: unknown): payload is ActivityReport[] {
  return Array.isArray(payload) && !Array.isArray(payload[0])
}

export type QuarterlyActivityReports = {
  [key: number]: ActivityReport[][] | undefined
}

export function isQuarterlyActivityReport(payload: unknown): payload is ActivityReport[][] {
  return Array.isArray(payload) && Array.isArray(payload[0])
}
