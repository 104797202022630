<template>
  <div class="route-loader"></div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'RouteLoader',
  })
</script>

<style lang="scss" scoped>
$module: "route-loader";

@keyframes loading {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

.#{$module} {
  height: 4px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $kelp-dark;
    animation: loading 5s ease forwards;
    transform-origin: left;
  }
}
</style>
