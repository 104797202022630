import type { BusinessUnit } from '@/imports/@types/organizationStructure/OrganizationStructure'

/**
 *
 * Recursive function that adds new properties and values to every node on a tree
 *
 * @param { array } tree            - The tree to operate
 * @param { string } children       - The name of the child array
 * @param { array } flags           - An array of filter flags; each flag has a property name and default value
 *
 * @returns { array }               - The updated array
 *
 * Use case: legal entities and business units. Default filter flags are set against each node to simplify display logic.
 * These flags are used when a filter is applied to e.g. hide a node or reduce its opacity.
 *
 */
export const setTreeProperties = (tree, children, flags) => {
  const filterTree = subtree => {
    /**
     * Iterate over the array, and set the (default) value for each item
     */

    subtree.forEach(node => {
      flags.forEach(flag => {
        node[flag.property] = flag.defaultValue
      })

      /**
       * Add a flag indicating whether any sibling of
       * an item has children
       */
      node.hasSiblingChildren = doesAnySiblingHaveChildren(subtree)

      /**
       * Repeat for children of each item
       */

      if (node[children]) {
        filterTree(node[children])
      }
    })
  }

  filterTree(tree)

  return tree
}

/**
 * Helper method for intialising setTreeProperties with default flags
 *
 * @param { array } structure - The tree to operate
 * @param { string } children - The name of the child array e.g. subunits or legalEntities
 *
 */
export const setDefaultTreeFilterStates = (structure, children) => {
  setTreeProperties(structure, children, [
    {
      property: 'isMatch',
      defaultValue: true,
    },
    {
      property: 'isVisible',
      defaultValue: true,
    },
  ])
}

/**
 *
 * Recursive function that returns nodes that match a given value of a property
 *
 * @param { array } tree                    - A tree
 * @param { string } property               - The property to check against
 * @param { string|boolean } propertyValue  - The value of the property that must be matched
 * @param { string } children               - The array of matched nodes
 *
 * @returns { array } matchedNodes          - The array of matched nodes
 *
 */
export const findNodesByPropertyValue = (tree, property, propertyValue, children) => {
  const matchedNodes = []

  const findMatches = subtree => {
    subtree.forEach(node => {
      if (node[property] && !!propertyValue && node[property] === propertyValue) {
        matchedNodes.push(node)
      }

      if (node[children]?.length > 0) {
        findMatches(node[children])
      }
    })
  }

  findMatches(tree)

  return matchedNodes
}

/**
 *
 * Recursive function that sets property values (flags) on every node on a tree
 *
 * @param { array } tree            - The tree to operate on
 * @param { string } filter         - The property to filter on
 * @param { string } filterValue    - The property value to match
 * @param { string } children       - The name of the child property array
 *
 * @returns { array }               - The same array with flags
 *
 * * Use case: filtering legal entities or business units. Every node is matched against a filter.
 */
export const setFilterFlags = (tree, flag, filterName, filterValue, children) => {
  const filterTree = subtree => {
    /**
     * Iterate over the array, setting the value of the flag to true if
     * The value of the flag is already true, and the value of filter is matched
     */

    subtree.forEach(node => {
      node[flag] = node[flag] && node[filterName] === filterValue

      /**
       * Repeat for children of each item
       */

      if (node[children]) {
        filterTree(node[children])
      }
    })
  }

  filterTree(tree)

  return tree
}

/**
 *
 * Recursive function that sets a true or false flag on every node where a property starts with a given string
 *
 * @param { array } tree              - The tree to operate on
 * @param { string } propertyToMatch  - The property to match
 * @param { string } strToMatch       - The value to match
 * @param { string } children         - The name of the child property array
 *
 * @returns { array }                 - The same array with flags
 *
 * * Use case: filtering legal entities or business units depending on how their name starts
 */
export const setDoesStartWithFilterFlag = (tree, flag, propertyToMatch, strToMatch, children) => {
  const filterTree = subtree => {
    subtree.forEach(node => {
      /**
       * Iterate over the array, setting the value of the flag to true if
       * The value of the flag is already true, and the start of a selected property matches the string
       */

      node[flag] = node[flag] && node[propertyToMatch].toLowerCase().startsWith(strToMatch.toLowerCase())

      /**
       * Repeat for children of each item
       */

      if (node[children]) {
        filterTree(node[children])
      }
    })
  }

  filterTree(tree)

  return tree
}

/**
 *
 * Recursive function that sets the isVisible flag to true on a parent where:
 * The parent does not match the filter
 * None of its children match the filter
 *
 * @param { array } tree
 * @param { array } children
 *
 * Use case: in the legal entity and business unit lists, hide LEs and BUs when a parent and their children that don't match the filter
 *
 */

export const setVisibilityFlags = (tree, children) => {
  let visibilityCount = 0

  const filterTree = subtree => {
    /**
     * Iterate over each item to check whether it has any children matching the filter
     * If it does, it should be visible
     */

    subtree.forEach(node => {
      node.isVisible =
        node.isMatch === true ||
        (node[children] ? findNodesByPropertyValue(node[children], 'isMatch', true, children).length > 0 : false)

      if (node.isVisible) visibilityCount++

      /**
       * Repeat for children of each item
       */

      if (node[children]) {
        filterTree(node[children])
      }
    })
  }

  filterTree(tree)

  return { tree, visibilityCount }
}

/**
 * Finds the immediate parent of a child, and converts the value into a viable id value
 *
 * @param { string } path - A dot separated list of ids, with the current item last (on the right), and its parents in order up the tree
 * e.g. path: "XXXXXXXX0be943c58e704fff5da3f675.XXXXXXXXe6a94be4a002c80a4e4b82e2.33bb09273b1f401d9b10e4e083f99865"
 *
 * @returns { string }
 */

export const findAChildsImmediateParentIdFromPath = path => {
  const ids = path.split('.')

  return ids.length === 1 ? null : ids.at(-2).replace(/(^.{8})(.{4})(.{4})(.{4})(.{12}$)/, '$1-$2-$3-$4-$5')
}

/**
 * Checks whether any item at the same level in a tree (the subtree) has children,
 * returns true if any sibling has children
 *
 * @param { array } subtree - The tree to operate on
 *
 * * @returns { boolean }
 */

export const doesAnySiblingHaveChildren = subtree => {
  /**
   * If any sibling is a not a leaf i.e. it has children, the total will be > 0
   * and when cast to a boolean will be true
   */
  const total = subtree.reduce((previousValue, currentValue) => previousValue + Number(!currentValue.isLeaf), 0)
  return Boolean(total)
}

/**
 *
 * @param { string } name   - The name of the business unit
 *
 * @returns { string }      - The abbreviated name of the business unit (first name of first 2 words, or first two letters of word if there is only one)
 */
export const abbreviateName = (name: string) => {
  if (!name) return ''

  const names = name.replace(/[^a-zA-Z ]/g, '').split(' ')

  return names.length === 1
    ? names[0].slice(0, 2).toUpperCase()
    : names[0].slice(0, 1).toUpperCase() + names[1].slice(0, 1).toUpperCase()
}

export const isBusinessUnitDeletable = (businessUnit: BusinessUnit) =>
  !businessUnit.subunits?.length && !businessUnit.facilitiesCount
