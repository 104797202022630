import moment from 'moment'

import type { PeriodData } from '@/imports/@types/PeriodData'
import type { ActivityReport } from '@/imports/@types/Report'

// Get correct state for year and quartes
export const existingActivitiesStatus = (report: ActivityReport[], year: string): PeriodData[] => {
  const periodData = []
  const yearParts = [parseFloat(year) === moment().utc().year() ? 'FYTD' : 'FY', year]

  // Defaults
  let isEstimated = false
  let type = 'complete'

  const estimatedReportCount = report.filter(r => r.isEstimated).length

  if (estimatedReportCount === report.length) {
    // If all activities in the report are estimated, show as estimated
    yearParts.push('(estimated)')
    isEstimated = true
    type = 'estimated'
  } else if (estimatedReportCount > 0) {
    // If any, but not all activities are estimated, show as in refinement
    yearParts.push('(in refinement)')
    isEstimated = true
    type = 'inRefinement'
  }

  const periodDataObj = {
    year: parseFloat(year),
    title: yearParts.join(' '),
    isEstimated,
    type,
  }

  periodData.push(periodDataObj)

  return periodData
}
