import type { AxiosInstance } from 'axios'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'
import type { SupplierSurvey, SupplierSurveyAnswers } from '@/imports/@types/SupplierSurvey'

type GetSupplierSurveyPayload = {
  orgId: string
  surveyId: string
}

export interface MarkAsCompletePayload extends SupplierSurveyAnswers {
  surveyId: string
  orgId: string
  surveyType: string
}

export interface SaveAnswersPayload extends MarkAsCompletePayload {
  surveyId: string
  orgId: string
  surveyType: string
}

export const useSupplierApi = (http: AxiosInstance) => ({
  getSupplierSurvey(params: GetSupplierSurveyPayload) {
    return http.get<ApiResponseInner<{ survey: SupplierSurvey; currentAnswers: SupplierSurveyAnswers }>>(
      'suppliersurvey/get',
      {
        params,
      },
    )
  },

  getForOrganization(orgId: string) {
    return http.get<ApiResponseInner<SupplierSurvey[]>>('supplierSurvey/getForOrganization', {
      params: {
        orgId,
      },
    })
  },

  markAsComplete(payload: MarkAsCompletePayload) {
    return http.post<ApiResponseInner<unknown>>('supplierSurvey/markascomplete', payload)
  },

  saveAnswers(payload: SaveAnswersPayload) {
    return http.post<ApiResponseInner<unknown>>('supplierSurvey/saveanswers', payload)
  },
})
