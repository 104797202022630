import { IsEnum, IsNumber, IsOptional, IsString } from 'class-validator'

import { GET_RESPONDED_FRANCHISES_SORT_FIELD } from '../../../@enums/franchises.enums'

import { SORT_ORDER } from '../../../@enums/common.enums'

import { FRANCHISES_CONTROLLER } from './franchises.common-vars'

import type { IFranchiseWithCompletedRequest } from '@/imports/@types/franchises.types'

export class GetCompletedFranchiseRequestsDTO {
  @IsNumber()
  year!: number

  @IsOptional()
  @IsEnum(SORT_ORDER)
  sortOrder?: SORT_ORDER

  @IsOptional()
  @IsEnum(GET_RESPONDED_FRANCHISES_SORT_FIELD)
  sortField?: GET_RESPONDED_FRANCHISES_SORT_FIELD

  @IsOptional()
  @IsString()
  searchQuery?: string

  @IsOptional()
  @IsString()
  scope?: string

  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number
}

export const GET_RESPONDED_FRANCHISES_SUB_PATH = 'responded'
export const GET_RESPONDED_FRANCHISES_PATH = `${FRANCHISES_CONTROLLER}/${GET_RESPONDED_FRANCHISES_SUB_PATH}`

export type GetCompletedFranchiseRequestsDTORes = {
  franchises: IFranchiseWithCompletedRequest[]
  meta: {
    totalCount: number
    pageNo: number
    perPage: number
  }
}
