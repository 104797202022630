import type { IFranchiseRequestForSurvey } from '../../../@types/franchises.types'

import { FRANCHISE_REQUESTS_CONTROLLER } from './franchise-requests.common-vars'

export type GetFranchiseRequestDTORes = {
  franchiseRequest: IFranchiseRequestForSurvey | null
}

export const GET_FRANCHISE_REQUEST_SUB_PATH = ':id'
export const GET_FRANCHISE_REQUEST_PATH = `${FRANCHISE_REQUESTS_CONTROLLER}/${GET_FRANCHISE_REQUEST_SUB_PATH}`
