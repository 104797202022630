export const EMISSION_FACTORS_FILTER_ICONS: { [key: string]: string | undefined } = {
  calcMethod: 'flask',
  specificity: 'globe',
  lifecycle: 'circle-dashed',
  source: 'bracket-curly',
  geography: 'location-dot',
  year: 'calendar',
  users: 'user',
}

// object which will format filter keys to params
export const EF_FILTER_KEYS = {
  calcMethod: 'calcMethodId',
  specificity: 'specificityId',
  lifecycle: 'lifecycleId',
  source: 'sourceId',
  geography: 'geographyCode',
  year: 'year',
}

export const EF_CALC_METHOD_PRIORITY_DESC = {
  1: {
    title: 'Preferred option',
    description: 'With this option, the calculations will be most accurate.',
  },

  2: {
    title: 'Second-preferred option',
    description: 'If you cannot meet the data requirements for option 1, this is the next best choice.',
  },

  3: {
    title: 'Least-preferred options',
    description: 'Options that are the least accurate for this activity, but might be easier to calculate.',
  },
}
