import { IsArray, IsEnum, IsNumber, IsOptional, IsString } from 'class-validator'

import { FRANCHISE_STATUS } from '../../../../imports/@enums/franchises.enums'

import { FRANCHISORS_CONTROLLER } from './franchisors.common-vars'

import type { IFranchisorRequest } from '@/imports/@types/franchises.types'

export class GetFranchisorsDataRequestsDTO {
  @IsOptional()
  @IsString()
  searchQuery?: string

  @IsOptional()
  @IsNumber()
  pageNo?: number

  @IsOptional()
  @IsNumber()
  perPage?: number

  @IsOptional()
  @IsArray()
  @IsEnum(FRANCHISE_STATUS, { each: true })
  statuses?: FRANCHISE_STATUS[]
}

export const GET_FRANCHISORS_DATA_REQUESTS_SUB_PATH = 'data-requests'
export const GET_FRANCHISORS_DATA_REQUESTS_PATH = `${FRANCHISORS_CONTROLLER}/${GET_FRANCHISORS_DATA_REQUESTS_SUB_PATH}`

export type GetFranchisorsDataRequestsDtoRes = {
  franchisors: IFranchisorRequest[]
  meta: {
    totalCount: number
    pageNo: number
    perPage: number
  }
}
