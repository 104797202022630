export const INITIATIVE_CONTROLLER = 'initiatives'

export enum EMISSION_FACTOR_SOURCE {
  SUPPLIER_PROVIDED = 'SUPPLIER_PROVIDED',
  ESTIMATION = 'ESTIMATION',
  OTHER = 'OTHER',
}

export type ProductListItem = {
  productId: string
  name: string
  isSelected: boolean
  emissionFactor: number
  emissionsFactorUnit: string
}

export type InputItem = {
  inputUUID?: string
  name: string
  emissionFactor: number
  supplier: string
  accuracyScore?: number
}

export type IngoingInputItem = InputItem & {
  replaceFactor: number
  isCustomInput: boolean
  source?: EMISSION_FACTOR_SOURCE
  sourceLink?: string
}

export type OutgoingInputItem = InputItem & {
  productCount: number
}

export type InitiativeResponseObject = {
  initiativeId: string
  buName: string
  upToDate: boolean
  name: string
  outgoingInputs: OutgoingInputItem[]

  ingoingInputs: IngoingInputItem[]
  products: ProductListItem[]

  searchDateStart: string
  searchDateEnd: string

  currentEmissionsPerKg: number
  currentEmissionsVsLastYear: number
  calculatedEmissionsPerKg: number
  calculatedEmissionsVsLastYear: number
  emissionsChangePerKgFactor: number // (currentEmissionsPerKG - calculatedEmissionsPerKG) / currentEmissionsPerKG
  emissionsChangeVsLastYearFactor: number // (currentEmissionsVsLastYear - calculatedEmissionsVsLastYear) / currentEmissionsVsLastYear

  createdAt: string
}
