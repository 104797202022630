export enum ORGANIZATION_INTERNAL_TYPES {
  CUSTOMER = 'customer',
  TEST = 'test',
  DEMO = 'demo',
}

export enum ORGANIZATION_MODULES {
  PRODUCT_FOOTPRINT = 'productFootprint',
  SUPPLIER_ENGAGEMENT = 'supplierEngagement',
  PORTFOLIO_ENGAGEMENT = 'portfolioEngagement',
}

export enum ORGANIZATION_CLIENT_TYPES {
  BASIC = 'supplier',
  PRO = 'full',
  ENTERPRISE = 'self',
}
