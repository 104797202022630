import VueClipboard from 'vue3-clipboard'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import VueApexCharts from 'vue3-apexcharts'

export const initVuePlugins = app => {
  app.use(VueClipboard)
  app.use(VueApexCharts)
  app.use(VueTippy, {
    directive: 'tippy',
    component: 'tippy',
    componentSingleton: 'tippy-singleton',
    defaultProps: {
      placement: 'top',
      allowHTML: true,
      interactive: true,
    },
  })
}
