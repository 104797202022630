import type { Ref } from 'vue'
import { onMounted, onBeforeUnmount, ref } from 'vue'

/**
 * List keyboard navigation
 */

export const useListKeyboardNavigation = (
  parent: Ref<HTMLElement | null>,
  open: Ref<boolean>,
  callback: (currentIndex: number) => void,
) => {
  const keyboardNavKeys = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Enter', 'Escape']

  // set initial -1 so the first item with arrow down key will be selected with index 0
  const currentIndex = ref(-1)

  const scrollOption: ScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'nearest',
    inline: 'start',
  }

  // Scroll when selected item is outside overflow
  const fixScrolling = (children: NodeListOf<HTMLElement>, indexRef: Ref<number>) => {
    children[indexRef.value]?.scrollIntoView(scrollOption)
  }

  // Keep hovering until it reach max length of the list
  const arrowDown = (childrenLength: number) => {
    if (currentIndex.value < childrenLength) {
      currentIndex.value += 1
    }
  }

  // Keep hovering until it reach list top limit
  const arrowUp = () => {
    if (currentIndex.value > 0) {
      currentIndex.value -= 1
    }
  }

  const onKey = async (event: KeyboardEvent) => {
    if (!(parent.value instanceof HTMLElement)) return

    const key = event.key
    const children = parent.value.querySelectorAll('li')

    if (!open.value || !keyboardNavKeys.includes(key)) return

    if (key === 'ArrowDown') arrowDown(children.length)

    if (key === 'ArrowUp') arrowUp()

    fixScrolling(children, currentIndex)

    // Remove class from previous index and add selected class for current one
    if (currentIndex.value >= 0) {
      const direction = key === 'ArrowDown' ? currentIndex.value - 1 : currentIndex.value + 1

      children[direction]?.classList.remove('selected')
    }

    children[currentIndex.value]?.classList.add('selected')

    if (key === 'Enter') {
      callback(currentIndex.value)

      // reset current index
      currentIndex.value = -1
    }
  }

  onMounted(async () => {
    window.addEventListener('keydown', onKey)
  })

  onBeforeUnmount(async () => {
    window.removeEventListener('keydown', onKey)
  })
}
