import { pullIntendedUrlPath } from '@lib/utilities/urlRedirector'

import FeatureFlagService from '@lib/services/featureFlagService'

import { ACTION } from '@lib/constants/permission/permissionConstants'

import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { updateActiveTarget } from '@lib/utilities/routeUtilities'

import {
  checkForAdminRole,
  checkForAdvisorRole,
  checkForEnabledFeatures,
  checkForAllEnabledFeatures,
  checkForOrgModuleEnabled,
  checkForPDFToken,
  checkForSuperuserRole,
  checkForUserRole,
  checkIfBasic,
  checkIfSupplier,
  checkIsActiveOrgRootOrg,
  hasPermissions,
} from '@/imports/lib/utilities/routeGuards'
import { ORGANIZATION_MODULES } from '@/imports/@enums/organizations.enums'

/**
 * WARNING, DO NOT CHANGE dbKey values!
 * Add dbKey and title to meta to make the page available in the organization configuration
 * When changing, also needs updating in constants.js
 *
 * NOTE: make sure to add public: true if the route is publicly accessible or session management will break!
 * NOTE: add 'dontUseAsIntendedPath' if you need to exclude a user from being redirected to that route after login,
 *   useful for getting out of infinite loops in the authentication stages (see 2fa)
 */
export default [
  /**
   * Public routes
   */
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'split-view', public: true },
    component: () => import('@pages/user/Login.vue'),
    children: [
      {
        path: 'set-organization',
        name: 'setOrgToLoginTo',
        component: () => import('@pages/user/SetOrgToLoginTo.vue'),
      },
      {
        path: 'credentials',
        name: 'credentials',
        component: () => import('@pages/user/LoginWithCredentials.vue'),
      },
      {
        path: 'saml',
        name: 'saml',
        component: () => import('@pages/user/LoginWithSamlOkta.vue'),
      },
      {
        path: 'saml-azure-ad',
        name: 'samlAzureAD',
        component: () => import('@pages/user/LoginWithSamlAzure.vue'),
      },
      {
        path: 'forgot-password',
        name: 'forgotPassword',
        component: () => import('@pages/user/ForgotPassword.vue'),
      },
    ],
  },
  {
    path: '/reset-password', // adding this route as safeguard
    name: 'resetPassword',
    meta: { layout: 'no-sidebar-or-navbar', public: true },
    component: () => import('@pages/user/ResetPassword.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'resetPassword',
    meta: { layout: 'no-sidebar-or-navbar', public: true },
    component: () => import('@pages/user/ResetPassword.vue'),
  },
  {
    path: '/invitation', // adding this route as safeguard
    name: 'acceptInvite',
    meta: { layout: 'split-view', public: true },
    component: () => import('@pages/user/AcceptInvite.vue'),
  },
  {
    path: '/invitation/:token',
    name: 'acceptInvite',
    meta: { layout: 'split-view', public: true },
    component: () => import('@pages/user/AcceptInvite.vue'),
  },
  {
    path: '/saml/success/:token',
    name: 'samlSessionWaitingRoom',
    meta: { layout: 'no-sidebar-or-navbar', public: true },
    component: () => import('@pages/user/SamlSessionWaitingRoom.vue'),
  },
  {
    path: '/saml/error/',
    name: 'samlError',
    meta: { layout: 'no-sidebar-or-navbar', public: true },
    component: () => import('@pages/user/SamlError.vue'),
  },
  {
    path: '/pdf/:reportType/:orgId/:year',
    name: 'pdfReport',
    meta: {
      layout: 'no-sidebar-or-navbar',
      public: true,
      category: 'Reports',
    },
    component: () => import('@pages/pdfReport/pdfReports.vue'),
    beforeEnter: checkForPDFToken,
  },
  {
    path: '/pdf/:reportType/:orgId/:customerId/:year',
    name: 'customerReport',
    meta: {
      layout: 'pdf',
      public: true,
      category: 'Reports',
    },
    component: () => import('@pages/pdfReport/pdfReports.vue'),
    beforeEnter: checkForPDFToken,
  },
  {
    path: '/customers/generate-report',
    name: 'generateCustomerReport',
    meta: {
      layout: 'no-sidebar-or-navbar',
      public: false,
      category: 'Reports',
    },
    component: () => import('@pages/customers/GenerateCustomerReport.vue'),
    beforeEnter: [checkForAdminRole],
  },

  /**
   * User routes
   */
  {
    path: '/',
    name: 'index',
    beforeEnter: [checkForUserRole],
    redirect: () => {
      const intendedPath = pullIntendedUrlPath()

      if (intendedPath) {
        return { path: intendedPath }
      }

      return { name: 'dashboard' }
    },
  },
  {
    path: '/measure/:breakdown?',
    name: 'measure',
    component: () => import('@pages/measure/Measure.vue'),
    beforeEnter: [
      hasPermissions([
        ACTION.VIEW_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
        ACTION.DOWNLOAD_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
      ]),
      checkForUserRole,
    ],
    meta: {
      pageTitle: 'Overview',
      category: 'Analyse',
    },
  },
  {
    path: '/measure/intensity-metrics',
    name: 'intensityMetrics',
    beforeEnter: [hasPermissions([ACTION.CREATE_INTENSITY_METRICS, ACTION.UPDATE_INTENSITY_METRICS]), checkForUserRole],
    component: () => import('@pages/measure/IntensityMetrics.vue'),
    meta: {
      disableYearSelector: true,
      pageTitle: 'Intensity metrics',
      category: 'Manage Data',
    },
  },
  {
    path: '/manage',
    name: 'manage',
    redirect: { name: 'Targets overview' },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@pages/dashboard/Dashboard.vue'),
    meta: {
      category: 'Dashboard',
    },
    beforeEnter: [checkForEnabledFeatures],
  },
  {
    path: '/suppliers/products',
    name: 'supplierProducts',
    meta: {
      requiredFeatureFlags: ['enable_supplier_product_footprint'],
      pageTitle: 'Supplier products',
      category: 'Manage Data',
    },
    component: () => {
      if (!FeatureFlagService.isEnabled('rollout-revised-product-emissions-factor-wizard')) {
        return import('@pages/supplierProducts/SupplierProductsPage.deprecated.vue')
      }

      return import('@pages/supplierProducts/SupplierProductsPage.vue')
    },
    beforeEnter: [checkForEnabledFeatures],
  },
  {
    path: '/suppliers/products/:id',
    name: 'supplierProductFootprint',
    meta: {
      layout: 'empty',
      requiredFeatureFlags: ['rollout-revised-product-emissions-factor-wizard', 'enable_supplier_product_footprint'],
      pageTitle: 'Product footprint',
      category: 'Manage data',
    },
    component: () => import('@pages/supplierProducts/CreateProductEmissionFactor/CreateProductEmissionFactor.vue'),
    beforeEnter: [checkForAllEnabledFeatures],
  },
  {
    path: '/suppliers/products/create/:id?',
    name: 'createSupplierProduct',
    meta: {
      layout: 'empty',
      requiredFeatureFlags: ['enable_supplier_product_footprint'],
      pageTitle: 'Create supplier product',
      category: 'Manage Data',
    },
    component: () => import('@pages/supplierProducts/CreateSupplierProduct.vue'),
    beforeEnter: [checkForEnabledFeatures],
  },
  {
    path: '/suppliers/footprints',
    name: 'supplierFootprints',
    meta: {
      requiredFeatureFlags: ['enable_supplier_footprints'],
      category: 'Manage Data',
    },
    component: () => import('@pages/supplierFootprints/SupplierFootprints.vue'),
    beforeEnter: [checkForEnabledFeatures, checkIfSupplier],
  },
  {
    path: '/suppliers/footprints/create/:id?',
    name: 'createSupplierFootprint',
    meta: {
      layout: 'empty',
      requiredFeatureFlags: ['enable_supplier_footprints'],
      category: 'Manage Data',
    },
    component: () => import('@pages/supplierFootprints/CreateSupplierFootprint.vue'),
    beforeEnter: [checkForEnabledFeatures, checkIfSupplier],
  },
  {
    path: '/suppliers/data-requests',
    name: 'supplierDataRequests',
    meta: {
      requiredFeatureFlags: ['enable-supplier-engagement', 'enable-franchise-engagement'],
      category: 'Engage Suppliers',
    },
    component: () => import('@pages/supplierDataRequests/SupplierDataRequestsPage.vue'),
    redirect: { name: 'supplierDataRequestsStats' },
    beforeEnter: [checkForEnabledFeatures],
    children: [
      {
        path: '',
        name: 'supplierDataRequestsStats',
        component: () =>
          import('@pages/supplierDataRequests/pages/SupplierDataRequestStats/SupplierDataRequestsStats.vue'),
        children: [
          {
            path: 'suppliers',
            name: 'suppliersDataRequestTab',
            component: () => import('@components/FranchiseEngagement/SuppliersTab/SuppliersTab.vue'),
          },
          {
            path: 'franchises',
            name: 'franchisesDataRequestTab',
            component: () => import('@components/FranchiseEngagement/FranchiseTab/FranchisesTab.vue'),
            meta: {
              requiredFeatureFlags: ['enable-franchise-engagement'],
            },
            beforeEnter: [checkForEnabledFeatures],
          },
        ],
      },
      {
        path: 'annual-report',
        name: 'annualReport',
        component: () => import('@pages/supplierDataRequests/pages/annual/AnnualSupplierRequestsReport.vue'),
        children: [
          {
            path: '',
            name: 'annualInvitedSuppliers',
            component: () => import('@pages/supplierDataRequests/pages/annual/InvitedSuppliersTab.vue'),
          },
          {
            path: 'result',
            name: 'annualAnalyseResult',
            component: () => import('@pages/supplierDataRequests/pages/annual/AnalyseResultTab.vue'),
          },
        ],
      },
      {
        path: 'product-report',
        name: 'productReport',
        component: () => import('@pages/supplierDataRequests/pages/product/ProductSupplierRequestsReport.vue'),
        children: [
          {
            path: '',
            name: 'productFootprintRequests',
            component: () => import('@pages/supplierDataRequests/pages/product/ProductFootprintRequestsTab.vue'),
          },
          {
            path: 'result',
            name: 'productAnalyseResult',
            component: () => import('@pages/supplierDataRequests/pages/product/ProductAnalyseResultsTab.vue'),
          },
        ],
      },
      {
        path: 'invite',
        name: 'requestSupplierData',
        component: () => import('@pages/supplierDataRequests/SupplierRequestDataPage.vue'),
        meta: {
          layout: 'empty',
          pageTitle: 'Invite suppliers',
        },
      },
      {
        path: 'franchise-corporate-report-requests',
        name: 'franchiseCorporateRequestsTabs',
        meta: {
          requiredFeatureFlags: ['enable-franchise-engagement'],
        },
        beforeEnter: [hasPermissions(ACTION.VIEW_FRANCHISE_REQUEST)],
        component: () =>
          import('@pages/engagePartners/dataRequests/franchises/corporateRequests/FranchiseCorporateRequests.vue'),
        children: [
          {
            path: '',
            name: 'franchiseCorporateFootprintDataRequests',
            component: () =>
              import(
                '@pages/engagePartners/dataRequests/franchises/corporateRequests/tabs/dataRequests/FranchiseCorporateDataRequestsTab.vue'
              ),
          },
          {
            path: 'analysis',
            name: 'franchiseCorporateFootprintAnalysis',
            component: () =>
              import(
                '@pages/engagePartners/dataRequests/franchises/corporateRequests/tabs/analysis/FranchiseCorporateDataAnalysisTab.vue'
              ),
          },
        ],
      },
    ],
  },
  /**
   * We are transitioning to partner engagement nomenclature. This route
   * needs to be outside of the supplier children scope because it is
   * a full screen layout. It is therefore a good candidate for beginning
   * that transition. This inconsistency will be temporary.
   */
  {
    path: '/partner-engagement/franchise-corporate-report-bulk-requests',
    name: 'franchiseCorporateReportBulkRequests',
    meta: {
      requiredFeatureFlags: ['enable-franchise-engagement'],
      layout: 'EmptyLayout',
    },
    beforeEnter: [hasPermissions(ACTION.SEND_FRANCHISE_INVITE)],
    component: () => import('@pages/FranchiseEngagement/BulkRequestWizard/BulkRequestWizard.vue'),
  },
  /**
   * Franchisor engagement refers to the relationship between a franchis_EE
   * and a franchis_OR from the perspective of the franchisee. So I own a mcdonald's
   * franchise and want to see the requests that mcdonald's head office has sent me,
   * I'd go to these pages.
   *
   * This means they are only available to basic organsiations. At the time of
   * writing there is no easy way to differentiate between a franchise and a supplier,
   * so both will see this.
   */
  {
    path: '/partner-engagement/franchisors',
    name: 'franchisors',
    component: () => import('@pages/engagePartners/franchisorEngagement/Franchisor.vue'),
    redirect: { name: 'franchisorEngagementFranchisorsTab' },
    meta: {
      requiredFeatureFlags: ['rollout-franchisor-engagement'],
      category: 'Engage Partners',
    },
    beforeEnter: [
      hasPermissions(ACTION.VIEW_LIST_OF_ALL_SUPPLIERS),
      checkForUserRole,
      checkForEnabledFeatures,
      checkIfBasic,
    ],
    children: [
      {
        path: 'franchisors',
        name: 'franchisorEngagementFranchisorsTab',
        component: () => import('@components/FranchiseEngagement/Franchisor/Tabs/FranchisorTab/FranchisorTab.hoc.vue'),
      },
      {
        path: 'data-requests',
        name: 'franchisorEngagementDataRequestsTab',
        component: () =>
          import('@components/FranchiseEngagement/Franchisor/Tabs/DataRequestTab/DataRequestTab.hoc.vue'),
      },
    ],
  },
  {
    path: '/suppliers-engagement',
    name: 'suppliersV2Page',
    component: () => import('@pages/suppliersV2/SuppliersPage.v2.vue'),
    meta: {
      requiredFeatureFlags: ['enable-supplier-engagement'],
      category: 'Engage Suppliers',
    },
    beforeEnter: [hasPermissions(ACTION.VIEW_LIST_OF_ALL_SUPPLIERS), checkForUserRole, checkForEnabledFeatures],
    redirect: { name: 'suppliersTab' },
    children: [
      {
        path: '',
        name: 'suppliersTab',
        component: () => import('@pages/suppliersV2/suppliersTab/SuppliersTab.vue'),
      },
      {
        path: 'purchases',
        name: 'purchasesTab',
        component: () => import('@pages/suppliersV2/purchasesTab/PurchasesTab.vue'),
      },
    ],
  },
  {
    path: '/franchise-engagement',
    name: 'franchiseEngagement',
    component: () => import('@pages/FranchiseEngagement/FranchiseEngagement.vue'),
    meta: {
      requiredFeatureFlags: ['enable-franchise-engagement'],
      category: 'Engage Suppliers',
    },
    beforeEnter: [hasPermissions([ACTION.VIEW_FRANCHISES]), checkForUserRole, checkForEnabledFeatures],
  },
  {
    path: '/supplier-details',
    name: 'supplierV2PurchasesPage',
    meta: {
      requiredFeatureFlags: ['enable-supplier-engagement'],
      category: 'Engage Suppliers',
    },
    component: () => import('@pages/suppliersV2/SupplierPurchasesPage.v2.vue'),
    beforeEnter: [hasPermissions(ACTION.VIEW_LIST_OF_ALL_SUPPLIERS), checkForUserRole, checkForEnabledFeatures],
  },
  {
    path: '/targets',
    name: 'Targets v2',
    component: () => import('@pages/targets/Targets.root.vue'),
    beforeEnter: [hasPermissions(ACTION.VIEW_LIVE_SUSTAINABILITY_TARGETS), checkForUserRole],
    meta: {
      requiredFeatureFlags: ['rollout-targets-v-2'],
    },
    children: [
      {
        path: '',
        name: 'View sustainability targets',
        component: () => import('@pages/targets/TargetsOverviewContainer.vue'),
        meta: {
          pageTitle: 'Sustainability targets',
        },
        redirect: { name: 'Targets overview' },
        children: [
          {
            path: '',
            name: 'Targets overview',
            component: () => import('@pages/targets/TargetsOverview.vue'),
          },
        ],
      },
      {
        path: 'create',
        name: 'Create sustainability target',
        component: () => import('@pages/targets/CreateTarget.vue'),
        meta: {
          layout: 'EmptyLayout',
        },
        beforeEnter: [hasPermissions(ACTION.UPDATE_SUSTAINABILITY_TARGETS)],
      },
      {
        path: ':id',
        name: 'targetPage',
        component: () => import('@pages/targets/Target.vue'),
        beforeEnter: [
          hasPermissions([ACTION.VIEW_LIVE_SUSTAINABILITY_TARGETS, ACTION.VIEW_DRAFT_SUSTAINABILITY_TARGETS]),
          updateActiveTarget,
        ],
      },
      {
        path: ':id/edit',
        name: 'Edit sustainability target',
        component: () => import('@pages/targets/CreateTarget.vue'),
        meta: {
          layout: 'EmptyLayout',
        },
        beforeEnter: [hasPermissions(ACTION.UPDATE_SUSTAINABILITY_TARGETS), updateActiveTarget],
      },
    ],
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('@pages/share/Share.vue'),
    beforeEnter: [hasPermissions([ACTION.VIEW_REPORTS, ACTION.DOWNLOAD_REPORTS]), checkForUserRole],
    meta: {
      disableBUSelectors: true,
      pageTitle: 'Corporate Reports',
      category: 'Reports',
    },
  },
  {
    path: '/organizations',
    name: 'organizations',
    meta: {
      disableBUSelectors: true,
      pageTitle: 'Organizations',
    },
    component: () => import('@pages/admin/Organizations.versionSwitch.vue'),
    beforeEnter: [checkForUserRole],
  },
  {
    path: '/full-impact-breakdown/:page?',
    name: 'fullImpactBreakdown',
    component: () => import('@pages/measure/FullImpactBreakdown.vue'),
    beforeEnter: [
      hasPermissions([
        ACTION.VIEW_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
        ACTION.DOWNLOAD_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
      ]),
    ],
    meta: {
      category: 'Analyse',
    },
  },
  {
    path: '/settings/user-organization',
    name: 'userOrganizationSettings',
    component: () => import('@pages/admin/UserAccountSettings.vue'),
    beforeEnter: [hasPermissions([ACTION.UPDATE_USER_PROFILE, ACTION.UPDATE_USER_SETTINGS]), checkForUserRole],
    meta: {
      disableBUSelectors: true,
      category: 'Settings',
    },
  },
  {
    path: '/2fa/setup',
    name: 'twoFactorSetup',
    meta: { layout: 'split-view', dontUseAsIntendedPath: true },
    beforeEnter: [checkForUserRole],
    component: () => import('@pages/user/2fa/TwoFactorSetup.vue'),
  },
  {
    path: '/2fa/login',
    name: 'twoFactorLogin',
    meta: { layout: 'split-view', dontUseAsIntendedPath: true },
    beforeEnter: [checkForUserRole],
    component: () => import('@pages/user/2fa/TwoFactorLogin.vue'),
  },
  {
    path: '/2fa/login-with-backup-code',
    name: 'twoFactorLoginWithBackupCode',
    meta: { layout: 'split-view', dontUseAsIntendedPath: true },
    beforeEnter: [checkForUserRole],
    component: () => import('@pages/user/2fa/TwoFactorLoginWithBackupCode.vue'),
  },
  {
    path: '/customers',
    name: 'customers',
    meta: {
      requiredFeatureFlags: ['enable_view_customers'],
      pageTitle: 'Customers',
      category: 'Reports',
    },
    beforeEnter: [hasPermissions([ACTION.VIEW_REPORTS, ACTION.DOWNLOAD_REPORTS]), checkForEnabledFeatures],
    component: () => import('@pages/customers/Customers.vue'),
  },
  {
    path: '/customers/:id',
    name: 'viewCustomer',
    meta: {
      requiredFeatureFlags: ['enable_view_customers'],
      pageTitle: 'Customer',
      category: 'Reports',
    },
    beforeEnter: [hasPermissions([ACTION.VIEW_REPORTS, ACTION.DOWNLOAD_REPORTS]), checkForEnabledFeatures],
    component: () => import('@pages/customers/customer/Customer.vue'),
  },

  {
    path: '/customers-engagement',
    name: 'customersEngagement',
    meta: {
      requiredFeatureFlags: ['enable_customers_engagement_page'],
      category: 'Engage Customers',
    },
    component: () => import('@pages/customersEngagement/CustomersEngagementPage.vue'),
    redirect: { name: 'customersTab' },
    children: [
      {
        path: '',
        name: 'customersTab',
        component: () => import('@pages/customersEngagement/customersTab/CustomersTab.vue'),
      },
      {
        path: 'data-requests',
        name: 'dataRequestsTab',
        component: () => import('@pages/customersEngagement/dataRequestsTab/DataRequestsTab.vue'),
      },
    ],
    beforeEnter: [checkForUserRole, checkForEnabledFeatures],
  },

  {
    path: '/corporate-initiatives',
    name: 'corporateInitiativesPage',
    meta: {
      requiredFeatureFlags: ['rollout-corporate-initiatives'],
      pageTitle: 'Corporate initiatives',
      category: 'Reduce Impact',
    },
    component: () => import('@pages/corporateInitiatives/CorporateInitiativesPage.vue'),
    beforeEnter: [hasPermissions([ACTION.VIEW_CORPORATE_INITIATIVES]), checkForUserRole, checkForEnabledFeatures],
  },

  {
    path: '/corporate-initiatives/:id',
    name: 'corporateInitiativePage',
    meta: {
      requiredFeatureFlags: ['rollout-corporate-initiatives'],
      pageTitle: 'Corporate initiative',
      category: 'Reduce Impact',
    },
    component: () => import('@pages/corporateInitiatives/CorporateInitiativePage.vue'),
    beforeEnter: [hasPermissions([ACTION.VIEW_CORPORATE_INITIATIVES]), checkForUserRole, checkForEnabledFeatures],
  },

  {
    path: '/product-initiatives/create',
    name: 'createInitiative',
    meta: {
      layout: 'no-sidebar',
      pageTitle: 'Create new initiative',
      category: 'Reduce Impact',
      showBetaHeader: true,
    },
    component: () => import('@pages/initiatives/CreateInitiative.vue'),
    beforeEnter: [
      checkForUserRole,
      hasPermissions([ACTION.CREATE_INITIATIVES]),
      checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT),
    ],
  },
  {
    path: '/product-initiatives/:id',
    name: 'Initiative Detail',
    meta: {
      pageTitle: 'Initiative',
      category: 'Reduce Impact',
      showBetaHeader: true,
    },
    component: () => import('@pages/initiatives/Initiative.vue'),
    beforeEnter: [
      checkForUserRole,
      hasPermissions([ACTION.VIEW_INITIATIVES]),
      checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT),
    ],
  },
  {
    path: '/product-initiatives',
    name: 'Initiatives List',
    meta: {
      pageTitle: 'Initiatives',
      category: 'Reduce Impact',
      showBetaHeader: true,
    },
    component: () => import('@pages/initiatives/Initiatives.vue'),
    beforeEnter: [
      checkForUserRole,
      hasPermissions([ACTION.VIEW_INITIATIVES]),
      checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT),
    ],
  },

  // /**
  //  * Stand alone pcf module
  //  */

  // /**
  //  * Basic Facility
  //  */
  {
    path: '/facility-data',
    name: 'facilityData',
    component: () => import('@pages/basicFacility/FacilityData.vue'),
    beforeEnter: checkForEnabledFeatures,
    meta: {
      requiredFeatureFlags: ['rollout-standalone-pcf-37955'],
      disableBUSelectors: true,
      category: 'Manage Data',
    },
  },
  {
    path: '/facility-data/:facilityId',
    name: 'basicFacilityPage',
    component: () => import('@pages/basicFacility/FacilityDetails.vue'),
    beforeEnter: checkForEnabledFeatures,
    redirect: { name: 'basicFacilityDetailsTab' },
    meta: {
      requiredFeatureFlags: ['rollout-standalone-pcf-37955'],
      disableBUSelectors: true,
      category: 'Manage Data',
    },
    children: [
      {
        name: 'basicFacilityDetailsTab',
        path: '',
        component: () => import('@components/basicFacility/facilityDetails/FacilityDetails.vue'),
      },
      {
        name: 'processingDataTab',
        path: '',
        component: () => import('@pages/basicFacility/ProcessingData.vue'),
      },
    ],
  },
  // /**
  //  * Admin routes
  //  */

  {
    path: '/organization',
    name: 'editRootOrganization',
    component: () => import('@pages/editOrganization/EditOrganization.vue'),
    meta: {
      layout: 'no-sidebar',
      category: 'Settings',
    },
    beforeEnter: [checkIsActiveOrgRootOrg],
    children: [
      {
        name: 'editContractScope',
        path: '',
        meta: {
          pageTitle: 'Settings - Contract Scope',
        },
        beforeEnter: [
          checkIsActiveOrgRootOrg,
          // TODO: RBAC enable when SOLUTION_ADVISOR role is enabled, and remove checkForSuperuserRole
          // hasPermissions([ACTION.VIEW_CONTRACT_SCOPE_SETTINGS, ACTION.UPDATE_CONTRACT_SCOPE_SETTINGS]),
          checkForSuperuserRole,
        ],
        component: () => import('@pages/editOrganization/EditContractScope.vue'),
      },
      {
        name: 'editDetailsAndSettings',
        path: 'details-and-settings',
        beforeEnter: [checkIsActiveOrgRootOrg, hasPermissions([ACTION.UPDATE_ORGANISATION_DETAILS])],
        meta: {
          pageTitle: 'Settings - Details & Settings',
        },
        component: () => import('@pages/editOrganization/EditDetailsAndSettings.vue'),
      },
      {
        name: 'editAuthenticationType',
        path: 'authentication',
        beforeEnter: [
          checkIsActiveOrgRootOrg,
          hasPermissions([
            ACTION.UPDATE_AUTHENTICATION_SETTINGS,
            ACTION.CREATE_INTEGRATIONS,
            ACTION.UPDATE_INTEGRATIONS,
            ACTION.DELETE_INTEGRATIONS,
          ]),
        ],
        meta: {
          pageTitle: 'Settings - Authentication',
        },
        component: () => import('@pages/editOrganization/EditAuthentication.vue'),
      },
    ],
  },

  {
    path: '/manage/users',
    name: 'manageUsers',
    beforeEnter: [hasPermissions([ACTION.CREATE_NEW_USERS])],
    meta: {
      pageTitle: 'Manage Users',
    },
    component: () => import('@pages/editOrganization/EditUsers/EditUsers.vue'),
  },
  {
    path: '/manage/users/:id',
    name: 'manageUser',
    beforeEnter: [hasPermissions([ACTION.UPDATE_USER_ROLES])],
    meta: {
      pageTitle: 'Manage User',
    },
    component: () => import('@pages/admin/ManageUser.vue'),
  },
  {
    path: '/organization/setup',
    name: 'organizationSetup',
    meta: {
      layout: 'no-sidebar',
      category: 'Settings',
    },
    component: () => import('@pages/admin/CreateOrganization.vue'),
    beforeEnter: [checkForAdminRole],
  },
  {
    path: '/supplier/settings',
    name: 'supplierSettings',
    meta: {
      requiredFeatureFlags: ['allow_edit_organization'],
      category: 'Settings',
    },
    component: () => import('@pages/admin/EditSupplierOrganization.vue'),
    beforeEnter: checkForEnabledFeatures,
  },
  {
    path: '/organization/settings/:page?',
    name: 'organizationSettings',
    meta: {
      requiredFeatureFlags: ['allow_edit_organization'],
      disableBUSelectors: true,
      category: 'Settings',
    },
    component: () => import('@pages/admin/CreateOrganization.vue'),
    beforeEnter: checkForEnabledFeatures,
  },
  {
    path: '/organizations/create',
    name: 'createOrganization',
    meta: {
      layout: 'no-header',
      requiredFeatureFlags: ['allow_create_organization'],
      category: 'Settings',
    },
    component: () => import('@pages/admin/CreateOrganization.vue'),
    beforeEnter: checkForEnabledFeatures,
  },

  {
    path: '/organizations/edit/:page/:id',
    name: 'editOrganization',
    meta: {
      layout: 'no-header',
      requiredFeatureFlags: ['allow_edit_other_organization'],
      category: 'Settings',
    },
    component: () => import('@pages/admin/CreateOrganization.vue'),
    beforeEnter: checkForEnabledFeatures,
  },

  {
    path: '/franchise-invitation/:token',
    name: 'acceptFranchiseInviteAdvanced',
    component: () => import('@pages/supplier/AcceptSupplierInviteAdvanced.vue'),
    meta: {
      layout: 'empty',
      public: true,
    },
  },
  // commented out new supplier sign up for now until FF is created for new sign up
  {
    path: '/supplier-invitation-v2/:token',
    name: 'acceptSupplierInviteAdvanced',
    component: () => import('@pages/supplier/AcceptSupplierInviteAdvanced.vue'),
    meta: {
      layout: 'empty',
      public: true,
    },
  },
  {
    path: '/survey/:id/scope',
    name: 'supplierSurveyScope',
    component: () => import('@pages/supplier/SupplierSurveyScope.vue'),
    beforeEnter: checkForEnabledFeatures,
    meta: {
      layout: 'empty',
      requiredFeatureFlags: ['enable_supplier_survey'],
      category: 'Engage Suppliers',
    },
  },
  {
    path: '/survey/:type/:id/',
    name: 'supplierSurveyForm',
    component: () => import('@pages/supplier/SupplierSurveyForm.vue'),
    beforeEnter: checkForEnabledFeatures,
    meta: {
      layout: 'empty',
      requiredFeatureFlags: ['enable_supplier_survey'],
      category: 'Engage Suppliers',
    },
    children: [
      {
        path: 'introduction',
        name: 'surveyIntroductionPage',
        component: () => import('@pages/supplier/SurveyIntroductionPage.vue'),
      },
      {
        path: 'scope1-scope2',
        name: 'surveyScope12Page',
        component: () => import('@pages/supplier/SurveyScope12Page.vue'),
      },
      {
        path: 'scope3',
        name: 'surveyScope3Page',
        component: () => import('@pages/supplier/SurveyScope3Page.vue'),
      },
      {
        path: 'business-outline',
        name: 'surveyBusinessOutlinePage',
        component: () => import('@pages/supplier/SurveyBusinessOutlinePage.vue'),
      },
      {
        path: 'products-and-services',
        name: 'surveyProductsServicesPage',
        component: () => import('@pages/supplier/SurveyProductsServicesPage.vue'),
      },
      {
        path: 'locations',
        name: 'surveyLocationsPage',
        component: () => import('@pages/supplier/SurveyLocationsPage.vue'),
      },
      {
        path: 'summary',
        name: 'surveySummaryPage',
        component: () => import('@pages/supplier/SurveySummaryPage.vue'),
      },
    ],
  },

  // data sources
  {
    path: '/corporate-data',
    name: 'corporateData',
    component: () => import('@pages/dataSources/DataSourcesPage.vue'),
    beforeEnter: [checkForUserRole],
    meta: {
      disableYearSelector: true,
      pageTitle: 'Corporate Data',
      category: 'Manage Data',
    },
  },
  {
    path: '/corporate-data/:id/:uploadId?',
    name: 'corporateDataSource',
    component: () => import('@pages/dataSources/DataSourcePage.vue'),
    beforeEnter: [checkForUserRole],
    meta: {
      requiredFeatureFlags: ['allow_access_data_sources'],
      disableBUSelectors: true,
      category: 'Manage Data',
    },
  },

  // redirects from old data-sources link to the updates /corporate-data link
  {
    path: '/data-sources',
    name: 'dataSources',
    redirect: { name: 'corporateData' },
  },
  {
    path: '/data-source/:id/:uploadId?',
    name: 'dataSource',
    redirect: { name: 'corporateDataSource' },
  },

  /**
   * Organization Structure
   */
  {
    path: '/organization-structure',
    name: 'organization-structure',
    component: () => import('@pages/organizationStructure/OrganizationStructure.vue'),
    beforeEnter: [hasPermissions(ACTION.VIEW_THE_LIST_OF_BUSINESS_UNITS_IN_THE_ORGANISATION)],
    meta: {
      disableBUSelectors: true,
      category: 'Manage Data',
    },
  },

  {
    path: '/organization-structure/business-unit/edit/:orgId?',
    name: 'editOrgStructureBusinessUnit',
    component: () => import('@pages/organizationStructure/OrgStrucBusinessUnit.vue'),
    beforeEnter: [
      hasPermissions([ACTION.UPDATE_THE_DETAILS_OF_A_BUSINESS_UNIT, ACTION.UPDATE_USER_ROLES_WITHIN_A_BUSINESS_UNIT]),
    ],
    meta: {
      disableBUSelectors: true,
      category: 'Manage Data',
    },
  },

  {
    path: '/organization-structure/business-unit/add/:orgId?',
    name: 'addOrgStructureBusinessUnit',
    component: () => import('@pages/organizationStructure/OrgStrucBusinessUnit.vue'),
    beforeEnter: [hasPermissions([ACTION.CREATE_A_NEW_BUSINESS_UNIT])],
    meta: {
      disableBUSelectors: true,
      category: 'Manage Data',
    },
  },

  {
    path: '/organization-structure/facilities/:orgId/:facilityId',
    name: 'facilityV2',
    component: () => import('@pages/facilities/Facility.vue'),
    beforeEnter: [hasPermissions([ACTION.CREATE_FACILITIES, ACTION.UPDATE_FACILITIES])],
    meta: {
      disableBUSelectors: true,
      category: 'Manage Data',
    },
  },

  {
    path: '/organization-structure/facilities',
    name: 'facilities',
    component: () => import('@pages/facilities/Facilities.vue'),
    meta: {
      disableBUSelectors: true,
      category: 'Manage Data',
    },
  },

  {
    path: '/organization-structure/facilities/:action/:orgId/:facilityId?/:facName?',
    name: 'organizationFacilityV2',
    component: () => import('@pages/facilities/Facility.vue'),
    beforeEnter: [hasPermissions([ACTION.CREATE_FACILITIES, ACTION.UPDATE_FACILITIES])],
    meta: {
      hideBUSelectors: true,
      category: 'Manage Data',
    },
  },

  /**
   * Business Views
   */
  {
    path: '/business-unit/not-operational/:name/:year',
    name: 'businessUnitNotOperational',
    component: () => import('@pages/businessUnitViews/BusinessUnitNotOperational.vue'),
    beforeEnter: [checkForUserRole],
  },

  /**
   * Internal AQ Views
   */
  {
    path: '/aq',
    name: 'AQWelcome',
    component: () => import('@pages/aq/Welcome.vue'),
    beforeEnter: [hasPermissions(ACTION.ACCESS_ALTRUISTIQ_ADVISOR), checkForAdvisorRole],
    meta: {
      layout: 'empty',
      category: 'AQ Advisor',
    },
  },
  {
    path: '/aq',
    name: 'AdvisorApp',
    component: () => import('@pages/aq/AdvisorApp.vue'),
    beforeEnter: [hasPermissions(ACTION.ACCESS_ALTRUISTIQ_ADVISOR), checkForAdvisorRole],
    meta: {
      layout: 'empty',
      pageTitle: 'Advisor App',
      category: 'AQ Advisor',
    },
    children: [
      {
        path: '/aq/ef-catalogue',
        name: 'EFCatalogue',
        component: () => import('@pages/aq/EfCatalogue.vue'),
        children: [
          {
            path: '/aq/ef-catalogue/calculation-methods',
            name: 'CalculationMethods',
            component: () => import('@pages/aq/efcatalogue/CalculationMethod.vue'),
            children: [
              {
                path: 'activity/:activityCode',
                name: 'CalculationMethodActivity',
                component: () => import('@pages/aq/efcatalogue/CalculationMethod.vue'),
              },
            ],
          },
          {
            path: '/aq/ef-catalogue/emission-factors',
            name: 'EmissionFactors',
            component: () => import('@pages/aq/efcatalogue/EmissionFactors.vue'),
          },
        ],
      },
      {
        path: '/aq/tagging/:query?',
        name: 'Tagging',
        component: () => import('@pages/aq/Tagging/Tagging.vue'),
      },
      {
        path: '/aq/your-calculations',
        name: 'Calculations',
        component: () => import('@pages/aq/Calculations/Calculations.vue'),
      },
      {
        path: '/aq/pdf-ingestion',
        name: 'PDFIngestion',
        component: () => import('@pages/aq/PDFIngestion/PDFIngestion.vue'),
        beforeEnter: (_: RouteLocationNormalized, __: RouteLocationNormalized, next: NavigationGuardNext) => {
          const lsScript = document.createElement('script')
          lsScript.src = 'https://cdn.jsdelivr.net/npm/@heartexlabs/label-studio@1.4.0/build/static/js/main.min.js'

          const lsStyles = document.createElement('link')
          lsStyles.rel = 'stylesheet'
          lsStyles.type = 'text/css'
          lsStyles.href = 'https://cdn.jsdelivr.net/npm/@heartexlabs/label-studio@1.4.0/build/static/css/main.css'

          document.head.appendChild(lsStyles)
          document.body.appendChild(lsScript)

          next()
        },
        children: [
          {
            path: 'labelling/:orgId?/:buId?/:dataSourceId?/:projectId?/:taskId?',
            name: 'PDFLabelling',
            component: () => import('@pages/aq/PDFLabelling/PDFLabelling.vue'),
          },
          {
            path: 'review-annotations/:orgId/:dataSourceId/:projectId/:dataSourceName?',
            name: 'OCROutput',
            component: () => import('@pages/aq/OCROutput/OCROutput.vue'),
          },
        ],
      },
      {
        path: '/aq/pdf-ingestion/page-grouping/:query?',
        name: 'PDFPageGrouping',
        component: () => import('@pages/aq/PDFPageGrouping/PDFPageGrouping.vue'),
        meta: {
          requiredFeatureFlags: ['rollout-pdf-v2'],
        },
      },
      {
        path: '/aq/pdf-ingestion',
        component: () => import('@pages/aq/PDFIngestion/PDFIngestion.vue'),
        beforeEnter: (_: RouteLocationNormalized, __: RouteLocationNormalized, next: NavigationGuardNext) => {
          const lsScript = document.createElement('script')
          lsScript.src = 'https://cdn.jsdelivr.net/npm/@heartexlabs/label-studio@1.4.0/build/static/js/main.min.js'
          lsScript.onload = () => {
            // Script loaded, continue to the next step in the navigation
            next()
          }

          const lsStyles = document.createElement('link')
          lsStyles.rel = 'stylesheet'
          lsStyles.type = 'text/css'
          lsStyles.href = 'https://cdn.jsdelivr.net/npm/@heartexlabs/label-studio@1.4.0/build/static/css/main.css'

          document.head.appendChild(lsStyles)
          document.body.appendChild(lsScript)
        },
        meta: {
          requiredFeatureFlags: ['rollout-pdf-v2'],
        },
        children: [
          {
            path: 'page-annotation/:orgId/:dataSourceId/:query?',
            name: 'PDFPageAnnotation',
            component: () => import('@pages/aq/PDFPageAnnotation/PDFPageAnnotation.vue'),
          },
          {
            path: 'pdf-review/:orgId/:dataSourceId',
            name: 'PDFReview',
            component: () => import('@pages/aq/PDFReviewPageV2/PDFReviewPage.v2.vue'),
          },
        ],
      },
    ],
  },

  /**
   * Products
   *
   * TODO: Remove isDev flag before releasing to production
   */
  {
    path: '/products',
    name: 'products',
    component: () => import('@pages/products/Products.vue'),
    beforeEnter: [
      hasPermissions([
        ACTION.VIEW_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
        ACTION.DOWNLOAD_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
      ]),
      checkForUserRole,
      checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT),
    ],
    meta: {
      pageTitle: 'Product Footprints',
      category: 'Product Footprinting',
    },
  },

  /**
   * Corporate footprints
   * */
  {
    path: '/progress',
    component: () => import('@pages/corporateFootprints/FootprintsRoute.vue'),
    beforeEnter: [checkForUserRole, checkForEnabledFeatures, checkIsActiveOrgRootOrg],
    meta: {
      category: 'Manage Data',
      showBetaHeader: true,
    },
    children: [
      {
        path: '',
        name: 'progress',
        component: () => import('@pages/corporateFootprints/FootprintsPage/FootprintsPage.vue'),
      },
      {
        path: '/progress/:year',
        name: 'progressPerYear',
        component: () => import('@/imports/ui/pages/corporateFootprints/FootprintPage/FootprintPage.vue'),
        beforeEnter: [checkForUserRole, checkForEnabledFeatures],
      },
      {
        path: '/progress/:year/:subActivityId',
        component: () => import('@pages/corporateFootprints/AssociationsPage.vue'),
        beforeEnter: [checkForUserRole, checkForEnabledFeatures],
        children: [
          {
            path: '',
            name: 'progressPerYearAndActivity',
            component: () => import('@pages/corporateFootprints/FacilitiesTab/FacilitiesTab.vue'),
          },
          {
            path: 'datasources',
            name: 'activityProgressDatasources',
            component: () => import('@pages/corporateFootprints/DatasourcesTab.vue'),
          },
        ],
      },
      {
        path: '/progress/:year/scope',
        name: 'progressScopes',
        component: () => import('@pages/corporateFootprints/ScopePage.vue'),
        beforeEnter: [checkForAdminRole, checkForEnabledFeatures],
      },
      {
        path: '/progress/:year/franchises',
        name: 'progressFranchise',
        component: () =>
          import('@pages//corporateFootprints/FranchiseFootprintsDetailsPage/FranchiseFootprintsDetailsPage.vue'),
        beforeEnter: [checkForAdminRole, checkForEnabledFeatures],
        meta: {
          requiredFeatureFlags: ['rollout-franchise-engagement-v2'],
        },
      },
    ],
  },
  /**
   * Product footprint
   *
   * TODO: Remove isDev flag before releasing to production
   */
  {
    path: '/products/:id',
    name: 'productFootprint',
    component: () => import('@pages/products/ProductFootprint.versionToggle.vue'),
    beforeEnter: [
      hasPermissions([
        ACTION.VIEW_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
        ACTION.DOWNLOAD_DATA_FROM_PRODUCT_IMPACT_DASHBOARDS,
      ]),
      checkForUserRole,
      checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT),
    ],
    meta: {
      pageTitle: 'Product Footprint',
      category: 'Product Footprinting',
    },
    children: [
      {
        path: 'overview',
        name: 'productOverviewTab',
        component: () => import('@pages/products/ProductFootprint/ProductFootprintOverviewTab.vue'),
      },
      {
        path: 'snapshots-tab',
        name: 'snapshotsTab',
        component: () => import('@pages/products/ProductFootprint/ProductSnapshotTab.vue'),
      },
    ],
  },

  {
    path: '/products/upload',
    name: 'ProductsUpload',
    component: () => import('@pages/products/ProductDatasource/ProductDatasource.vue'),
    beforeEnter: [
      checkForUserRole,
      checkForEnabledFeatures,
      checkForOrgModuleEnabled(ORGANIZATION_MODULES.PRODUCT_FOOTPRINT),
    ],
    meta: {
      pageTitle: 'Upload products',
      category: 'Manage Data',
    },
  },

  /**
   * Metabase custom dashboards
   */
  {
    path: '/analytics',
    name: 'MainAnalyticsCollection',
    component: () => import('@pages/customDashboards/MainDashboard.vue'),
    beforeEnter: [hasPermissions([ACTION.VIEW_CUSTOM_DASHBOARDS]), checkForUserRole],
    meta: {
      layout: 'default-no-padding',
      category: 'Explore Data',
    },
  },
  {
    path: '/analytics/login',
    name: 'MetabaseSSOLogin',
    component: () => import('@pages/customDashboards/MetabaseSSOLogin.vue'),
    beforeEnter: [hasPermissions([ACTION.VIEW_CUSTOM_DASHBOARDS]), checkForUserRole, checkForEnabledFeatures],
    meta: {
      layout: 'no-sidebar-or-navbar',
      requiredFeatureFlags: ['enable_metabase_dashboards'],
    },
  },

  /**
   * Legal entities
   * */

  {
    path: '/legal-entities',
    name: 'legalEntities',
    component: () => import('@pages/legalEntities/LegalEntitiesPage.vue'),
    beforeEnter: [checkForUserRole, checkForEnabledFeatures],
    meta: {
      requiredFeatureFlags: ['enable_legal_entities'],
      category: 'Manage Data',
    },
  },

  /**
   * Material and packaging
   *
   */
  {
    path: '/materials-and-packaging',
    name: 'materialsAndPackaging',
    component: () => import('@pages/materialsAndPackaging/MaterialsAndPackaging.vue'),
    beforeEnter: [checkForUserRole, checkForEnabledFeatures],
    meta: {
      requiredFeatureFlags: ['enable-materials-breakdown-page'],
      category: 'Product Footprinting',
    },
  },

  {
    path: '/materials-and-packaging/:id',
    name: 'materialsAndPackagingDetails',
    component: () => import('@pages/materialsAndPackaging/MaterialsAndPackagingDetailsPage.vue'),
    beforeEnter: [checkForUserRole, checkForEnabledFeatures],
    meta: {
      requiredFeatureFlags: ['enable-materials-breakdown-page'],
      category: 'Product Footprinting',
    },
  },

  /**
   * Facility footprinting
   */
  {
    path: '/facility-footprints',
    name: 'facilityFootprints',
    component: () => import('@pages/facilityFootprint/FacilityFootprintOverview/FacilityFootprintOverview.vue'),
    beforeEnter: [
      hasPermissions([
        ACTION.VIEW_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
        ACTION.DOWNLOAD_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
      ]),
      checkForUserRole,
    ],
    meta: {
      pageTitle: 'Facility Footprints',
      category: 'Analyse',
    },
  },

  {
    path: '/facility-footprints/:facilityId',
    name: 'FacilityFootprint',
    component: () => import('@pages/facilityFootprint/FacilityFootprintDetail/FacilityFootprintDetail.vue'),
    beforeEnter: [
      hasPermissions([
        ACTION.VIEW_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
        ACTION.DOWNLOAD_DATA_FROM_CORPORATE_IMPACT_DASHBOARDS,
      ]),
      checkForUserRole,
      checkForEnabledFeatures,
    ],
    meta: {
      pageTitle: 'Facility Footprint',
      category: 'Analyse',
    },
  },
  {
    path: '/data-request/:id',
    name: 'dataRequestResponse',
    component: () => {
      if (FeatureFlagService.isEnabled('rollout-franchise-engagement-related-updates')) {
        return import('@pages/dataRequestResponse/DataRequestResponsePage.vue')
      }

      return import('@pages/dataRequestResponse/DataRequestResponsePage.deprecated.vue')
    },
    meta: {
      requiredFeatureFlags: ['enable_supplier_survey'],
      layout: 'empty',
      category: 'Engage Suppliers',
    },
    beforeEnter: [checkForUserRole, checkForEnabledFeatures],
  },

  {
    path: '/basic-calculator',
    name: 'basicCalculator',
    component: () => import('@pages/basicCalculator/index.vue'),
    meta: {
      layout: 'empty',
    },
    children: [
      {
        path: 'introduction/:id?',
        name: 'basicCalculatorIntroduction',
        component: () =>
          import('@components/BasicFootprints/BasicCalculatorWizard/Introduction/CalculatorIntroduction.vue'),
        meta: {
          layout: 'empty',
        },
        beforeEnter: [checkForUserRole, checkForEnabledFeatures],
      },

      {
        path: 'calculation/:id',
        name: 'basicCalculation',
        meta: {
          layout: 'empty',
          category: 'Manage Data',
        },
        component: () => {
          if (!FeatureFlagService.isEnabled('rollout-standalone-pcf-37955')) {
            return import('@pages/basicCalculator/BasicFootprintCalculator.deprecated.vue')
          }

          return import('@pages/basicCalculator/BasicFootprintCalculator.vue')
        },
        beforeEnter: [checkForUserRole, checkForEnabledFeatures],
      },
    ],
  },

  {
    path: '/audit-logs',
    name: 'audit-logs',
    component: () => import('@pages/audit-logs/AuditLogs.root.vue'),
    meta: {
      requiredFeatureFlags: ['enable-audit-logs'],
    },
    beforeEnter: [checkForUserRole, checkForEnabledFeatures],
  },

  {
    path: '/request-access',
    name: 'request-access',
    component: () => import('@pages/RequestAccess.vue'),
    beforeEnter: [],
    meta: { layout: 'no-sidebar-or-navbar', public: true },
  },

  {
    path: '/no-access',
    name: 'noAccess',
    component: () => import('@/imports/ui/components/rbac/NoAccessToPage.vue'),
    meta: {
      pageTitle: 'No Access',
    },
  },

  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@pages/404.vue'),
    beforeEnter: [checkForUserRole],
    meta: {
      pageTitle: 'Altruistiq 404',
    },
  },
]
