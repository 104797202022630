import type { AxiosInstance } from 'axios'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'

import type {
  GetOrganizationStructurePayload,
  BusinessUnitResponse,
  GetOrgStructureBusinessUnitPayload,
  BusinessUnitDetails,
  EditChildOrg,
  EditChildOrgResponseStructure,
  CreateOrgStructure,
  StructureHistoryItem,
  AddChildOrg,
  AddChildOrgResponseStructure,
  GetFieldHistoryPayload,
  OrgPropertyItem,
  EditOrgProperty,
  CreateOrgProperty,
  EditOrgPropertyResponseStructure,
  DeleteOrgProperty,
  DeleteOrgPropertyResponse,
} from '@/imports/@types/organizationStructure/v2/OrganizationStructure'

export const useOrganizationStructureApi = (http: AxiosInstance) => ({
  getOrgStructure(payload: GetOrganizationStructurePayload) {
    return http.get<ApiResponseInner<BusinessUnitResponse>>('/organizationStructure/get', { params: payload })
  },

  getOrgStructureBusinessUnit(payload: GetOrgStructureBusinessUnitPayload) {
    return http.get<ApiResponseInner<BusinessUnitDetails>>('/organization/getChildOrgEditDetails', {
      params: payload,
    })
  },

  editChildOrg(payload: EditChildOrg) {
    return http.patch<ApiResponseInner<EditChildOrgResponseStructure>>('/organization/updateChildOrganization', payload)
  },

  createOrgStructure(payload: CreateOrgStructure) {
    return http.post<ApiResponseInner<StructureHistoryItem>>('/organizationStructure/create', payload)
  },

  addChildOrg(payload: AddChildOrg) {
    return http.post<ApiResponseInner<AddChildOrgResponseStructure>>('/organization/createChildOrganization', payload)
  },

  getFieldHistory(payload: GetFieldHistoryPayload) {
    return http.get<ApiResponseInner<StructureHistoryItem[]>>('/organizationProperties/getFieldHistory', {
      params: payload,
    })
  },

  updateOrgProperty(payload: EditOrgProperty) {
    return http.patch<ApiResponseInner<EditOrgPropertyResponseStructure>>('/organizationProperties/update', payload)
  },

  createOrgProperty(payload: CreateOrgProperty) {
    return http.post<ApiResponseInner<OrgPropertyItem>>('/organizationProperties/create', payload)
  },

  deleteOrgProperty(payload: DeleteOrgProperty) {
    return http.delete<ApiResponseInner<DeleteOrgPropertyResponse>>('/organizationProperties/delete', {
      params: payload,
    })
  },
})
