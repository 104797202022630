import type { AxiosInstance } from 'axios'
import { replaceUriParams } from '@lib/DTO'

import {
  INITIATIVE_LIST_URI,
  InitiativesListDTOReq,
  type InitiativesListDTORes,
} from '@lib/DTO/product-initiative/get-initiatives.dto'

import {
  INITIATIVE_DETAIL_URI,
  InitiativeDetailDTOReq,
  type InitiativeDetailDTORes,
} from '@lib/DTO/product-initiative/get-initiative-details.dto'

import {
  INITIATIVE_GET_INPUTS_URI,
  InitiativesInputsDTOReq,
  type InitiativesInputsDTORes,
} from '@lib/DTO/product-initiative/get-initiative-inputs.dto'

import {
  INITIATIVE_PRODUCTS_URI,
  InitiativesProductsDTOReq,
  type InitiativesProductsDTORes,
} from '@lib/DTO/product-initiative/get-products-using-input.dto'

import {
  INPUT_TYPE_LIST_URI,
  InitiativesInputTypesDTOReq,
  type InitiativesInputTypesDTORes,
} from '@lib/DTO/product-initiative/get-input-types-list.dto'

import { INITIATIVE_DELETE_URI, InitiativeDeleteDTOReq } from '@lib/DTO/product-initiative/delete-initative.dto'

import {
  INITIATIVE_CREATE_URI,
  InitiativesCreateDTOReq,
  type InitiativesCreateDTORes,
} from '@lib/DTO/product-initiative/create-initiative.dto'

import {
  INITIATIVE_UPDATE_URI,
  InitiativeUpdateDTOReq,
  type InitiativeUpdateDTORes,
} from '@lib/DTO/product-initiative/update-initiative.dto'

import {
  INITIATIVE_UPDATE_NAME_URI,
  InitiativeUpdateNameDTOReq,
  type InitiativeUpdateNameDTORes,
} from '@lib/DTO/product-initiative/update-initiative-name.dto'

export class InitiativesApi {
  private http: AxiosInstance

  constructor(http: AxiosInstance) {
    this.http = http
  }

  getInitiatives(orgId: string, params: InitiativesListDTOReq) {
    const requestURI = replaceUriParams(INITIATIVE_LIST_URI, params)

    return this.http.get<InitiativesListDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getInitiative(orgId: string, params: InitiativeDetailDTOReq) {
    const requestURI = replaceUriParams(INITIATIVE_DETAIL_URI, params)

    return this.http.get<InitiativeDetailDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getInitiativeInputs(orgId: string, params: InitiativesInputsDTOReq, signal?: AbortSignal) {
    const requestURI = replaceUriParams(INITIATIVE_GET_INPUTS_URI, params)

    return this.http.get<InitiativesInputsDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': orgId,
      },
      signal,
    })
  }

  getInitiativeProducts(orgId: string, params: InitiativesProductsDTOReq) {
    const requestURI = replaceUriParams(INITIATIVE_PRODUCTS_URI, params)

    return this.http.get<InitiativesProductsDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  getInputTypes(orgId: string, params: InitiativesInputTypesDTOReq) {
    const requestURI = replaceUriParams(INPUT_TYPE_LIST_URI, params)

    return this.http.get<InitiativesInputTypesDTORes>(requestURI, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  createInitiative(orgId: string, params: InitiativesCreateDTOReq) {
    const requestURI = replaceUriParams(INITIATIVE_CREATE_URI, params)

    return this.http.post<InitiativesCreateDTORes>(requestURI, params, {
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  updateInitiative(orgId: string, params: InitiativeUpdateDTOReq) {
    const requestURI = replaceUriParams(INITIATIVE_UPDATE_URI, params)

    return this.http.patch<InitiativeUpdateDTORes>(requestURI, params, {
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  deleteInitiative(orgId: string, params: InitiativeDeleteDTOReq) {
    const requestURI = replaceUriParams(INITIATIVE_DELETE_URI, params)

    return this.http.delete(requestURI, {
      params,
      headers: {
        'x-org-id': orgId,
      },
    })
  }

  updateInitiativeName(orgId: string, params: InitiativeUpdateNameDTOReq) {
    const requestURI = replaceUriParams(INITIATIVE_UPDATE_NAME_URI, params)

    return this.http.patch<InitiativeUpdateNameDTORes>(requestURI, params, {
      headers: {
        'x-org-id': orgId,
      },
    })
  }
}
