<template>
  <div
    class="spinner"
    :class="[{ block: isBlock }]"
  >
    <!-- Added for screen reader support-->
    <span v-show="false">Loading</span>
    <div
      ref="loading"
      class="spinner__inner"
      :class="[size]"
    >
      <div
        class="cube"
        :class="`has-background-${color}`"
      />
      <div
        class="cube"
        :class="`has-background-${color}`"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, nextTick, ref } from 'vue'

  type Props = {
    color?: string
    size?: string
    isBlock?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    color: 'is-grey-8',
    size: 'medium',
  })

  const loading = ref<HTMLElement | null>(null)

  onMounted(async () => {
    await nextTick()

    if (!loading.value) return

    const el = loading.value

    const cubes = Array.from(el.children) as HTMLElement[]

    cubes.forEach((cube, cubeIndex) => {
      cube.style.animation = `sk-cubemove-${props.size} 1.8s infinite ease-in-out forwards`

      if (cubeIndex === 1) cube.style.animationDelay = ' -0.9s'
    })
  })
</script>

<style lang="scss">
  $module: 'spinner';

  .#{$module} {
    &.block {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      margin: 0 auto;
    }

    &__inner {
      position: relative;
      top: -5px;
      z-index: 1;

      .cube {
        position: absolute;
        top: 0;
      }

      &.small {
        width: 10px;
        height: 10px;

        .cube {
          width: 5px;
          height: 5px;
        }
      }

      &.medium {
        .cube {
          width: 10px;
          height: 10px;
        }
      }

      &.large {
        width: 40px;
        height: 40px;

        .cube {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  $small: 5px;
  @-webkit-keyframes sk-cubemove-small {
    0% {
      -webkit-transform: translateX($small) translateY($small) rotate(-360deg);
    }
    25% {
      -webkit-transform: translateX(-$small) translateY($small) rotate(-270deg) scale(0.5);
    }
    50% {
      -webkit-transform: translateX(-$small) translateY(-$small) rotate(-180deg);
    }
    75% {
      -webkit-transform: translateX($small) translateY(-$small) rotate(-90deg) scale(0.5);
    }
    100% {
      -webkit-transform: translateX($small) translateY($small) rotate(0deg);
    }
  }

  @keyframes sk-cubemove-small {
    0% {
      transform: translateX($small) translateY($small) rotate(-360deg);
      -webkit-transform: translateX($small) translateY($small) rotate(-360deg);
    }
    25% {
      transform: translateX(-$small) translateY($small) rotate(-270deg) scale(0.5);
      -webkit-transform: translateX(-$small) translateY($small) rotate(-270deg) scale(0.5);
    }
    50% {
      transform: translateX(-$small) translateY(-$small) rotate(-180deg);
      -webkit-transform: translateX(-$small) translateY(-$small) rotate(-180deg);
    }
    75% {
      transform: translateX($small) translateY(-$small) rotate(-90deg) scale(0.5);
      -webkit-transform: translateX($small) translateY(-$small) rotate(-90deg) scale(0.5);
    }
    100% {
      transform: translateX($small) translateY($small) rotate(0deg);
      -webkit-transform: translateX($small) translateY($small) rotate(0deg);
    }
  }

  $medium: 6px;
  @-webkit-keyframes sk-cubemove-medium {
    0% {
      -webkit-transform: translateX($medium) translateY($medium) rotate(-360deg);
    }
    25% {
      -webkit-transform: translateX(-$medium) translateY($medium) rotate(-270deg) scale(0.5);
    }
    50% {
      -webkit-transform: translateX(-$medium) translateY(-$medium) rotate(-180deg);
    }
    75% {
      -webkit-transform: translateX($medium) translateY(-$medium) rotate(-90deg) scale(0.5);
    }
    100% {
      -webkit-transform: translateX($medium) translateY($medium) rotate(0deg);
    }
  }

  @keyframes sk-cubemove-medium {
    0% {
      transform: translateX($medium) translateY($medium) rotate(-360deg);
      -webkit-transform: translateX($medium) translateY($medium) rotate(-360deg);
    }
    25% {
      transform: translateX(-$medium) translateY($medium) rotate(-270deg) scale(0.5);
      -webkit-transform: translateX(-$medium) translateY($medium) rotate(-270deg) scale(0.5);
    }
    50% {
      transform: translateX(-$medium) translateY(-$medium) rotate(-180deg);
      -webkit-transform: translateX(-$medium) translateY(-$medium) rotate(-180deg);
    }
    75% {
      transform: translateX($medium) translateY(-$medium) rotate(-90deg) scale(0.5);
      -webkit-transform: translateX($medium) translateY(-$medium) rotate(-90deg) scale(0.5);
    }
    100% {
      transform: translateX($medium) translateY($medium) rotate(0deg);
      -webkit-transform: translateX($medium) translateY($medium) rotate(0deg);
    }
  }

  $large: 21px;
  @-webkit-keyframes sk-cubemove-large {
    0% {
      -webkit-transform: translateX($large) translateY($large) rotate(-360deg);
    }
    25% {
      -webkit-transform: translateX(-$large) translateY($large) rotate(-270deg) scale(0.5);
    }
    50% {
      -webkit-transform: translateX(-$large) translateY(-$large) rotate(-180deg);
    }
    75% {
      -webkit-transform: translateX($large) translateY(-$large) rotate(-90deg) scale(0.5);
    }
    100% {
      -webkit-transform: translateX($large) translateY($large) rotate(0deg);
    }
  }

  @keyframes sk-cubemove-large {
    0% {
      transform: translateX($large) translateY($large) rotate(-360deg);
      -webkit-transform: translateX($large) translateY($large) rotate(-360deg);
    }
    25% {
      transform: translateX(-$large) translateY($large) rotate(-270deg) scale(0.5);
      -webkit-transform: translateX(-$large) translateY($large) rotate(-270deg) scale(0.5);
    }
    50% {
      transform: translateX(-$large) translateY(-$large) rotate(-180deg);
      -webkit-transform: translateX(-$large) translateY(-$large) rotate(-180deg);
    }
    75% {
      transform: translateX($large) translateY(-$large) rotate(-90deg) scale(0.5);
      -webkit-transform: translateX($large) translateY(-$large) rotate(-90deg) scale(0.5);
    }
    100% {
      transform: translateX($large) translateY($large) rotate(0deg);
      -webkit-transform: translateX($large) translateY($large) rotate(0deg);
    }
  }
</style>
