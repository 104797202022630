/* eslint-disable no-shadow */
import { ref, type Plugin, watch } from 'vue'

export enum TOAST_POSITIONS {
  TOP_RIGHT = 'top-right',
  BOTTOM_CENTRE = 'bottom-centre',
}

export type ToastOptions = {
  type: 'is-success' | 'is-danger' | 'is-info' | 'is-destructive'
  message: string
  queue?: boolean
  position?: TOAST_POSITIONS
  duration?: number // ms
  link?: string
}

const getIconClassName = (type: ToastOptions['type']): string => {
  if (type === 'is-success') return 'fa-regular fa-circle-check'
  if (type === 'is-danger') return 'far fa-exclamation-triangle'
  if (type === 'is-info') return 'far fa-info'
  if (type === 'is-destructive') return 'far fa-exclamation-triangle'

  return ''
}

export const showToast = (toast: ToastOptions): Promise<void> => {
  return new Promise(resolve => {
    // If we're creating a duplicate toast, cancel early.
    if (document.querySelector('.toastie') && document.querySelector('.toastie')?.innerHTML.includes(toast.message))
      return

    const position = toast.position || TOAST_POSITIONS.BOTTOM_CENTRE

    // making sure multiple calls don't cover each other
    const offset = [...document.querySelectorAll(`.toastie.${position}`)].reduce<number>(
      (acc, curr) => acc + curr.getBoundingClientRect().height + 16,
      16,
    )
    const node = document.createElement('div')

    node.setAttribute('role', 'alert') // hint to screen readers
    node.className = `toastie toastie--${toast.type} ${position}`
    node.style.bottom = position === TOAST_POSITIONS.BOTTOM_CENTRE ? `${offset}px` : 'auto'
    node.style.top = position === TOAST_POSITIONS.TOP_RIGHT ? `${offset}px` : 'auto'

    const messageNode = document.createElement('div')
    const contentWrapper = document.createElement('div')
    const iconWrapper = document.createElement('div')
    const iconNode = document.createElement('i')
    const textNode = document.createElement('p')
    const linkNode = document.createElement('a')
    const closeButton = document.createElement('i')

    iconWrapper.className = 'toastie__icon-wrapper'
    messageNode.className = 'toastie__message'
    iconNode.className = getIconClassName(toast.type)
    textNode.innerHTML = toast.message
    linkNode.href = toast.link || ''
    linkNode.innerHTML = toast.link || ''
    linkNode.className = 'toastie__link'
    contentWrapper.className = 'toastie__content-wrapper'
    closeButton.className = 'fa-regular fa-xmark'
    closeButton.addEventListener('click', () => {
      document.body.removeChild(node)
      resolve()
    })

    iconWrapper.appendChild(iconNode)
    contentWrapper.appendChild(iconWrapper)
    contentWrapper.appendChild(textNode)
    contentWrapper.appendChild(linkNode)
    messageNode.appendChild(contentWrapper)
    messageNode.appendChild(closeButton)

    node.appendChild(messageNode)

    document.body.appendChild(node)

    setTimeout(() => {
      document.body.removeChild(node)

      resolve()
    }, toast.duration || 3000)
  })
}

export const Toastie: Plugin = {
  install(app) {
    const toastQueue = ref<ToastOptions[]>([])
    const activeToast = ref(false)

    watch(
      toastQueue,
      newQueue => {
        // If we have more than 1 toast queued already then do nothing
        if (activeToast.value && newQueue.length > 1) return

        // If we've just shifted off the oldest toast then go back to an inactive state
        if (!newQueue.length) {
          activeToast.value = false

          return
        }

        activeToast.value = true

        // show toast and then remove toast from queue when it completes
        showToast(toastQueue.value[0]).then(() => {
          if (toastQueue.value?.length) {
            toastQueue.value.shift()
          }
        })
      },
      { deep: true },
    )

    /**
     * Disabling eslints no-param-reassign rule because Vue plugins
     * require us to update the app global properties object
     */
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$toast = (options: ToastOptions) => {
      if (options.queue) {
        return toastQueue.value.push(options)
      }

      return showToast(options)
    }
  },
}
