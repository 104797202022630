import type { AxiosInstance } from 'axios'
import { useMemoize } from '@vueuse/core'
import { omit } from 'lodash-es'

import type { ActivityWithScope } from '@/imports/@types/corporateFootprints/CorporateActivity'
import type {
  CorporateFootprintYearlyBreakdownResponse,
  CorporateFootprintActivityBreakdownResponse,
  CorporateFootprintActivityDatasourceRequestProps,
  CorporateFootprintActivityDatasourceResponse,
  CorporateFootprintGetOrgYearStatusesResponse,
  CorporateFootprintBreakdown,
  ToggleNotApplicable,
} from '@/imports/@types/corporateFootprints/CorporateFootprint'

import type {
  ActivityBreakdownEmissionsRes,
  GetSubActivitiesStatusRes,
  GetAssociationStatusRes,
  MeasurementProgressBURes,
  MeasurementProgressFacilityRes,
} from '@/server/services/@types/footprint.service.types'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'

import { SORT_ORDER } from '@/imports/@enums/common.enums'
import { FACILITY_FOOTPRINT_SORT_FIELD } from '@/imports/@enums/footprint.enums'

export interface YearlyCompletion {
  year: number
  isComplete: boolean
}

export interface FootprintYearlyBreakdownRequestProps {
  orgId: string
  year: number
}

export const useCorporateFootprintsApi = (http: AxiosInstance) => ({
  toggleActivityScope(payload: { orgId: string; year: number; activityId: string; subActivityId: string }) {
    return http.post<ApiResponseInner<never>>('/footprints/toggleActivityMeasured', payload)
  },

  getActivitiesWithScope({ orgId, year }: { orgId: string; year: number }) {
    return http.get<{ activities: ActivityWithScope[] }>('/footprints/getActivities', {
      params: {
        orgId,
        year,
      },
    })
  },

  getFootprintsYearlyBreakdown: useMemoize(({ orgId, year }: FootprintYearlyBreakdownRequestProps) => {
    return http.get<CorporateFootprintYearlyBreakdownResponse>('/footprints/getYearlyBreakdown', {
      params: {
        orgId,
        year,
      },
    })
  }),

  toggleYearAsComplete(payload: { orgId: string; year: number }) {
    return http.post<ApiResponseInner<never>>('/footprints/toggle-year-as-complete', payload)
  },

  toggleFacilityApplicable(payload: ToggleNotApplicable) {
    return http.post<ApiResponseInner<undefined>>('/footprints/toggleFacilityApplicable', payload)
  },

  getActivityBreakdown: useMemoize(
    (params: {
      orgId: string
      year: number
      subActivityId: string
      pageNo: number
      perPage: number
      query: string
    }) => {
      return http.get<CorporateFootprintActivityBreakdownResponse>('/footprints/getActivityBreakdown', { params })
    },
  ),

  getActivityDatasources({ orgId, year, subActivityId }: CorporateFootprintActivityDatasourceRequestProps) {
    return http.get<CorporateFootprintActivityDatasourceResponse>('/footprints/getDataSources', {
      params: {
        orgId,
        year,
        subActivityId,
      },
    })
  },

  getRootOrgCompleteYearStatus: useMemoize(({ orgId }: { orgId: string }) => {
    return http.get<CorporateFootprintGetOrgYearStatusesResponse>('/footprints/get-yearly-completion', {
      params: {
        orgId,
      },
    })
  }),

  getFootprintCompleteness: useMemoize((orgId: string, year: number | string) => {
    return http.get<{ status: GetSubActivitiesStatusRes }>('/footprints/yearly-completion-status', {
      params: {
        year,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }),

  /**
   * Gets the data for the corporate footprint progress table which shows the list of (sub)activities
   * and their completeness
   *
   * @param orgId - the active organisation's orgId
   * @param year - the selected year
   * @param completeness - the list of completeness types that we want to filter by
   * @param accuracy - the list of accuracy scores we want to filter by
   */
  getYearlyBreakdownActivityProgress: useMemoize(
    (orgId: string, year: number | string, completeness?: string[], accuracy?: number[]) => {
      return http.get<{ activities: CorporateFootprintBreakdown[] }>('/footprints/yearly-activities', {
        params: {
          year,
          completeness,
          accuracy,
        },
        headers: {
          'x-org-id': orgId,
        },
      })
    },
  ),

  getActivityStatus: useMemoize((orgId: string, subActivityId: string, year: string | number) => {
    return http.get<{ status: GetAssociationStatusRes }>(`/footprints/sub-activity-status/${subActivityId}`, {
      params: {
        year,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }),

  getEmissionsForActivityProgress: useMemoize((orgId: string, subActivityId: string, year: string | number) => {
    return http.get<{ emissions: ActivityBreakdownEmissionsRes }>(
      `/footprints/sub-activity-emissions/${subActivityId}`,
      {
        params: {
          year,
        },
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  }),

  getFootprintEmissions: useMemoize((orgId: string, year: string | number) => {
    return http.get<{ emissions: ActivityBreakdownEmissionsRes }>('/footprints/yearly-breakdown-emissions', {
      params: {
        year,
      },
      headers: {
        'x-org-id': orgId,
      },
    })
  }),

  getBusinessUnitTableData(
    orgId: string,
    year: string,
    subActivityId: string,
    onlyMissingData: boolean,
    query: {
      perPage?: number
      pageNo?: number
      sortField?: string
      query?: string
      businessUnit?: string
      sortOrder?: SORT_ORDER
    } = {
      query: '',
      sortField: FACILITY_FOOTPRINT_SORT_FIELD.BUSINESS_UNIT_NAME,
      businessUnit: '',
      sortOrder: SORT_ORDER.ASC,
    },
  ) {
    const updatedQuery = {
      ...omit(query, 'businessUnit'),
      businessUnitIds: query.businessUnit ? [query.businessUnit] : null,
    }

    return http.get<MeasurementProgressBURes>(
      `/footprints/yearly-sub-activity-business-unit-breakdown/${subActivityId}`,
      {
        params: {
          year,
          onlyMissingData,
          ...updatedQuery,
        },
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  },

  getFacilityCompletenessTableData(
    orgId: string,
    year: string,
    subActivityId: string,
    onlyMissingData: boolean,
    query: {
      perPage?: number
      pageNo?: number
      query?: string
      sortField?: string
      businessUnit?: string
      sortOrder?: SORT_ORDER
    } = {
      query: '',
      sortField: FACILITY_FOOTPRINT_SORT_FIELD.FACILITY_NAME,
      businessUnit: '',
      sortOrder: SORT_ORDER.ASC,
    },
  ) {
    const updatedQuery = {
      ...omit(query, 'businessUnit'),
      businessUnitIds: query.businessUnit ? [query.businessUnit] : null,
    }

    return http.get<MeasurementProgressFacilityRes>(
      `/footprints/yearly-sub-activity-facility-breakdown/${subActivityId}`,
      {
        params: {
          year,
          onlyMissingData,
          ...updatedQuery,
        },
        headers: {
          'x-org-id': orgId,
        },
      },
    )
  },
})
