import axios from 'axios'

import { getApiUrl } from '@/client/helpers'

class ApiClient {
  constructor(authToken) {
    this.instance = axios.create()
    this.authToken = authToken
  }

  getAuthToken() {
    // user authToken or get the authentication token from local storage if it exists
    let token

    // even naming localStorage on server (when running tests) crashes this line of code
    // guarding against it
    try {
      token = this.authToken || localStorage.getItem('sessionToken')
    } catch (e) {
      // nothing
    }

    return token
  }

  async call(methodName, params) {
    try {
      let method = 'post'
      if (methodName.includes('.get') || methodName.includes('list')) method = 'get'
      if (methodName.includes('update')) method = 'patch'
      if (methodName.includes('delete')) method = 'delete'
      if (methodName.includes('create')) method = 'post'

      let url = `${getApiUrl()}/api/v1/${methodName.toLowerCase().split('.').join('/')}`

      // construct query params
      if (method === 'get' && params) {
        const queryParams = Object.keys(params)
          .map(key => `${key}=${params[key]}`)
          .join('&')
        url += `?${queryParams}`
      }

      const resp = await this.instance.request({
        method,
        url,
        data: params,
        headers: {
          Authorization: `Bearer ${this.getAuthToken()}`,
        },
      })
      return resp.data
    } catch (err) {
      const error = err?.response?.data?.error

      if (['unauthorized', 'totp_not_verified'].includes(error)) {
        window.localStorage.removeItem('sessionToken')
        window.location.replace('/login')
      }

      console.error('api error: ', err.toJSON ? JSON.stringify(err.toJSON()) : err)
      throw new Error(error)
    }
  }
}

export default ApiClient
