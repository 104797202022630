import { computed, type Ref } from 'vue'
import { cloneDeep } from 'lodash-es'

import type { BusinessUnitResponseStructure } from '@/imports/@types/organizationStructure/v2/OrganizationStructure'

const nameMatches = (name1: string, searchName: string) => name1.toLowerCase().includes(searchName.toLowerCase())

export const searchBusinessUnitTreeByName = (
  tree: BusinessUnitResponseStructure[],
  searchName: string,
): BusinessUnitResponseStructure[] => {
  if (!searchName) return tree

  return tree.reduce<BusinessUnitResponseStructure[]>((result, businessUnit) => {
    const clonedBusinessUnit = cloneDeep(businessUnit) // avoid mutations
    const { name, children } = businessUnit

    /**
     * WITH children
     * If we find any nested BUs which satisfy the
     * search criteria we save the BU with all those nested
     * BUs.
     *
     * If we don't find any nested BUs we check to see
     * if the parent matches the search criteria, and save
     * it without its children if it does
     *
     * WITHOUT children
     * We just check if the name matches the searchName and include
     * the BU if it does.
     */
    if (children) {
      const matchedChildren = searchBusinessUnitTreeByName(children, searchName)

      if (matchedChildren.length > 0) {
        result.push({ ...clonedBusinessUnit, children: matchedChildren })
      } else if (nameMatches(name, searchName)) {
        result.push({ ...clonedBusinessUnit, children: [] })
      }
    } else if (nameMatches(name, searchName)) {
      result.push(clonedBusinessUnit)
    }

    return result
  }, [] as BusinessUnitResponseStructure[])
}

export const useSearchBusinessUnitTreeByName = (
  tree: Ref<BusinessUnitResponseStructure[]>,
  searchName: Ref<string>,
) => ({
  filteredBuList: computed(() => searchBusinessUnitTreeByName(tree.value, searchName.value)),
})
