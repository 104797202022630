<template>
  <div id="impersonating-layout">
    <div
      class="pl-10 pr-10 pt-10"
      :class="isImpersonating ? 'impersonating' : ''"
    >
      <Notification
        v-if="isImpersonating"
        class="impersonate mb-2"
        variant="danger"
      >
        <template v-slot:content> NOTE: You are impersonating an organisation. </template>
      </Notification>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapState } from 'pinia'

  import Notification from '@components/designsystem/Notification/Notification.vue'

  import { useUserStore } from '@/client/store/user.pinia'

  export default defineComponent({
    name: 'ImpersonatingLayout',

    components: { Notification },

    computed: {
      ...mapState(useUserStore, ['isImpersonating']),
    },
  })
</script>

<style lang="scss">
  #impersonating-layout {
    .impersonating {
      border: 3px solid red;
      height: inherit;
    }
  }
</style>
