import { IsBoolean, IsOptional, IsUUID } from 'class-validator'

import { DATASET_DELETE_ACTIONS } from '../dataset/dataset-common.dto'

import { SELF_SERVE_UPLOAD_CONTROLLER } from './controller.common-vars'

export const SELF_SERVE_UPLOAD_REQUEST_DELETE_SUBPATH = 'dataset/:selfServeUploadId/request-deletion'
export const SELF_SERVE_UPLOAD_REQUEST_DELETE_URI = `${SELF_SERVE_UPLOAD_CONTROLLER}/${SELF_SERVE_UPLOAD_REQUEST_DELETE_SUBPATH}`

export class SelfServeUploadRequestDeleteDTOReq {
  @IsUUID()
  selfServeUploadId!: string

  @IsBoolean()
  @IsOptional()
  isUserPlanningToModify?: boolean
}

type FileUpload = {
  dataSourceId: string
  data: []
  originalData: []
  errorCount: number
  fileName: string
  status: string
  S3Url: string
}

export type SelfServeRequestDeleteDTORes = {
  file: FileUpload
  fileId: string
  status: DATASET_DELETE_ACTIONS
  isUserPlanningToModify: boolean
  ticketId: string
}
