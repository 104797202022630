import { defineStore } from 'pinia'
import { isEqual, omit, range, round } from 'lodash-es'
import moment from 'moment'

import { Api } from '@lib/services/api.service'
import { showToast } from '@lib/plugins/Toastie'
import { getApiErrorMessage } from '@lib/utilities/getApiErrorMessage'

import { SCOPE3_GHG_CATEGORIES } from '@lib/constants/constants'

import type { CreateEmissionsTargetDTO } from '@lib/DTO/emissions-targets/create-emissions-target.dto'
import type { EmissionsTarget } from '@lib/DTO/emissions-targets/get-emissions-targets.dto'
import type { GetEmissionsTargetsGroupedDTORes } from '@lib/DTO/emissions-targets/get-emissions-targets-grouped.dto'

import {
  calculateIntensityEmission,
  calculateTargetReductionValues,
  getAllYearsOfTarget,
  getTotalEmissionsForStartYear,
} from './targets.calculations'

import type { EmissionDriver } from '@/imports/@types/EmissionDriver'
import type { TargetFormState, TTargetChartData } from '@/imports/@types/targetsv2/TargetsStore'

import { useOrganizationStore } from '@/client/store/organization.pinia'
import { useReportsStore } from '@/client/store/reports.pinia'

import {
  ACTIVE_EMISSION_TARGET_ROUTE_STATE,
  EMISSIONS_TARGETS_CONTRACTION_TYPES,
  EMISSIONS_TARGETS_REDUCTION_TYPES,
  EMISSIONS_TARGETS_SBTI_STATUS,
  EMISSIONS_TARGETS_STATUSES,
  EMISSIONS_TARGETS_TERM_TYPES,
} from '@/imports/@enums/emissions-targets.enums'

import type { SelectOption } from '@/imports/@types/SelectOption'

export type TargetTab = {
  name: string
  query: { type: string }
  label: string
  key: string
}

export type State = {
  groupedTargetsDictionary: { [key: string]: EmissionsTarget[] } | null
  targetsTabs: TargetTab[]
  activeTarget: EmissionsTarget | null
  activeTargetCategory: string
  states: {
    isLoadingTargets: boolean
    isCreatingTarget: boolean
    isUpdatingTarget: boolean
    isFilteredByDraft: boolean
  }
  errors: {
    loadingTargets: string
    creatingTarget: string
    updatingTarget: string
  }
  targetFormState: TargetFormState
}

const initialTargetFormState = () => ({
  name: 'Untitled',
  description: '',
  scopes: [],
  termType: EMISSIONS_TARGETS_TERM_TYPES.SHORT,
  sbtiStatus: undefined,
  contraction: EMISSIONS_TARGETS_CONTRACTION_TYPES.ABSOLUTE,
  startDate: '',
  endDate: '',
  totalReductionPercentage: 0.25,
  totalReductionType: EMISSIONS_TARGETS_REDUCTION_TYPES.LINEAR,
  intensityMetricId: '',
  scope3Categories: Object.keys(SCOPE3_GHG_CATEGORIES).map(key => key),
})

export const useTargetsStore = defineStore('targetsv2', {
  state: (): State => ({
    targetsTabs: [],
    groupedTargetsDictionary: null,
    activeTarget: null,
    activeTargetCategory: '',
    states: {
      isLoadingTargets: false,
      isCreatingTarget: false,
      isUpdatingTarget: false,
      isFilteredByDraft: false,
    },

    errors: {
      loadingTargets: '',
      creatingTarget: '',
      updatingTarget: '',
    },

    /**
     * This is the state for the target form state which
     * can represent a new target or an edited target.
     */
    targetFormState: initialTargetFormState(),
  }),

  getters: {
    targets(state): EmissionsTarget[] {
      if (!state.groupedTargetsDictionary) return []

      return Object.values(state.groupedTargetsDictionary).flat()
    },

    totalActiveTargetsCount(): number {
      return this.targets.filter(target => target.status === EMISSIONS_TARGETS_STATUSES.LIVE).length
    },

    /**
     * Returns targets matching the active category and filtered by status
     */
    activeCategoryTargets(): EmissionsTarget[] {
      if (!this.activeTargetCategory || !this.groupedTargetsDictionary) return []

      return this.groupedTargetsDictionary[this.activeTargetCategory].filter(target => {
        return (
          (this.states.isFilteredByDraft && target.status === EMISSIONS_TARGETS_STATUSES.DRAFT) ||
          (!this.states.isFilteredByDraft && target.status === EMISSIONS_TARGETS_STATUSES.LIVE)
        )
      })
    },

    /**
     * Returns targets matching the active category with status of LIVE
     */
    activeCategoryActiveTargets(): EmissionsTarget[] {
      if (!this.activeTargetCategory || !this.groupedTargetsDictionary) return []

      return this.groupedTargetsDictionary[this.activeTargetCategory].filter(
        target => target.status === EMISSIONS_TARGETS_STATUSES.LIVE,
      )
    },

    /**
     * Returns targets matching the active category with status of DRAFT
     */
    activeCategoryDraftTargets(): EmissionsTarget[] {
      if (!this.activeTargetCategory || !this.groupedTargetsDictionary) return []

      return this.groupedTargetsDictionary[this.activeTargetCategory].filter(
        target => target.status === EMISSIONS_TARGETS_STATUSES.DRAFT,
      )
    },

    targetsDictionary(): { [key: string]: EmissionsTarget } | null {
      if (!this.targets.length) return null

      return this.targets.reduce(
        (acc, curr) => {
          acc[curr.id] = curr

          return acc
        },
        {} as { [key: string]: EmissionsTarget },
      )
    },

    /**
     * Represents the options available in the endDate dropdown
     * in the create target and update target form
     *
     * TODO: discuss with Babis. I think the backend should be accepting
     * years as numbers rather than start and end date strings.
     */
    endDateOptions(state): string[] {
      if (!state.targetFormState.startDate) return []

      const TARGET_MAX_YEAR = 2100
      const options: string[] = []
      const startYear = moment(state.targetFormState.startDate).year()

      for (let i = 0; i + startYear <= TARGET_MAX_YEAR; i++) {
        options.push((startYear + i).toString())
      }

      return options
    },

    startDateOptions(): string[] {
      const reportsStore = useReportsStore()

      return reportsStore.completedYears.map(y => y.toString())
    },

    selectedIntensityMetric(): ReturnType<typeof useReportsStore>['intensityMetrics'][0] | undefined {
      const selectedId = this.targetFormState.intensityMetricId
      if (!selectedId) return

      const reportsStore = useReportsStore()

      return reportsStore.intensityMetrics.find(metric => metric.id === selectedId)
    },

    targetChartData() {
      const reportsStore = useReportsStore()
      const allYearsOfTarget = getAllYearsOfTarget(+this.targetFormState.startDate, +this.targetFormState.endDate)
      const report = reportsStore.reports.find(r => r.financialYear === +this.targetFormState.startDate)

      if (!report) return

      const totalEmissionsForStartYear = getTotalEmissionsForStartYear(
        this.targetFormState.scopes,
        report,
        this.targetFormState.scope3Categories,
      )

      const ghgData = reportsStore.reports.reduce(
        (acc, curr) => {
          const year = curr.financialYear

          if (year >= +this.targetFormState.startDate) {
            acc[year] = curr.data.ghg.data
          }

          return acc
        },
        {} as { [key: number]: (typeof reportsStore.reports)[0]['data']['ghg']['data'] },
      )

      const calculateIntensityEmissionParams = [
        this.targetFormState.contraction,
        this.targetFormState.intensityMetricId,
        +this.targetFormState.startDate,
        reportsStore.intensityMetrics,
      ] as [EMISSIONS_TARGETS_CONTRACTION_TYPES, string, number, ReturnType<typeof useReportsStore>['intensityMetrics']]

      /**
       * Iterates over the ghg reports and adds the emission
       * totals to our chart data objects
       */
      const chartData = Object.values(ghgData).reduce(
        (acc, curr, currIndex) => {
          acc.scope1[currIndex] = calculateIntensityEmission(
            curr[0].emission / 1000,
            ...calculateIntensityEmissionParams,
          )

          acc.scope2[currIndex] = calculateIntensityEmission(
            curr[1].emission / 1000,
            ...calculateIntensityEmissionParams,
          )

          acc.scope3[currIndex] = curr[2].emissionDrivers.reduce((acc, curr) => {
            if (this.targetFormState.scope3Categories.find(cat => SCOPE3_GHG_CATEGORIES[cat] === curr.categoryName)) {
              acc += calculateIntensityEmission(curr.emission / 1000, ...calculateIntensityEmissionParams)

              return acc
            }

            return acc
          }, 0)

          return acc
        },
        {
          scope1: Array.from<number | null>(allYearsOfTarget).fill(null),
          scope2: Array.from<number | null>(allYearsOfTarget).fill(null),
          scope3: Array.from<number | null>(allYearsOfTarget).fill(null),
          target: Array.from<number | null>(allYearsOfTarget).fill(null),
        } as TTargetChartData,
      )

      chartData.target = calculateTargetReductionValues(
        allYearsOfTarget,
        this.targetFormState.totalReductionPercentage,
        this.targetFormState.totalReductionType,
        calculateIntensityEmission(totalEmissionsForStartYear / 1000, ...calculateIntensityEmissionParams),
      )

      return {
        chartData,
        labels: allYearsOfTarget,
      }
    },

    isValid(): boolean {
      return (
        !!this.targetFormState.startDate &&
        !!this.targetFormState.endDate &&
        !!this.targetFormState.scopes.length &&
        this.targetFormState.totalReductionPercentage > 0
      )
    },

    isDraft(): boolean {
      return !isEqual(this.targetFormState, initialTargetFormState())
    },

    targetsCount(): number {
      return this.targets.length
    },

    scope3GhgCategoryOptions(): SelectOption<string>[] {
      if (!this.targetFormState.startDate) return []

      const reportsStore = useReportsStore()
      const report = reportsStore.reports.find(r => r.financialYear === +this.targetFormState.startDate)

      if (!report) return []

      const categories = Object.keys(SCOPE3_GHG_CATEGORIES).map(SCOPE3_GHG_CATEGORY => {
        return {
          label: SCOPE3_GHG_CATEGORIES[SCOPE3_GHG_CATEGORY],
          value: SCOPE3_GHG_CATEGORY,
        }
      })

      const totalEmissions = report.data.ghg.emissionsPerScope[2]

      const emissionDrivers = report.data.ghg.data[2].emissionDrivers.reduce(
        (acc, curr) => {
          acc[curr.categoryName] = curr

          return acc
        },
        {} as { [key: string]: EmissionDriver },
      )

      return categories
        .map(cat => {
          const totalEmissionsForCategory = emissionDrivers[cat.label]?.emission || 0

          const percentageOfTotal = round((totalEmissionsForCategory / totalEmissions) * 100)

          return {
            ...cat,
            meta: {
              percentageOfTotal,
            },
          }
        })
        .sort((a, b) => b.meta.percentageOfTotal - a.meta.percentageOfTotal)
    },

    isSbtiAligned(): boolean {
      if (!this.targetFormState.scopes.includes(3)) return false

      const totalPercentage = this.targetFormState.scope3Categories.reduce((acc, catName) => {
        const option = this.scope3GhgCategoryOptions.find(option => option.value === catName)
        const percentageOfTotal = option?.meta?.percentageOfTotal

        if (typeof percentageOfTotal === 'number') {
          return acc + percentageOfTotal
        }

        return acc
      }, 0)

      return totalPercentage >= 66
    },

    /**
     * Represents the min and max years of the active category's targets
     * So if we have 2 targets, and one has the start year 2020 and the
     * end date 2035, and the other has the start year 2023 and the end
     * date 2050, then the range will be [2020, ..., 2050]
     */
    activeCategoryDateRange(): number[] {
      const targets = this.activeCategoryTargets

      if (!targets?.length) return []

      const min = Math.min(...targets.map(target => moment(target.startDate).year()))
      const max = Math.max(...targets.map(target => moment(target.endDate).year() + 1))

      // Min or max can be infinity if the array is empty and this will upset lodash's range function
      if ([min, max].includes(Infinity)) return []

      return range(min, max)
    },

    /**
     * Returns an object which asserts for each scope whether or not it is
     * relevant to the currently selected subset of targets. So for example
     * if the current category has 2 targets and one is a scope1+2 target and
     * the second is a scope 3 target then all targets are relevant.
     */
    activeCategoryRelevantScopes(): { 1: boolean; 2: boolean; 3: boolean } {
      return {
        1: this.activeCategoryTargets.some(target => target.scope1),
        2: this.activeCategoryTargets.some(target => target.scope2),
        3: this.activeCategoryTargets.some(target => target.scope3),
      }
    },

    /**
     * Returns all the Scope 3 IDs associated with the current category. This
     * is used in the charting to know which emissions to pull in from the
     * scope3 total.
     */
    activeCategoryRelevantScope3CategoryIds(): Set<string> {
      return this.activeCategoryTargets.reduce((acc, curr) => {
        curr.emissionsTargetScope3Categories.forEach(cat => {
          if (!acc.has(cat.ghgCategory)) {
            acc.add(cat.ghgCategory)
          }
        })

        return acc
      }, new Set<string>())
    },

    /**
     * Returns the names of the relevant scope 3 IDs
     */
    activeCategoryRelevantScope3CategoryNames(): string[] {
      return Array.from(this.activeCategoryRelevantScope3CategoryIds).map(
        categoryId => SCOPE3_GHG_CATEGORIES[categoryId],
      )
    },
  },

  actions: {
    async fetchGroupedTargets() {
      this.clearLoadingErrors()

      this.states.isLoadingTargets = true
      const orgStore = useOrganizationStore()

      try {
        const { data } = await Api.targets.fetchGroupedTargets(orgStore.activeOrganization?.id || '')

        this.groupedTargetsDictionary = data.groupedEmissionsTargets

        this.targetsTabs = this.normalizeTargetTabs(data.groupedEmissionsTargets)
      } catch (err) {
        this.errors.loadingTargets = getApiErrorMessage(err)

        showToast({
          type: 'is-danger',
          message:
            "Whoops! Something went wrong when fetching your organisation's targets. Please try again or contact your solution advisor.",
        })
      }

      this.states.isLoadingTargets = false
    },

    async createTarget(status: EMISSIONS_TARGETS_STATUSES) {
      if (this.states.isCreatingTarget || !this.isValid) return

      if (this.targetFormState.name === 'Untitled') this.generateTargetName()

      this.clearCreateErrors()

      const payload = this.getNewTargetFormAsPayload(status)
      const orgStore = useOrganizationStore()
      const buId = orgStore.activeOrganization?.id || ''

      try {
        this.states.isCreatingTarget = true

        await Api.targets.createTarget(buId, { ...payload, status })

        this.resetFormState()
      } catch (err) {
        this.errors.creatingTarget = getApiErrorMessage(err)

        showToast({
          type: 'is-danger',
          message:
            'Whoops! Something went wrong when creating your target. Please try again, and if the problem persists, contact your solution advisor.',
        })
      }

      this.states.isCreatingTarget = false
    },

    async updateTarget() {
      if (!this.targetsDictionary || !this.targets || !this.activeTarget) return

      if (this.states.isUpdatingTarget) {
        showToast({
          type: 'is-danger',
          message: 'Target update request in progress, please wait before trying again',
        })

        return
      }

      this.clearUpdatingErrors()

      const orgStore = useOrganizationStore()
      const payload = this.getNewTargetFormAsPayload(this.activeTarget.status)

      try {
        this.states.isUpdatingTarget = true

        const {
          data: { emissionsTarget },
        } = await Api.targets.updateTarget(orgStore.activeOrganization?.id || '', this.activeTarget.id, payload)

        /**
         * Exclam used because it's impossible to reach this point without first fetching
         * and caching the targets in state. If one did so it is reasonable to allow the try
         * catch block to catch the error.
         */
        this.targetsDictionary[this.activeTarget.id] = emissionsTarget
      } catch (err) {
        this.errors.updatingTarget = getApiErrorMessage(err)

        showToast({
          type: 'is-danger',
          message:
            'Whoops! Something went wrong when updating the target. Please try again or contact your solution advisor.',
        })
      }

      this.states.isUpdatingTarget = false
    },

    async updateTargetStatus(targetId: string, payload: { status: EMISSIONS_TARGETS_STATUSES }, refetch = true) {
      try {
        const orgStore = useOrganizationStore()

        this.states.isUpdatingTarget = true
        await Api.targets.updateTargetStatus(orgStore.activeOrganization?.id || '', targetId, payload)

        if (refetch) {
          return this.fetchGroupedTargets()
        }

        if (this.activeTarget) {
          this.activeTarget.status = payload.status
        }
      } catch (err) {
        showToast({
          type: 'is-danger',
          message:
            'Whoops! Something went wrong when deleting the target. Please try again or contact your solution advisor.',
        })
      } finally {
        this.states.isUpdatingTarget = false
      }
    },

    async deleteTarget(targetId: string, refetch = true) {
      try {
        const orgStore = useOrganizationStore()

        this.states.isUpdatingTarget = true
        await Api.targets.deleteTarget(orgStore.activeOrganization?.id || '', targetId)

        if (refetch) {
          await this.fetchGroupedTargets()
        }
      } catch (err) {
        showToast({
          type: 'is-danger',
          message:
            'Whoops! Something went wrong when deleting the target. Please try again or contact your solution advisor.',
        })
      } finally {
        this.states.isUpdatingTarget = false
      }
    },

    /**
     * @param targetId { string } - the unique identifier (UUID) of the target
     * @param state
     * @description If we don't have any targets then fetch them. If we still don't
     * have the target described by the targetId then throw an error. Otherwise
     * update the active target ID with the given targetId.
     */
    async setActiveTarget(
      targetId: string,
      state: ACTIVE_EMISSION_TARGET_ROUTE_STATE = ACTIVE_EMISSION_TARGET_ROUTE_STATE.EDIT,
    ): Promise<void> {
      if (!this.targetsDictionary) {
        await this.fetchGroupedTargets()
      }

      if (this.targetsDictionary && !this.targetsDictionary[targetId]) {
        showToast({
          type: 'is-danger',
          message: `Target with ID ${targetId} not found. Please try again or contact your solution advisor.`,
        })

        return
      }

      if (this.targetsDictionary) {
        this.activeTarget = this.targetsDictionary[targetId]
      }

      if (state === ACTIVE_EMISSION_TARGET_ROUTE_STATE.EDIT) {
        this.updateTargetFormWithExistingValues()
      }
    },

    updateTargetFormWithExistingValues() {
      if (!this.activeTarget) return

      const scopes: number[] = []

      if (this.activeTarget.scope1) scopes.push(1)
      if (this.activeTarget.scope2) scopes.push(2)
      if (this.activeTarget.scope3) scopes.push(3)

      this.targetFormState = {
        name: this.activeTarget.name,
        description: this.activeTarget.description || '',
        scopes,
        termType: this.activeTarget.termType,
        sbtiStatus: this.activeTarget.sbtiStatus,
        contraction: this.activeTarget.contraction,
        // todo: update when we store year numbers
        startDate: new Date(this.activeTarget.startDate).getFullYear().toString(),
        endDate: new Date(this.activeTarget.endDate).getFullYear().toString(),
        totalReductionPercentage: this.activeTarget.totalReductionPercentage,
        totalReductionType: this.activeTarget.totalReductionType,
        intensityMetricId: this.activeTarget?.emissionsTargetIntensityMetric?.intensityMetricId,
        scope3Categories: this.activeTarget.emissionsTargetScope3Categories?.map(c => c.ghgCategory) || [],
      }
    },

    setActiveCategory(category?: string) {
      if (!category || !this.groupedTargetsDictionary) return

      this.activeTargetCategory = category
    },

    /**
     * Get the target tabs for the targets overview page based on the grouped targets dictionary
     * @param groupedEmissionsTargets
     */
    normalizeTargetTabs(groupedEmissionsTargets: GetEmissionsTargetsGroupedDTORes['groupedEmissionsTargets']) {
      return Object.keys(groupedEmissionsTargets).map(key => {
        const numberOfTargets = groupedEmissionsTargets[key].filter(
          target => target.status === EMISSIONS_TARGETS_STATUSES.LIVE,
        ).length

        const labelCount = numberOfTargets ? `(${numberOfTargets})` : ''
        const label = `${key} ${labelCount}`

        return {
          name: 'Targets overview',
          key,
          label,
          query: { type: key },
        }
      })
    },

    clearActiveTarget() {
      this.activeTarget = null
    },

    getNewTargetFormAsPayload(status: EMISSIONS_TARGETS_STATUSES): CreateEmissionsTargetDTO {
      return {
        ...omit(this.targetFormState, 'scopes'),
        scope1: this.targetFormState.scopes.includes(1),
        scope2: this.targetFormState.scopes.includes(2),
        scope3: this.targetFormState.scopes.includes(3),
        status,
      }
    },

    clearCreateErrors() {
      this.errors.creatingTarget = ''
    },

    clearLoadingErrors() {
      this.errors.loadingTargets = ''
    },

    clearUpdatingErrors() {
      this.errors.updatingTarget = ''
    },

    setIsFilteredByDraft(value: boolean) {
      this.states.isFilteredByDraft = value
    },

    /**
     * ------------------------------------------------
     * UPDATE TARGET FORM STATE
     * ------------------------------------------------
     */

    updateScope(scopes: number[]) {
      this.targetFormState.scopes = scopes
    },

    updateStartDate(startDate: string) {
      this.targetFormState.startDate = startDate
    },

    updateEndDate(endDate: string) {
      this.targetFormState.endDate = endDate
    },

    updateTotalReductionPercentage(reductionPercentage: number) {
      if (reductionPercentage > 1) {
        throw new Error('targets.pinia.ts => updateTotalReductionPercentage error. Payload is greater than 100%')
      }

      if (reductionPercentage < 0) {
        throw new Error('targets.pinia.ts => updateTotalReductionPercentage error. Payload is less than 0%')
      }

      this.targetFormState.totalReductionPercentage = reductionPercentage
    },

    updateTotalReductionType(reductionType: EMISSIONS_TARGETS_REDUCTION_TYPES) {
      this.targetFormState.totalReductionType = reductionType
    },

    updateTermType(termType: EMISSIONS_TARGETS_TERM_TYPES) {
      this.targetFormState.termType = termType
    },

    updateContraction(contractionType: EMISSIONS_TARGETS_CONTRACTION_TYPES) {
      this.targetFormState.contraction = contractionType
    },

    updateSbtiStatus(sbtiStatus: EMISSIONS_TARGETS_SBTI_STATUS) {
      this.targetFormState.sbtiStatus = sbtiStatus
    },

    updateIntensityMetricId(metricId: string) {
      this.targetFormState.intensityMetricId = metricId
    },

    updateScope3Categories(categories: string[]) {
      this.targetFormState.scope3Categories = categories
    },

    updateName(name: string) {
      this.targetFormState.name = name
    },

    /**
     * Calculates and updates the target name
     */
    generateTargetName() {
      const selectedScopes = this.targetFormState.scopes.join('+')
      const period = `${this.targetFormState.startDate}-${this.targetFormState.endDate}`
      const reduction = `-${round(this.targetFormState.totalReductionPercentage * 100)}%`

      const name: string[] = []

      // If we have any scopes selected add them to the name
      if (selectedScopes.length) name.push(`Scope ${selectedScopes}`)

      // If we have both dates selected add them to the name
      if (this.targetFormState.startDate && this.targetFormState.endDate) name.push(period)

      /**
       * If we have either scopes or dates, add the reduction as well.
       * This is because we will always have a reduction value and if we
       * added it by default the name would always start as '25%' which
       * could be confusing.
       */
      if (name.length) name.push(reduction)

      // If we have deselected things go back to our original state
      if (!name.length) {
        this.targetFormState.name = 'Untitled'

        return
      }

      this.targetFormState.name = name.join(' | ')
    },

    async startNewEmissionsTarget() {
      this.targetFormState = initialTargetFormState()

      await this.router.push({ name: 'Create sustainability target' })
    },

    resetFormState() {
      this.targetFormState = initialTargetFormState()
    },
  },
})
