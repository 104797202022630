<template>
  <div
    class="aq-user-avatar"
    data-ci="aq-user-avatar"
  >
    <tippy>
      <div
        v-if="isPlaceholderActive"
        class="aq-user-avatar__placeholder"
        data-ci="aq-user-avatar__placeholder"
        :class="`aq-user-avatar__placeholder--${generatedUserColor}`"
        :style="{ width: avatarSize, height: avatarSize }"
      >
        <span :class="['aq-user-avatar__initial', variant]"> {{ letterPlaceholder }}</span>

        <button
          v-if="hasRemoveBtn"
          class="aq-user-avatar__remove"
          @click="emit('onRemove')"
        >
          <div class="fal fa-times"></div>
        </button>
      </div>

      <div
        v-if="!isPlaceholderActive"
        class="aq-user-avatar__image-container"
        data-ci="aq-user-avatar__image-container"
        :style="{ width: avatarSize, height: avatarSize }"
      >
        <img
          :src="uploadedAvatar ? uploadedAvatar : user.avatar"
          alt="avatar"
          class="aq-user-avatar__image"
        />
      </div>

      <template
        v-if="hasTooltip"
        v-slot:content
      >
        <span class="aq-user-avatar__tooltip-user-name"> {{ formattedUserName }} </span>
        <br />
        <span class="aq-user-avatar__tooltip-user-email">{{ user.email }}</span>
      </template>
    </tippy>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  import type { User } from '@/imports/@types/User'

  interface Props {
    user: Pick<User, 'userName' | 'avatar' | 'email'>
    hasTooltip?: boolean
    hasRemoveBtn?: boolean
    showPlaceHolder?: boolean
    variant?: 'default' | 'large'
    uploadedAvatar?: string
    avatarSize?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    variant: 'default',
    avatarSize: '24px',
    hasTooltip: false,
    hasRemoveBtn: false,
    showPlaceHolder: false,
    uploadedAvatar: '',
  })

  const emit = defineEmits<{
    (e: 'onRemove'): void
  }>()

  const userName = computed(() => props.user.userName || '')

  const isPlaceholderActive = computed(() => (!props.user.avatar && !props.uploadedAvatar) || props.showPlaceHolder)

  const letterPlaceholder = computed(() => userName.value[0]?.toUpperCase())

  const formattedUserName = computed(() => `${userName.value.charAt(0).toUpperCase()}${userName.value.slice(1)}`)

  /**
   * Generate user color
   *
   * Get 1st and last character of username and sum them together to get unique number
   * Then use Math module to always get the same color for the user
   */
  const generatedUserColor = computed(() => {
    if (!userName.value) return

    const colors = ['green', 'purple', 'red', 'blue', 'yellow', 'pink', 'orange']
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    const lastChar = userName.value[userName.value.length - 1]?.toUpperCase() || 'A'
    const concatNumbersString = (alphabet.indexOf(letterPlaceholder.value) || 1) + (alphabet.indexOf(lastChar) || 1)

    const obj = {
      name: userName.value,
      module: concatNumbersString,
    }

    const maxColorsToBeReached = colors.length
    const index = obj.module % maxColorsToBeReached || 0
    return colors[index]
  })
</script>

<style lang="scss" scoped>
  $module: 'aq-user-avatar';

  $close-btn-size: 14px;

  .#{$module} {
    display: flex;
    align-items: center;

    &__image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    &__image {
      border: 2px solid white;
      border-radius: 50%;
    }

    &__placeholder {
      border-radius: 100%;
      display: flex;
      color: $grey-8;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      position: relative;

      &:hover {
        cursor: pointer;

        .#{$module}__remove {
          display: flex;
        }
      }

      &--default {
        background: $grey-6;
        color: $grey-1;
      }

      &--green {
        background-color: $avatar-green;
      }

      &--purple {
        background-color: $avatar-purple;
      }

      &--red {
        background-color: $avatar-red;
      }

      &--blue {
        background-color: $avatar-blue;
      }

      &--yellow {
        background-color: $avatar-yellow;
      }

      &--pink {
        background-color: $avatar-pink;
      }

      &--orange {
        background-color: $avatar-orange;
      }
    }

    &__remove {
      padding: 0;
      position: absolute;
      background-color: $grey-1;
      border-radius: 100%;
      height: $close-btn-size;
      width: $close-btn-size;
      font-size: 9px;
      color: $grey-8;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -5px;
      right: -5px;
    }

    .default {
      font-size: $font-size-7;
      font-weight: 500;
    }

    .large {
      font-size: $font-size-4;
      font-weight: 500;
    }

    &__tooltip-user-name,
    &__tooltip-user-email {
      font-size: $font-size-9;
      line-height: math.div(18, $font-size-9-unitless);
    }

    &__tooltip-user-name {
      color: $grey-8;
    }

    &__tooltip-user-email {
      color: $grey-3;
      padding-top: $grid-size-padding;
    }
  }
</style>
