import type { AxiosInstance } from 'axios'
import { AUDIT_LOG_GET_URI, type AuditLogsGetDTORes } from '@lib/DTO/audit-log/audit-logs-get.dto'
import { AUDIT_LOG_GET_USERS_URI, type AuditLogsGetUsersDTORes } from '@lib/DTO/audit-log/audit-logs-get-users.dto'

import { AUDIT_LOG_GET_EVENTS_URI, type AuditLogsGetEventsDTORes } from '@lib/DTO/audit-log/audit-logs-get-events.dto'

import type { AuditLogUser } from '@/imports/@types/auditLogs/audit-log.types'
import { useOrganizationStore } from '@/client/store/organization.pinia'

export const useAuditLogApi = (http: AxiosInstance) => ({
  async getAuditLogs(
    events: string[] | null,
    users: string[] | null,
    datePeriod: [string, string],
    showOnlyFailed: boolean,
    searchTerm: string,
    currentPage: number,
    pageSize: number,
  ): Promise<AuditLogsGetDTORes> {
    const orgId = useOrganizationStore().id || ''

    const response = await http.post<AuditLogsGetDTORes>(
      AUDIT_LOG_GET_URI,
      {
        events,
        users,
        datePeriod,
        showOnlyFailed,
        searchTerm,
        currentPage,
        pageSize,
      },
      {
        headers: {
          'x-org-id': orgId,
        },
      },
    )

    return response.data
  },

  async getAuditLogEvents() {
    const orgId = useOrganizationStore().id || ''

    const response = await http.get<AuditLogsGetEventsDTORes>(AUDIT_LOG_GET_EVENTS_URI, {
      headers: {
        'x-org-id': orgId,
      },
    })

    return response.data.events
  },

  async getAuditLogUsers(): Promise<AuditLogUser[]> {
    const orgId = useOrganizationStore().id || ''

    const response = await http.get<AuditLogsGetUsersDTORes>(AUDIT_LOG_GET_USERS_URI, {
      headers: {
        'x-org-id': orgId,
      },
    })
    return response.data.users
  },
})
