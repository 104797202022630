<template>
  <div id="app">
    <RouteLoader v-if="isLoading"></RouteLoader>

    <IfFeatureDisabled flagName="rollout-mobile-version">
      <ResponsiveWarning></ResponsiveWarning>
    </IfFeatureDisabled>

    <div :class="{ 'responsive-warning-active': !mobileStateEnabled }">
      <component
        :is="layout"
        :showBetaHeader="showBetaHeader"
      >
        <router-view></router-view>
      </component>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapState, mapActions, mapStores } from 'pinia'

  import featureFlagService from '@lib/services/featureFlagService'

  import IfFeatureDisabled from '@components/IfFeatureDisabled.vue'
  import ResponsiveWarning from '@components/ResponsiveWarning.vue'
  import RouteLoader from '@components/RouteLoader.vue'

  import { useAppStore } from '@/client/store/app/app.pinia'
  import { useOrganizationStore } from '@/client/store/organization.pinia'
  import { useCorporateFootprintsStore } from '@/client/store/corporateFootprints/corporateFootprints.pinia'
  import { useUserStore } from '@/client/store/user.pinia'

  import type { Organization } from '@/imports/@types/Organization'

  export default defineComponent({
    name: 'App',

    components: {
      IfFeatureDisabled,
      ResponsiveWarning,
      RouteLoader,
    },

    data: () => ({
      mobileStateEnabled: false,
    }),

    computed: {
      ...mapState(useAppStore, {
        isLoading: state => state.loaders.route,
      }),

      ...mapStores(useUserStore, useOrganizationStore, useCorporateFootprintsStore),

      layout() {
        // fix issue that initial this.$route is undefined when starting app
        // causing it to show default-layout briefly
        if (!this.$route.name) return

        if (!this.$route.meta.layout) return 'default-layout'

        return `${this.$route.meta.layout}-layout`
      },

      showBetaHeader() {
        return !!this.$route.meta?.showBetaHeader
      },
    },

    created() {
      this.listenForLogin()
      this.listenForLogout()
      this.updateMobileState()

      featureFlagService.subscribeToContext(this.updateMobileState)
    },

    methods: {
      ...mapActions(useAppStore, ['fetchCountriesList']),

      updateMobileState() {
        this.mobileStateEnabled = featureFlagService.isEnabled('rollout-mobile-version')
      },

      listenForLogin() {
        this.organizationStore.$onAction(({ name, after }) => {
          if (name === 'setActiveOrganizationById') {
            after(() => {
              // there's a reasoneable chance Intercom is not mounted yet
              this.$intercom?.boot(
                this.userStore.user,
                this.organizationStore.activeOrganization as Organization,
                this.organizationStore.organizations.map(org => org.name).join(', '),
                this.userStore.intercomHash,
              )

              this.corporateFootprintsStore.getYearlyCompletion()
            })
          }
        })

        this.userStore.$onAction(({ name, after }) => {
          if (name === 'setUser') {
            after(() => {
              this.fetchCountriesList()
            })
          }
        })
      },

      listenForLogout() {
        this.userStore.$onAction(({ name, after }) => {
          if (name === 'clearSession') {
            after(() => {
              this.$intercom?.shutdown()
            })
          }
        })
      },
    },
  })
</script>

<style lang="scss">
  @import '@/client/style/index.scss';

  .responsive-warning-active {
    @media (max-width: 1023px) {
      display: none;
    }

    @media print {
      display: block;
    }
  }
</style>
