<template>
  <div>
    <NavBar></NavBar>

    <div
      class="page-content"
      :class="isImpersonating ? 'impersonating' : ''"
      data-scroll="page-content"
    >
      <BetaBanner v-if="showBetaHeader" />
      <div class="page-content__padding">
        <Notification
          v-if="isImpersonating"
          class="impersonate mb-2 container"
          variant="danger"
        >
          <template v-slot:content> NOTE: You are impersonating an organisation.</template>
        </Notification>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'

  import NavBar from '@components/NavBar/NavBar.vue'
  import Notification from '@components/designsystem/Notification/Notification.vue'

  import BetaBanner from '@reinga/BetaBanner/BetaBanner.vue'

  import { useUserStore } from '@/client/store/user.pinia'

  const isImpersonating = computed(() => useUserStore().isImpersonating)

  const props = defineProps<{
    showBetaHeader: boolean
  }>()

  const showBetaHeader = computed(() => props.showBetaHeader || false)
</script>

<style lang="scss" scoped>
  .page-content {
    position: absolute;
    top: $topbar-height;
    left: 0;
    bottom: 0;
    right: 0;
    height: calc(100vh - 64px); // navbar height is 64px, page-content fills remainder of viewport height
    overflow: auto;

    &__padding {
      padding: $grid-size-padding * 10;
    }
  }
</style>
