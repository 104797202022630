export const ANALYTICS_CONTROLLER = 'analytics'

export enum ANALYTICS_DASHBOARD_MAPPING {
  OUR_ANALYTICS = '%2Fcollection%2Froot',
  COLLECTIONS = '%2Fcollection%2F',
  BROWSE_DATA = '%2Fbrowse',
  HOME = '%2F',
}

export type AnalyticsIframeFormatting = {
  showTopNav?: boolean

  showSideNav?: boolean

  showBorder?: boolean

  showTitle?: boolean
}

export type MetabaseJWTPayload = {
  email: string

  first_name?: string

  last_name?: string

  groups: string[]

  org_id: string[]

  exp: number

  resource?: {
    dashboard: number
  }
}
