import type { AxiosInstance } from 'axios'
import { useMemoize } from '@vueuse/core'

import type {
  SupplierFootprint,
  SupplierFootprintsGetResponse,
  SupplierFootprintUpdateResponse,
} from '@/imports/@types/supplierFootprints'
import type { RequestForFootprintPayload, YearsAvailability } from '@/imports/@types/Supplier.v2'
import type {
  GetAnnualRespondedDataRequestsPayload,
  GetAnnualRespondedDataRequestsResponse,
} from '@/imports/@types/DataRequest'

import { useOrganizationStore } from '@/client/store/organization.pinia'

import type { SupplierFootprintYearlyBreakdown } from '@/server/services/@types/supplier-footprint.service.types'

export const useSupplierFootprintsApi = (http: AxiosInstance) => ({
  getSupplierFootprints({ orgId, supplierFootprintId }: { orgId: string; supplierFootprintId?: string }) {
    return http.get<SupplierFootprintsGetResponse>('/supplier-footprints', {
      params: {
        orgId,
        supplierFootprintId,
      },
    })
  },

  getYearlyBreakdown: useMemoize(() => {
    const orgStore = useOrganizationStore()

    return http.get<{ yearlyBreakdown: SupplierFootprintYearlyBreakdown }>('/supplier-footprints/yearly-breakdown', {
      headers: {
        'x-org-id': orgStore.activeOrganization?.id,
      },
    })
  }),

  createSupplierFootprint(payload: {
    orgId: string
    supplierFootprint: SupplierFootprint
    isDraft: boolean
    footprintRequestId?: string
  }) {
    return http.post<SupplierFootprintUpdateResponse>('/supplier-footprints', payload)
  },

  updateSupplierFootprint(
    payload: {
      orgId: string
      supplierFootprint: SupplierFootprint
      isDraft: boolean
    },
    supplierFootprintId: string,
  ) {
    return http.patch<SupplierFootprintUpdateResponse>(`/supplier-footprints/${supplierFootprintId}`, payload)
  },

  sendRequestForFootprint(payload: RequestForFootprintPayload) {
    return http.post<{ success: boolean }>('/supplier-footprints/requests', payload)
  },

  updateFootprintRequestNoData({
    orgId,
    hasNoData,
    footprintRequestId,
  }: {
    orgId: string
    hasNoData: boolean
    footprintRequestId: string
  }) {
    return http.patch<{ success: boolean }>(`/supplier-footprints/requests/${footprintRequestId}/no-data`, {
      orgId,
      hasNoData,
    })
  },

  finalizeSupplierFootprintRequest({
    orgId,
    supplierFootprintRequestId,
    supplierFootprintId,
  }: {
    orgId: string
    supplierFootprintRequestId: string
    supplierFootprintId: string
  }) {
    return http.patch<{ success: boolean }>(`/supplier-footprints/requests/${supplierFootprintRequestId}/finalize`, {
      orgId,
      supplierFootprintId,
    })
  },

  getAvailableYearsForRequest({
    orgId,
    supplierOrgId,
    supplierHash,
  }: {
    orgId: string
    supplierOrgId?: string
    supplierHash?: string
  }) {
    return http.get<{ years: YearsAvailability }>(`/supplier-footprints/requests/available-years`, {
      params: { orgId, supplierOrgId, supplierHash },
    })
  },

  getAnnualFootprintReport(params: GetAnnualRespondedDataRequestsPayload) {
    return http.get<GetAnnualRespondedDataRequestsResponse>('/supplier-footprints/annual-report', {
      params,
    })
  },
})
