/**
 * Definitive list of icon types and colours:
 * https://www.figma.com/file/L5lk5CTP1G67d1xFXxxMFk/Onboarding-tracker?node-id=4712%3A11259&t=vpY0Iplaut9B0fy2-0
 */

export const CALC_METHODS_ICONS: { [key: string]: string | undefined } = {
  'Purchased Electricity Method': 'chart-line',
  'Fuel-Based Method': 'gas-pump',
  'Green Generation Method': 'solar-panel',
  'District Heating Method': 'chart-line',
  'Spend-Based Method': 'coin',
  'Metered Method': 'chart-line',
  'Weight-Based Average Data Method': 'weight-hanging',
  'Waste-Type-Specific Method': 'trash-can',
  'Estimated Metered Method': 'gauge-high',
  'Customer-Measured Method': 'gauge-high',
  'Distance-Based Method': 'truck',
  'Distance- & Weight-Based Method': 'truck-loading',
  'Distance & Weight-Based Method': 'truck-loading',
  'Distance Based Method': 'truck',
  'Transport Refrigeration Method': 'truck-droplet',
  'Distance & Passenger-Based Method': 'user',
  'Distance- & Passenger-Based Method': 'user',
  'Average Data Method': 'map-marker-alt',
}

export const CALC_METHODS_COLORS: { [key: string]: string | undefined } = {
  'Purchased Electricity Method': 'grass',
  'Fuel-Based Method': 'lavender',
  'Green Generation Method': 'mac-n-cheese',
  'District Heating Method': 'grass',
  'Spend-Based Method': 'cornflower',
  'Metered Method': 'grass',
  'Weight-Based Average Data Method': 'picton-blue',
  'Waste-Type-Specific Method': 'yellow-orange',
  'Estimated Metered Method': 'grass',
  'Customer-Measured Method': 'yellow-orange',
  'Distance-Based Method': 'coral',
  'Distance- & Weight-Based Method': 'cornflower',
  'Distance & Weight-Based Method': 'cornflower',
  'Distance Based Method': 'coral',
  'Transport Refrigeration Method': 'picton-blue',
  'Distance & Passenger-Based Method': 'picton-blue',
  'Distance- & Passenger-Based Method': 'picton-blue',
  'Average Data Method': 'coral',
}
