export enum FRANCHISE_EMISSION_SCOPE {
  SCOPE_1_2 = 'scope12',
  SCOPE_1_2_3 = 'scope123',
}

export enum FRANCHISE_STATUS {
  NOT_INVITED = 'invite_not_sent',
  INVITE_PENDING = 'invite_pending',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
}

export enum GET_FRANCHISES_SORT_FIELD {
  STATUS = 'status',
  LATEST_TOTAL_EMISSIONS = 'latestTotalEmissions',
  DATA_QUALITY_SCORE = 'dataQualityScore',
  NAME = 'name',
  COUNTRY_CODE = 'countryCode',
}

export enum DRAWER_TYPES {
  ADD = 'add',
  EDIT = 'edit',
}

export enum GET_RESPONDED_FRANCHISES_SORT_FIELD {
  NAME = 'name',
  SCOPE_ONE = 'scopeOne',
  SCOPE_TWO = 'scopeTwo',
  SCOPE_THREE = 'scopeThree',
}
