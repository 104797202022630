import type { AxiosInstance } from 'axios'

import { replaceUriParams } from '@lib/DTO'

import type { GetDataRequestsDashboardDTORes } from '@lib/DTO/data-requests/get-data-requests-dashboard.dto'
import {
  GET_FRANCHISE_REQUESTS_PROGRESS_PATH,
  type GetFranchiseRequestsProgressDTORes,
} from '@lib/DTO/franchise-requests/get-franchise-requests-progress.dto'

import {
  type GetFranchiseRequestDTORes,
  GET_FRANCHISE_REQUEST_PATH,
} from '@lib/DTO/franchise-requests/get-franchise-request.dto'

import { ADD_FOOTPRINT_TO_REQUEST_PATH } from '@lib/DTO/franchise-requests/add-footprint-to-request.dto'

import { useOrganizationStore } from '@/client/store/organization.pinia'

import type {
  DataRequestDataResponse,
  DataRequestsTableDataResponse,
  GetDataRequestPayload,
  GetDataRequestsPayload,
  DataRequestsStats,
  GetSupplierDataRequestsProgressResponse,
} from '@/imports/@types/DataRequest'

import { REQUEST_TYPES } from '@/imports/@enums/data-requests.enums'

export const useDataRequestsApi = (http: AxiosInstance) => ({
  getDataRequests(params: GetDataRequestsPayload) {
    return http.get<DataRequestsTableDataResponse>('/supplier-data-requests', {
      params,
    })
  },

  // Returns an array of data requests
  getDashboardDataRequests() {
    const orgStore = useOrganizationStore()

    return http.get<GetDataRequestsDashboardDTORes>('/data-requests/dashboard', {
      headers: {
        'x-org-id': orgStore.id,
      },
    })
  },

  getFranchiseDataRequest(id: string) {
    const orgStore = useOrganizationStore()

    const path = replaceUriParams(GET_FRANCHISE_REQUEST_PATH, {
      id,
    })

    return http.get<GetFranchiseRequestDTORes>(path, {
      headers: {
        'x-org-id': orgStore.id,
      },
    })
  },

  getFranchiseDataRequestProgressForYear(year: number) {
    const orgStore = useOrganizationStore()

    return http.get<GetFranchiseRequestsProgressDTORes>(GET_FRANCHISE_REQUESTS_PROGRESS_PATH, {
      params: {
        year,
      },
      headers: {
        'x-org-id': orgStore.id,
      },
    })
  },

  addFootprintToFranchiseRequest(requestId: string, footprintId: string) {
    const orgStore = useOrganizationStore()

    const path = replaceUriParams(ADD_FOOTPRINT_TO_REQUEST_PATH, {
      requestId,
    })

    return http.post(
      path,
      { footprintId },
      {
        headers: {
          'x-org-id': orgStore.id,
        },
      },
    )
  },

  getDataRequest(params: GetDataRequestPayload) {
    return http.get<DataRequestDataResponse>(`/supplier-data-requests/request/${params.requestId}`, {
      params,
    })
  },

  getStats(params: { orgId: string; year?: number }) {
    return http.get<DataRequestsStats>('/supplier-data-requests/stats', {
      params,
    })
  },

  getProgress(params: { orgId: string; year: number; type?: REQUEST_TYPES }) {
    return http.get<GetSupplierDataRequestsProgressResponse>('/supplier-data-requests/progress', {
      params,
    })
  },
})
