import { IsEmail, IsString, IsUUID } from 'class-validator'

import { FRANCHISES_CONTROLLER } from './franchises.common-vars'

export class EditFranchiseDTO {
  @IsString()
  name!: string

  @IsString()
  legalName!: string

  @IsString()
  taxNumber!: string

  @IsString()
  countryCode!: string

  @IsString()
  effectiveDate!: string

  @IsEmail()
  email!: string

  @IsUUID()
  businessUnitId!: string
}

export const EDIT_FRANCHISE_SUB_PATH = ':franchiseId'
export const EDIT_FRANCHISE_PATH = `${FRANCHISES_CONTROLLER}/${EDIT_FRANCHISE_SUB_PATH}`
