// Update initiative
// [PATCH] /initiatives/:initiativeId

import { ArrayMinSize, IsArray, IsUUID } from 'class-validator'

import { ValidateDTO } from '..'

import { type IngoingInputItem, INITIATIVE_CONTROLLER, type InitiativeResponseObject } from './controller.common-vars'

export const INITIATIVE_UPDATE_SUBPATH = ':initiativeId'
export const INITIATIVE_UPDATE_URI = `${INITIATIVE_CONTROLLER}/${INITIATIVE_UPDATE_SUBPATH}`

export class InitiativeUpdateDTOReq extends ValidateDTO {
  @IsUUID()
  initiativeId!: string

  @IsArray()
  @ArrayMinSize(1)
  outgoingInputIds!: string[] // if we need to update the inputs. Initially will be undefined

  @IsArray()
  @ArrayMinSize(0)
  ingoingInputs!: IngoingInputItem[]

  productIds?: string[]
  // some products = [1, 2, 3]
  // all products = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  // no products = []
}

export type InitiativeUpdateDTORes = {
  initiative: InitiativeResponseObject
}
