/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: update 'any' instances and remove this line

import { defineStore } from 'pinia'
import { isNumber, last, orderBy, max, sum } from 'lodash-es'
import moment from 'moment'

import { Api } from '@/imports/lib/services/api.service'
import { getActivityDataCompletion } from '@/imports/lib/utilities/getActivityDataCompletion'
import { compareEmissions } from '@/imports/lib/utilities/compareEmissions/compareEmissions'
import { calculateAccuracyScore } from '@/imports/lib/utilities/calculateAccuracyScore/calculateAccuracyScore'
import { roundAccuracyScore } from '@/imports/lib/utilities/roundAccuracyScore'
import { TimePeriods } from '@/imports/@types/TimePeriods'

import type { IntensityMetric } from '@/imports/@types/IntensityMetric'
import type { AnnualReportDetail } from '@/imports/@types/ReportDetail'
import type { Report, QuarterlyReport, GhgReport, ActivityReport } from '@/imports/@types/Report'
import type { ActivityArea } from '@/imports/@types/activity/activityArea'
import type { Activity } from '@/imports/@types/activity/activity'
import type { ActivityTableRow } from '@/imports/@types/activity/ActivityTableRow'
import type { EmissionDriver } from '@/imports/@types/EmissionDriver'
import type { GhgData } from '@/imports/@types/GhgData'
import type { PeriodData } from '@/imports/@types/PeriodData'

type State = {
  activeFinancialYear?: number
  availableYears: number[]
  availableYearsDetail: AnnualReportDetail[]
  baselineYear: number | null
  intensityMetrics: IntensityMetric[]
  quarterReports: QuarterlyReport[]
  availableQuartersDetails: PeriodData[]
  reports: Report[]

  states: {
    isLoadingReports: boolean
    hasRetriedLoadingReports: boolean
    isLoadingIntensityMetrics: boolean
  }

  errors: {
    loadingReports: string
    loadingIntensityMetrics: string
  }
}

const LOCAL_STORAGE_YEAR_KEY = TimePeriods.YEAR

export const useReportsStore = defineStore('reports', {
  state: (): State => ({
    activeFinancialYear: undefined,
    availableYears: [],
    availableYearsDetail: [],
    baselineYear: null,
    intensityMetrics: [],
    quarterReports: [],
    availableQuartersDetails: [],
    reports: [],

    states: {
      isLoadingReports: false,
      hasRetriedLoadingReports: false,
      isLoadingIntensityMetrics: false,
    },

    errors: {
      loadingReports: '',
      loadingIntensityMetrics: '',
    },
  }),

  getters: {
    activeFinancialYearDetail: state =>
      state.availableYearsDetail.find(availableYear => state.activeFinancialYear === availableYear.year),

    getActivityReports: state => {
      const reports = state.reports.reduce(
        (acc, curr) => {
          const { financialYear, data } = curr
          const { activities } = data

          acc[financialYear] = activities

          return acc
        },
        {} as { [key: number]: ActivityReport[] | undefined },
      )

      return reports
    },

    latestAvailableYear: state => max(state.availableYears),

    completedYears(): number[] {
      if (!this.availableYearsDetail || Object.keys(this.availableYearsDetail).length === 0) return []

      const availableYears = this.availableYearsDetail

      const completedYears = orderBy(
        availableYears?.filter(year => year.type === 'complete'),
        ['year'],
        ['asc'],
      )

      return completedYears.map(completed => completed.year)
    },

    /**
     * Returns normalized object of GHG reports
     */
    getReports: state => {
      const reports = state.reports.reduce(
        (acc, curr) => {
          const { financialYear, data } = curr
          const { ghg, totalEmissions } = data

          acc[financialYear] = { ...ghg, totalEmissions }

          return acc
        },
        {} as { [key: number]: GhgReport },
      )

      return reports
    },

    /**
     * Returns a GHG report for the provided year if it exists
     */
    getReport(): (year: number) => GhgReport | undefined {
      return (year: number) => {
        if (this.reports.length === 0) return

        const activityReports = this.getReports

        return activityReports[year]
      }
    },

    getActivityReport(): (year: number) => ActivityReport[] | undefined {
      return (year: number) => {
        const activityReports = this.getActivityReports

        return activityReports[year]
      }
    },

    getQuarterReportByYear(): (year: number) => QuarterlyReport[] {
      return (year: number) => this.quarterReports?.filter(r => r.financialYear === year) || []
    },

    getReportUpdateDate(): string | undefined {
      const year = this.activeFinancialYear

      if (year) return this.reports[year]?.updatedAt
    },

    shouldDisableBaselineYear(): boolean {
      return !!this.reports.length
    },

    getBaseYearData(): GhgReport | undefined {
      if (!this.baselineYear) return

      return this.getReport(this.baselineYear)
    },

    getBaseYearScopeEmissions(): (scopeIndex: number) => number | undefined {
      return (scopeIndex: number) => {
        if (!this.getBaseYearData?.emissionsPerScope) return

        return this.getBaseYearData.emissionsPerScope[scopeIndex]
      }
    },

    getBaseYearEmissionDriver(): (scopeIndex: number, categoryName: string) => EmissionDriver | undefined {
      return (scopeIndex: number, categoryName: string) => {
        if (!this.getBaseYearData?.data) return

        const data = this.getBaseYearData.data[scopeIndex]

        return data?.emissionDrivers?.find(emissionDriver => emissionDriver.categoryName === categoryName)
      }
    },

    getBaseYearActivityData(): ActivityReport[] | undefined {
      if (!this.baselineYear) return

      return this.getActivityReport(this.baselineYear)
    },

    getBaseYearActivityArea(): (activityArea: string) => ActivityArea | undefined {
      return (activityArea: string) => {
        const data = this.getBaseYearActivityData

        if (!data) return

        return data.find(area => activityArea === area.activityArea)
      }
    },

    getActivityBaseYear(): (activityArea: string, activity: string) => Activity | undefined {
      return (activityArea: string, activity: string) => {
        if (!this.getBaseYearActivityData) return

        const data = this.getBaseYearActivityArea(activityArea)

        return data?.activities?.find(activityName => activityName.activity === activity)
      }
    },

    getPreviousYearActivityData(): ActivityReport[] | undefined {
      if (!this.activeFinancialYear) return []

      return this.getActivityReport(this.activeFinancialYear - 1) || []
    },

    getPreviousYearActivityArea(): (activityArea: string) => ActivityArea | undefined {
      return (activityArea: string) => {
        const data = this.getPreviousYearActivityData

        return data?.find(area => activityArea === area.activityArea)
      }
    },

    getActivityPreviousYear(): (activityArea: string, activity: string) => Activity | undefined {
      return (activityArea: string, activity: string) => {
        if (!this.getPreviousYearActivityArea) return

        const data = this.getPreviousYearActivityArea(activityArea)

        return data?.activities?.find(activityName => activityName.activity === activity)
      }
    },

    getPreviousYearScopeData(): GhgReport | undefined {
      if (!this.activeFinancialYear) return

      return this.getReport(this.activeFinancialYear - 1)
    },

    getPreviousYearScopeEmissions(): (scopeIndex: number) => number | undefined {
      return (scopeIndex: number) => {
        if (!this.getPreviousYearScopeData?.emissionsPerScope) return

        return this.getPreviousYearScopeData.emissionsPerScope[scopeIndex]
      }
    },

    getPreviousYearEmissionDriver(): (scopeIndex: number, categoryName: string) => EmissionDriver | undefined {
      return (scopeIndex: number, categoryName: string) => {
        if (!this.getPreviousYearScopeData?.data) return

        const data = this.getPreviousYearScopeData.data[scopeIndex]

        return data?.emissionDrivers?.find(emissionDriver => emissionDriver.categoryName === categoryName)
      }
    },

    /**
     * Returns the GHG report object for the selected year
     */
    getCurrentYearReport(): GhgReport | undefined {
      if (!this.activeFinancialYear) return

      return this.getReports[this.activeFinancialYear]
    },

    getCurrentYearTotalEmissions(): number {
      if (!this.getCurrentYearReport) return 0

      return sum(this.getCurrentYearReport.emissionsPerScope)
    },

    getIsActiveYearEstimated(): boolean {
      return Boolean(this.activeFinancialYearDetail?.isEstimated)
    },

    getCurrentYearActivityAreas(): ActivityReport[] | undefined {
      if (!this.activeFinancialYear) return

      return this.getActivityReport(this.activeFinancialYear)
    },

    getCurrentYearScopeData(): GhgData[] {
      if (!this.activeFinancialYear) return []

      const { data } = this.getReports[this.activeFinancialYear]

      return data
    },

    /**
     * Returns row data for the activity breakdown table
     */
    activityTableRows(): ActivityTableRow[] {
      if (!this.getCurrentYearActivityAreas) return []
      // return array of objects - activityAreas as parent and activities as children
      return this.getCurrentYearActivityAreas.map(area => {
        const baseYearData = this.getBaseYearActivityArea(area.activityArea)
        const previousYearData = this.getPreviousYearActivityArea(area.activityArea)

        // objects which holds parent data - activityArea data
        return {
          category: area.activityArea,
          emission: area.emission,
          percentageOfTotal: area.percentageOfTotal,
          baseYear: compareEmissions(area.emission, baseYearData?.emission),
          previousYear: compareEmissions(area.emission, previousYearData?.emission),
          accuracyScore: roundAccuracyScore(calculateAccuracyScore(area.activities)),

          // array of objects which holds children - activities within activity
          activities: area.activities.map(activity => {
            const baseYearActivityData = this.getActivityBaseYear(area.activityArea, activity.activity)
            const previousYearActivityData = this.getActivityPreviousYear(area.activityArea, activity.activity)

            return {
              activity: activity.activity,
              category: area.activityArea,
              emission: activity.emission,
              percentageOfTotal: activity.percentageOfTotal,
              baseYear: compareEmissions(activity.emission, baseYearActivityData?.emission),
              previousYear: compareEmissions(activity.emission, previousYearActivityData?.emission),
              accuracyScore: roundAccuracyScore(activity.accuracyScore),
            }
          }),
        }
      })
    },

    /**
     * Returns sorted row data for the activity breakdown table
     */
    sortedActivityTableRows(): (sort: 'asc' | 'desc' | boolean) => ActivityTableRow[] {
      return (sort: 'asc' | 'desc' | boolean) => {
        if (sort) {
          const sortedAreas = orderBy(this.activityTableRows, ['emission'], [sort])

          const sortedAreasWithSortedChildren = sortedAreas.map(area => ({
            ...area,
            activities: orderBy(area.activities, ['emission'], [sort]),
          }))

          return sortedAreasWithSortedChildren
        }

        return this.activityTableRows
      }
    },

    /**
     * Returns an array of paginated activity table rows
     * for use in the PDF report
     *
     * TODO: CONFIRM FOLLOWING WITH MICHAEL
     *
     * This seems wrong. It appears to return a mixture
     * of activity table rows and activities because on the last
     * line we push in a 'child' which appears to represent an
     * activity which is not the same as an ActivityTableRow. I
     * presume this works for where it's used but it's not
     * returning the expected type based on the function name
     */
    paginatedActivityTableRows(): (perPage: number) => { [key: number]: any[] } {
      return (perPage: number) => {
        let pageNo = 0
        const pages: { [key: number]: any[] } = {}

        this.sortedActivityTableRows('desc').forEach(parent => {
          if (!pages[pageNo] || pages[pageNo].length >= perPage - 1 || pages[pageNo].length === 0) {
            pageNo += 1
            pages[pageNo] = []
          }

          pages[pageNo].push(parent)

          parent.activities.forEach(child => {
            if (pages[pageNo].length >= perPage || pages[pageNo].length === 0) {
              pageNo += 1
              pages[pageNo] = []
              pages[pageNo].push(parent)
            }
            pages[pageNo].push(child)
          })
        })

        return pages
      }
    },

    /**
     * Returns an array of paginated activity table rows for a given page
     * for use in the PDF report
     *
     * TODO: PART OF SAME CONVERSATION AS ABOVE
     */
    getActivityPage(): ({ pageNo, perPage }: { pageNo: number; perPage: number }) => any[] {
      return ({ pageNo, perPage }: { pageNo: number; perPage: number }) => {
        const pages = this.paginatedActivityTableRows(perPage)
        const rows = pages[pageNo]

        const rowsRebuilt: any[] = rows.map(row => {
          const updatedRow = { ...row }

          if (row.activities) {
            const set1 = new Set(row.activities)
            const set2 = new Set(rows)

            updatedRow.activities = [...set1].filter(element => set2.has(element))
          }

          return updatedRow
        })

        return rowsRebuilt
      }
    },

    // getters for GHG breakdown table
    /**
     * Returns row data for the GHG breakdown table
     * @param state
     * @param getters
     * @returns {*}
     */
    ghgTableRows(): ActivityTableRow[] {
      // return an array of objects which holds parent as scope and scope categories as children
      return (
        this.getCurrentYearScopeData?.map((scope, scopeIndex) => {
          const baseYear = this.getBaseYearScopeEmissions(scopeIndex)
          const previousYear = this.getPreviousYearScopeEmissions(scopeIndex)

          // objects which holds parent data - scope  data
          return {
            category: `Scope ${scopeIndex + 1}`,
            emission: scope.emission,
            percentageOfTotal: scope.percentageOfTotal,
            baseYear: compareEmissions(scope.emission, baseYear),
            previousYear:
              !!previousYear && scope.emission > 0 && previousYear > 0
                ? compareEmissions(scope.emission, previousYear)
                : '-',
            accuracyScore: calculateAccuracyScore(scope.emissionDrivers),

            // array of objects which holds children - scope categories within scope data
            activities: scope.emissionDrivers.map(emissionDriver => {
              const driverBaseYear = this.getBaseYearEmissionDriver(scopeIndex, emissionDriver.categoryName)
              const driverPreviousYear = this.getPreviousYearEmissionDriver(scopeIndex, emissionDriver.categoryName)

              return {
                category: `Scope ${scopeIndex + 1}`,
                activity: emissionDriver.categoryName,
                emission: emissionDriver.emission,
                percentageOfTotal: emissionDriver.percentageOfTotal,
                baseYear: compareEmissions(emissionDriver.emission, driverBaseYear?.emission),
                previousYear: compareEmissions(emissionDriver.emission, driverPreviousYear?.emission),
                accuracyScore: emissionDriver.accuracyScore,
              }
            }),
          }
        }) || []
      )
    },

    /**
     * Returns sorted row data for the GHG breakdown table
     * @param state
     * @param getters
     * @params sort     - order on which to sort (asc/desc)
     * @returns {*}
     */
    sortedGHGTableRows(): (sort: 'asc' | 'desc' | boolean) => ActivityTableRow[] {
      return (sort: 'asc' | 'desc' | boolean) => {
        if (sort) {
          const sortedScopes = orderBy(this.ghgTableRows, ['emission'], [sort])
          const sortedScopesWithSortedChildren = sortedScopes.map(area => ({
            ...area,
            activities: orderBy(area.activities, ['emission'], [sort]),
          }))

          return sortedScopesWithSortedChildren
        }

        return this.ghgTableRows
      }
    },

    /**
     * Returns an array of paginated GHG table rows
     * for use in the PDF report
     *
     * TODO: SAME DISCUSSION AS ABOVE RE: TYPES
     */
    paginatedGHGTableRows(): (perPage: number) => { [key: number]: any[] } {
      return (perPage: number) => {
        let pageNo = 0
        const pages: { [key: number]: any[] } = {}

        this.sortedGHGTableRows(false).forEach(parent => {
          if (!pages[pageNo] || pages[pageNo].length >= perPage - 1 || pages[pageNo].length === 0) {
            pageNo += 1
            pages[pageNo] = []
          }

          pages[pageNo].push(parent)

          parent.activities.forEach(child => {
            if (pages[pageNo].length >= perPage || pages[pageNo].length === 0) {
              pageNo += 1
              pages[pageNo] = []
              pages[pageNo].push(parent)
            }
            pages[pageNo].push(child)
          })
        })

        return pages
      }
    },

    /**
     * Returns an array of paginated GHG table rows for a given page
     * for use in the PDF report
     */
    getGHGPage(): ({ pageNo, perPage }: { pageNo: number; perPage: number }) => any[] {
      return ({ pageNo, perPage }: { pageNo: number; perPage: number }) => {
        const pages = this.paginatedGHGTableRows(perPage)
        const rows = pages[pageNo]

        const rowsRebuilt: any[] = rows.map(row => {
          if (row.activities) {
            const set1 = new Set(row.activities)
            const set2 = new Set(rows)
            const newRow = { ...row }

            newRow.activities = [...set1].filter(element => set2.has(element))

            return newRow
          }

          return row
        })
        return rowsRebuilt
      }
    },

    dashboardIntensityMetrics(): IntensityMetric[] {
      if (!Array.isArray(this.intensityMetrics)) return []

      return this.intensityMetrics.filter(metric => metric.isVisibleOnDashboard)
    },

    totalEmissionsForScope3Categories(): (categoryNames: string[], year: number) => number | null {
      return (categoryNames: string[], year: number): number | null => {
        const report = this.getReport(year)

        if (!report) return null

        return report.data[2].emissionDrivers.reduce((acc, curr) => {
          if (categoryNames.includes(curr.categoryName)) {
            acc += curr.emission
          }

          return acc
        }, 0)
      }
    },
  },

  actions: {
    /**
     * Fetches reports from the API using organization.getAllReports
     */
    async fetchReports({
      orgId,
      year,
      timePeriod = TimePeriods.YEAR,
      fromAPIWhenUnavailable = true,
    }: {
      orgId: string
      year: number
      timePeriod?: TimePeriods
      fromAPIWhenUnavailable?: boolean
    }) {
      // If we already have the report, we don't need to do anything
      if (timePeriod === 'year' && this.getReports[year]) return true
      if (timePeriod === 'quarter' && this.quarterReports.find(r => r.financialYear === year)) return true

      if (fromAPIWhenUnavailable) {
        this.states.isLoadingReports = true
        this.errors.loadingReports = ''

        try {
          const {
            data: { result: reports },
          } = await Api.reports.getAllReports({ orgId, timePeriod })

          // filter out any reports that don't have a data key.
          const validReports = reports.filter(report => !!report.data)

          this.setReports({ reports: validReports, timePeriod })

          this.states.hasRetriedLoadingReports = false
        } catch (err) {
          if (!this.states.hasRetriedLoadingReports) {
            this.states.hasRetriedLoadingReports = true

            this.fetchReports({
              orgId,
              year,
              timePeriod,
              fromAPIWhenUnavailable,
            })
          }

          this.errors.loadingReports = `${err}`
          this.states.isLoadingReports = false
        }
      }
    },

    /**
     * Resets both yearly and quarterly report states
     */
    resetAllReports() {
      this.$reset()
    },

    /**
     * Sets yearly or quarterly reports in the reports store state
     */
    async setReports({ reports, timePeriod }: { reports: Report[]; timePeriod: TimePeriods }) {
      if (timePeriod === TimePeriods.YEAR) {
        reports.forEach(report => {
          this.reports.push(report)
        })

        this.setAvailableYearsDetail(this.getActivityReports)
      }

      if (timePeriod === TimePeriods.QUARTER) {
        reports.forEach(report => {
          this.quarterReports.push(report)
        })
      }

      this.states.isLoadingReports = false
    },

    /**
     * Sets the active year for the selected org
     */
    async setActiveFinancialYear({ activeFinancialYear }: { activeFinancialYear: number; reloadFromAPI?: boolean }) {
      this.activeFinancialYear = activeFinancialYear
    },

    /**
     * Sets the availableYears state object
     *
     * IMPORTANT:
     * The list of years is temporarily comprised of EITHER an available report year, OR a
     * completed Basic Survey year. This means that the available years is temporarily unreliable
     * as a source of truth for whether a report actually exists. This should not cause issues,
     * because the types for the getters of a report already assume the potential of an undefined response.
     *
     * See org store for details.
     */
    setAvailableYears({ availableYears, baselineYear }: { availableYears: number[]; baselineYear: number }) {
      this.baselineYear = baselineYear
      this.availableYears = availableYears

      this.deriveAndSetActiveFinancialYear(availableYears)
    },

    deriveAndSetActiveFinancialYear(availableYears: number[]): void {
      let selectedYear = moment().utc().year()

      /**
       * We assume the current year is incomplete, so if there is more than one year (and the last available year is
       * the same as the current year), we show a previous year.
       * Otherwise if there is at least one year available (and the last is not the current year), we select the last
       * If there's no available years, set the active year to the current year
       */
      if (availableYears.length > 1 && last(availableYears) === selectedYear) {
        const newSelectedYear = availableYears.at(-2)

        if (isNumber(newSelectedYear)) selectedYear = newSelectedYear
      } else {
        const newSelectedYear = last(availableYears)

        if (isNumber(newSelectedYear)) selectedYear = newSelectedYear
      }

      // if baseline year exists set it as default
      const baselineYearAvailable = Boolean(this.baselineYear && availableYears.includes(this.baselineYear))

      /**
       * When the user changes the year in the nav bar year selector, it is persisted in local storage.
       * If it's available, we use it to update the selector when starting a new session e.g. page refresh
       */
      const lastSelectedYear = window.localStorage.getItem(LOCAL_STORAGE_YEAR_KEY)

      if (lastSelectedYear) {
        this.activeFinancialYear = Number(lastSelectedYear)
      } else {
        this.activeFinancialYear = baselineYearAvailable && this.baselineYear ? this.baselineYear : selectedYear
      }
    },

    async fetchIntensityMetrics({
      orgId,
      cacheResponse = true,
    }: {
      orgId: string
      cacheResponse?: boolean
    }): Promise<IntensityMetric[]> {
      // Request the metrics
      const {
        data: { result },
      } = await Api.reports.getIntensityMetrics(orgId)

      // If we have an active org set update the intensity metrics on that object
      if (cacheResponse) {
        this.intensityMetrics = result
      }

      return result
    },

    async fetchDashboardIntensityMetrics(orgId: string) {
      const metrics = await this.fetchIntensityMetrics({ orgId })

      return metrics.filter(metric => metric.isVisibleOnDashboard)
    },

    /**
     * @param { intensityMetric } payload - data that represents a new intensity metric
     * @param { string } orgId
     * @param { boolean } cacheResponse
     * @returns { Promise<AqResponse<attribute>> }
     */
    async createCustomIntensityMetric({
      payload,
      orgId,
      cacheResponse = true,
    }: {
      payload: {
        name: string
        description: string
      }
      orgId: string
      cacheResponse?: boolean
    }) {
      const {
        data: { result },
      } = await Api.reports.createIntensityMetric({
        orgId,
        intensityMetricsData: [
          {
            ...payload,
            values: {},
            isVisibleOnDashboard: false,
          },
        ],
      })

      await this.fetchIntensityMetrics({ orgId, cacheResponse })

      return result
    },

    /**
     * @param { intensityMetric[] } payload - array of intensity metrics
     * @param { orgId } string
     * @returns { Promise<AqResponse<attribute>> }
     */
    async updateIntensityMetrics({
      payload,
      orgId,
      cacheResponse = true,
    }: {
      payload: IntensityMetric[]
      orgId: string
      cacheResponse?: boolean
    }) {
      const {
        data: { result },
      } = await Api.reports.createIntensityMetric({
        orgId,
        intensityMetricsData: payload,
      })

      await this.fetchIntensityMetrics({ orgId, cacheResponse })

      return result
    },

    async fetchCustomer({ orgId, customerId }: { orgId: string; customerId: string }) {
      const {
        data: { result },
      } = await Api.organization.getCustomer({
        orgId,
        customerId,
      })

      return result
    },

    setSelectedYear(year: number) {
      /** Persist year selection in local storage */
      window.localStorage.setItem(LOCAL_STORAGE_YEAR_KEY, year.toString())
    },

    clearSelectedYear() {
      window.localStorage.removeItem(LOCAL_STORAGE_YEAR_KEY)
    },

    /**
     * Resets quarter reports to original empty state
     */
    resetQuarterReports() {
      this.quarterReports = []
    },

    /**
     * Appends a year report to the state
     */
    setReport({ report }: { report: Report }) {
      this.reports.push(report)
    },

    /**
     * Appends a quarter report to the state
     * @param { object } report
     */
    setQuarterReport({ report }: { report: QuarterlyReport }) {
      this.quarterReports.push(report)
    },

    /**
     * Appends a quarter report to the state
     * @param { boolean } loadingState
     */
    setReportsLoading(loadingState: boolean) {
      this.states.isLoadingReports = loadingState
    },

    /**
     * Ses the baseline year report state for the active org
     * @param { number } baselineYear
     */
    setBaselineYear(baselineYear: number) {
      this.baselineYear = baselineYear
    },

    /**
     * Sets the available years array state
     * TODO: Refactor to enable TS to automatically infer the types with the 'as' definitions
     */
    setActiveFinancialYearFromAvailableYears(availableYears: number[]) {
      this.availableYears = availableYears

      let selectedYear = moment().utc().year()

      /**
       * We assume the current year is incomplete, so if there is more than one year (and the last available year is
       * the same as the current year), we show a previous year.
       * Otherwise if there is at least one year available (and the last is not the current year), we select the last
       * If there's no available years, set the active year to the current year
       */
      if (availableYears.length > 1 && last(availableYears) === selectedYear) {
        selectedYear = availableYears.at(-2) as number
      } else if (availableYears.length > 0) {
        selectedYear = last(availableYears) as number
      }

      // if baseline year exists set it as default
      const baselineYearAvailable = !!(this.baselineYear && availableYears.includes(this.baselineYear))

      /**
       * When the user changes the year in the nav bar year selector, it is persisted in local storage.
       * If it's available, we use it to update the selector when starting a new session e.g. page refresh
       */
      const lastSelectedYear = window.localStorage.getItem(LOCAL_STORAGE_YEAR_KEY)

      if (lastSelectedYear) {
        this.activeFinancialYear = Number(lastSelectedYear)
      } else {
        this.activeFinancialYear = baselineYearAvailable ? (this.baselineYear as number) : selectedYear
      }
    },

    /**
     * Formats the available years for the drop down
     * @param { array } activityReports
     */
    setAvailableYearsDetail(reports: { [key: number]: ActivityReport[] | undefined }) {
      const years = getActivityDataCompletion(reports)

      this.availableYearsDetail = orderBy(years, ['year'], ['desc'])
    },

    /**
     * Sets available quarter detail, used by footprint timeseries chart
     * @param { array } quarterGhgReports
     */
    setAvailableQuartersDetail(quarterGhgReports: QuarterlyReport[]) {
      // sort quarterly reports - order by asc based on year
      const sortedQuarterGhgReports = orderBy(quarterGhgReports, ['reportStartDate'])

      let availableYears = sortedQuarterGhgReports.map(report => report.financialYear)
      availableYears = [...new Set(availableYears)]

      const yearsActivityReports: { [key: number]: ActivityReport[][] } = {} // all activities in given year
      const reportAvailableQuarters: { [key: number]: unknown[] } = {} // available quarters in the report

      availableYears.forEach(year => {
        yearsActivityReports[year] = []
        reportAvailableQuarters[year] = []
      })

      // create an object { year: activities } so based on the leng of the arrays we can get status: estimated, incompleted etc.
      sortedQuarterGhgReports.forEach(report => {
        const year = report.financialYear

        yearsActivityReports[year].push(report.data.activities)
      })

      // get quarters with status data -> ex. incompleted, estimated etc.
      let yearsDataCompletion = getActivityDataCompletion(yearsActivityReports)

      // get available quarters for given years
      sortedQuarterGhgReports.forEach(report => {
        const { financialYear, timePeriodIndex } = report

        reportAvailableQuarters[financialYear].push(timePeriodIndex)
      })

      const availableQuartersArray = Object.values(reportAvailableQuarters).flat(1)
      yearsDataCompletion = yearsDataCompletion.map((year, index) => ({
        ...year,
        quarter: availableQuartersArray[index],
      }))

      this.availableQuartersDetails = orderBy(yearsDataCompletion, ['year'], ['asc'])
    },

    resetFinancialYear() {
      this.activeFinancialYear = last(this.availableYears)
    },

    setIntensityMetrics(metrics: IntensityMetric[]) {
      this.intensityMetrics = metrics
    },
  },
})
