import type { AxiosInstance } from 'axios'

import type { ApiResponseInner } from '@/imports/@types/ApiResponse'

import type { ScopePayload, ScopeResponse } from '@/imports/@types/organizationStructure/v2/OrganizationStructure'

export const useOrganizationPropertiesApi = (http: AxiosInstance) => ({
  createScope(payload: ScopePayload) {
    return http.post<ApiResponseInner<ScopeResponse>>('/organizationPropertiesV2/create', payload)
  },
})
